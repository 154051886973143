import { Td, Tr } from '@chakra-ui/react';
import React from 'react';

interface Props {
  userData: any;
  index: number;
}
const UserListItemReport: React.FC<Props> = (props) => {
  const { userData, index } = props;

  return (
    <Tr key={userData.id}>
      <Td>{index}</Td>
      <Td>{userData?.company_name}</Td>
      <Td>{userData?.last_name}</Td>
      <Td>{userData?.first_name}</Td>
      <Td>{userData?.date_added}</Td>
      <Td>{userData?.date_removed}</Td>
      <Td isNumeric>{userData?.number_of_jobs_worked}</Td>
      <Td isNumeric>{userData?.total_hours_worked}</Td>
    </Tr>
  );
};

export default UserListItemReport;
