import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';

interface Props {
  locationData: any;
  index: number;
}
const LocationListItem: React.FC<Props> = (props) => {
  const { locationData, index } = props;

  return (
    <Tr key={locationData.id}>
      <Td>{index}</Td>
      <Td>
        <Text fontWeight="semibold">
          {truncateString(locationData.name) || '-'}
        </Text>
      </Td>
      <Td>
        {locationData?.geo_location?.lat}, {locationData?.geo_location?.lng}
      </Td>
      <Td>{truncateString(locationData?.company?.name) || '-'}</Td>
      <Td>
        <RouterLink
          to={routes.locations.view.replace(':id', locationData.id.toString())}>
          <Tooltip hasArrow label={strings.view_location}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_location}
              color="green.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default LocationListItem;
