import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  hazardTypeData?: any;
}

const HazardForm: React.FC<Props> = (props) => {
  const { hazardTypeData } = props;

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<any>();

  return (
    <>
      <SimpleGrid columns={1} spacing={4}>
        <Box>
          <FormControl isInvalid={!!errors?.name}>
            <FormLabel>{strings.name}</FormLabel>
            <Input
              {...register('name', {
                maxLength: {
                  value: 100,
                  message: strings.too_many_characters,
                },
              })}
              type="text"
              defaultValue={hazardTypeData?.name}
              placeholder={strings.name}
            />
            <FormErrorMessage>
              {errors?.name && errors?.name?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.description}>
            <FormLabel>{strings.description}</FormLabel>
            <Textarea
              {...register('description')}
              defaultValue={hazardTypeData?.description}
              placeholder={strings.write_here}
            />
            <FormErrorMessage>
              {errors?.description && errors?.description?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default HazardForm;
