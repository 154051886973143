import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PermissionResource from 'api/permissions';
import { CenterSpinner } from 'components/common/CenterSpinner';
import RolePermissionForm from 'components/rolePermissions/RolePermissionForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { CompanySchema } from 'constants/schema';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditRolePermissions: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<CompanySchema>();

  const rolePermissionAPI = new PermissionResource();

  const rolePermissionQuery = useQuery(
    [`role-permission${id}-edit`, id],
    () => rolePermissionAPI.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const permissionListQuery = useQuery<any>(
    [`permission-list-query`],
    () =>
      rolePermissionAPI.list().then((res) => {
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateRolePermission = useMutation((data: any) =>
    rolePermissionAPI.setPermissions(data)
  );

  const onSubmit = (data: any) => {
    const selectedPermissions = Object.keys(data?.permissions).filter(
      (key) => data?.permissions[key]
    );
    const formData = {
      role: +id,
      permissions: selectedPermissions,
    };
    updateRolePermission.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.permission_updated_successfully,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('permission-role-list');
        history.push(routes.settings.rolePermissions.list);
      },
    });
  };

  if (rolePermissionQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (rolePermissionQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.permission_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.settings}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.settings.rolePermissions.list}>
            {strings.role_and_permissions}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.settings.rolePermissions.edit}>
            {strings.edit_permissions}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_permissions}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateRolePermission.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.permission_update_failed}
                </Alert>
              )}
              <RolePermissionForm
                permissionGroupQuery={rolePermissionQuery.data}
                permissionList={permissionListQuery?.data?.permissions}
              />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateRolePermission.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditRolePermissions;
