import Resource from 'api/resource';
import http from 'utils/http';
class HazardTypeResource extends Resource {
  constructor() {
    super('hazard-type/');
  }

  itemList(query?: any) {
    return http({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    });
  }
}

export { HazardTypeResource as default };
