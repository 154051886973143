const routes = {
  dashboard: '/',
  appDownloads: '/downloads',
  locationExternal: '/location/external/:id',
  splash: '/splash',
  profile: {
    base: '/profile/*',
    myProfile: '/profile/my-profile',
    edit: '/profile/edit',
  },
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    setPassword: '/set-password',
    verifyEmail: '/verify-email',
    register: '/register',
  },
  users: {
    base: '/users',
    list: '/users/list',
    create: '/users/create',
    edit: '/users/edit/:id',
    profile: '/users/profile/:id',
    deactivateRequest: {
      base: '/users/request-deactivate*',
      list: '/users/request-deactivate',
    },
  },
  companies: {
    base: '/companies/*',
    list: '/companies/list',
    create: '/companies/create',
    edit: '/companies/edit/:id',
    view: '/companies/view/:id',
  },
  locations: {
    base: '/locations/*',
    list: '/locations/list',
    create: '/locations/create',
    edit: '/locations/edit/:id',
    view: '/locations/view/:id',
  },
  ppe: {
    base: '/ppe/*',
    list: '/ppe/list',
    create: '/ppe/create',
    edit: '/ppe/edit/:id',
    view: '/ppe/view/:id',
  },
  sops: {
    base: '/sops/*',
    list: '/sops/list',
    create: '/sops/create',
    edit: '/sops/edit/:id',
    view: '/sops/view/:id',
  },
  reports: {
    base: '/reports',
    company: '/reports/company',
    employee: '/reports/employee',
    user: '/reports/user',
    vendor: '/reports/vendor',
    jsa: {
      base: '/reports/jsa*',
      list: '/reports/jsa',
      listByCompany: '/reports/jsa/:company_name',
      view: '/reports/jsa/view/:id',
    },
    jobSummaries: {
      base: '/reports/job-summaries*',
      list: '/reports/job-summaries',
      view: '/reports/job-summaries/:id',
    },
  },
  mapFeatures: {
    view: '/map-features',
  },
  workType: {
    base: '/work-types/*',
    list: '/work-types/list',
    create: '/work-types/create',
    edit: '/work-types/edit/:id',
    view: '/work-types/view/:id',
    editStep: '/work-types/steps/create/:id/:companyId',
  },
  hazards: {
    base: '/hazards',
    hazardType: {
      base: '/hazards/types*',
      list: '/hazards/types/list',
      view: '/hazards/types/view/:id',
      edit: '/hazards/types/edit/:id',
      create: '/hazards/types/create',
    },
    hazard: {
      base: '/hazards/main*',
      list: '/hazards/main/list',
      view: '/hazards/main/view/:id',
      edit: '/hazards/main/edit/:id',
      create: '/hazards/main/create',
    },
  },
  announcements: {
    base: '/announcements/*',
    list: '/announcements/list',
    create: '/announcements/create',
    edit: '/announcements/edit/:id',
    view: '/announcements/view/:id',
  },
  chats: {
    base: '/chats/*',
    list: '/chats',
  },
  settings: {
    base: '/settings/*',
    rolePermissions: {
      list: '/settings/role-permissions',
      view: '/settings/role-permissions/view/:id',
      edit: '/settings/role-permissions/edit/:id',
    },
  },
  faqs: {
    base: '/faqs/*',
    list: '/faqs',
  },
  fieldReporting: {
    base: '/field-report',
    environmentTracker: {
      base: '/field-report/environment-tracker*',
      list: '/field-report/environment-tracker/list',
      create: '/field-report/environment-tracker/create',
      edit: '/field-report/environment-tracker/edit/:id',
      view: '/field-report/environment-tracker/view/:id',
      create_field_type:
        '/field-report/environment-tracker/:id/add-user-field-report',
    },
    fieldType: {
      base: '/field-report/field-type*',
      list: '/field-report/field-type/list',
      create: '/field-report/field-type/create',
      edit: '/field-report/field-type/edit/:id',
      view: '/field-report/field-type/view/:id',
    },
    userFieldReport: {
      base: '/field-report/user-field-report*',
      list: '/field-report/user-field-report/list',
      create: '/field-report/user-field-report/create',
      edit: '/field-report/user-field-report/edit/:id',
      view: '/field-report/user-field-report/view/:id',
    },
  },
};

export default routes;
