import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import BadgeResource from 'api/badge';
import { strings } from 'config/localization';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { validEmail, validPhone } from 'utils/validate';
interface Props {
  userData?: any;
}

const ProfileForm: React.FC<Props> = (props) => {
  const { userData } = props;
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const badgeAPI = new BadgeResource();
  const badgeQuery: any = useQuery(
    [`badge-list`],
    () => badgeAPI.list().then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const badgeList = badgeQuery?.data?.results;
  const badgeOptions = badgeList?.map((badge: any) => ({
    label: badge.badges,
    value: badge.id,
  }));

  const handleBadges = (selectedBadge: any) => {
    const badges = selectedBadge.map((res: any) => res.value);
    setValue('badges', badges);
  };

  const getBadges = () => {
    return userData?.user_badges.map((res: any) => {
      return {
        label: res.badges,
        value: res.id,
      };
    });
  };

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid gap={['3', '6']} templateColumns="repeat(3, 1fr)" flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.first_name} isRequired>
              <FormLabel>{strings.first_name}</FormLabel>
              <Input
                type="text"
                defaultValue={userData?.first_name}
                placeholder={strings.first_name}
                {...register('first_name', {
                  required: strings.first_name,
                })}
              />
              <FormErrorMessage>
                {errors?.first_name && errors?.first_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.middle_name}>
              <FormLabel>{strings.middle_name}</FormLabel>
              <Input
                type="text"
                defaultValue={userData?.middle_name}
                placeholder={strings.middle_name}
                {...register('middle_name')}
              />
              <FormErrorMessage>
                {errors?.middle_name && errors?.middle_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.last_name} isRequired>
              <FormLabel>{strings.last_name}</FormLabel>
              <Input
                defaultValue={userData?.last_name}
                type="text"
                placeholder={strings.last_name}
                {...register('last_name', { required: strings.last_name })}
              />
              <FormErrorMessage>
                {errors?.last_name && errors?.last_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.email}>
              <FormLabel>{strings.email}</FormLabel>
              <Input
                defaultValue={userData?.email}
                type="email"
                placeholder={strings.email}
                {...register('email', {
                  validate: (value) =>
                    validEmail(value) || strings.valid_email_msg,
                })}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.phone} isRequired>
              <FormLabel>{strings.cell_phone}</FormLabel>
              <Input
                type="number"
                {...register('phone', {
                  required: strings.cell_phone,
                  validate: (value) =>
                    validPhone(value) || strings.valid_contact_number_msg,
                })}
                defaultValue={userData?.phone}
                placeholder={strings.cell_phone}
              />
              <FormErrorMessage>
                {errors?.phone && errors?.phone?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.work_phone} isRequired>
              <FormLabel>{strings.work_phone}</FormLabel>
              <Input
                defaultValue={userData?.work_phone}
                placeholder={strings.work_phone}
                type="number"
                {...register('work_phone', {
                  required: strings.work_phone,
                  validate: (value) =>
                    validPhone(value) || strings.valid_contact_number_msg,
                })}
              />
              <FormErrorMessage>
                {errors?.work_phone && errors?.work_phone?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.work_email} isRequired>
              <FormLabel>{strings.work_email}</FormLabel>
              <Input
                defaultValue={userData?.work_email}
                type="email"
                placeholder={strings.work_email}
                {...register('work_email', {
                  required: strings.work_email,
                })}
              />
              <FormErrorMessage>
                {errors?.work_email && errors?.work_email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.company}</FormLabel>
              <Input
                defaultValue={userData?.company?.name}
                type="text"
                disabled
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.allergies}>
              <FormLabel>{strings.allergies}</FormLabel>
              <Input
                defaultValue={userData?.allergies}
                type="text"
                placeholder={strings.allergies}
                {...register('allergies')}
              />
              <FormErrorMessage>
                {errors?.allergies && errors?.allergies?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.blood_type}>
              <FormLabel>{strings.blood_type}</FormLabel>
              <Input
                defaultValue={userData?.blood_type}
                type="text"
                placeholder={strings.blood_type}
                {...register('blood_type')}
              />
              <FormErrorMessage>
                {errors?.blood_type && errors?.blood_type?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.emergency_name} isRequired>
              <FormLabel>{strings.emergency_name}</FormLabel>
              <Input
                defaultValue={userData?.emergency_name}
                type="text"
                placeholder={strings.emergency_name}
                {...register('emergency_name', {
                  required: strings.emergency_name,
                })}
              />
              <FormErrorMessage>
                {errors?.emergency_name && errors?.emergency_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={!!errors?.emergency_contact_phone}
              isRequired>
              <FormLabel>{strings.emergency_contact_phone}</FormLabel>
              <Input
                defaultValue={userData?.emergency_contact_phone}
                type="text"
                placeholder={strings.emergency_contact_phone}
                {...register('emergency_contact_phone', {
                  required: strings.emergency_contact_phone,
                })}
              />
              <FormErrorMessage>
                {errors?.emergency_contact_phone &&
                  errors?.emergency_contact_phone?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.certification_notes}>
              <FormLabel>{strings.certification_notes}</FormLabel>
              {/* <Uploader acceptFileType={CERTIFICATION_ACCEPT_FILE_TYPE} /> */}
              <Input
                defaultValue={userData?.certification_notes}
                type="text"
                placeholder={strings.certification_notes}
                {...register('certification_notes')}
              />
              <FormErrorMessage>
                {errors?.certification_notes &&
                  errors?.certification_notes?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          {/* <GridItem>
            <FormControl isInvalid={!!errors?.certification_notes}>
              <FormLabel>Upload Certification</FormLabel>
              <Uploader acceptFileType={CERTIFICATION_ACCEPT_FILE_TYPE} />
              <FormErrorMessage>
                {errors?.certification_notes &&
                  errors?.certification_notes?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem> */}
          <GridItem>
            <FormControl isInvalid={!!errors?.about}>
              <FormLabel>{strings.about}</FormLabel>
              <Textarea
                {...register('about')}
                defaultValue={userData?.about}
                placeholder={strings.write_here}
              />
              <FormErrorMessage>
                {errors?.about && errors?.about?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          {/* <HideControl
            hideFor="profile-badges"
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            <GridItem>
              <FormControl isInvalid={!!errors?.badges}>
                <FormLabel>Badges</FormLabel>
                <Select
                  isMulti
                  options={badgeOptions}
                  placeholder="Select Badge"
                  closeMenuOnSelect={false}
                  selectedOptionStyle="check"
                  hideSelectedOptions={false}
                  onChange={(e: any) => handleBadges(e)}
                  defaultValue={getBadges()}
                />
                <FormErrorMessage>
                  {errors?.badges && errors?.badges?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </HideControl> */}
        </Grid>
      </Stack>
    </form>
  );
};

export default ProfileForm;
