import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import EmployeeResource from 'api/user';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import EmployeeListItem from 'components/reports/EmployeeListItemReport';
import { strings } from 'config/localization';
import CreatedAtInput, {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import { EmployeeSchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import {
  BiCalendar,
  BiChevronLeft,
  BiChevronRight,
  BiExport,
} from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat, DateFormatMDY, getStartingSerialNumber } from 'utils';
import { csvExport } from 'utils/fileExport';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  createdAt: string;
  search: string;
}

const EmployeeListReport: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const employeeAPI = new EmployeeResource();
  const [createdAt, setCreatedAt] = useState<any>();

  const companyAPI = new CompanyResource();

  const [totalEmployees, setTotalEmployees] = useState<number | undefined>(
    undefined
  );
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    createdAt: '',
    search: '',
  });

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalEmployees,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: employeeList, isLoading: isEmployeeListLoading } = useQuery(
    [
      'employeeList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        createdAt: filterParams.createdAt,
        search: filterParams.search,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.createdAt)
        queryParams.date = DateFormatMDY(filterParams.createdAt);
      if (filterParams.search) queryParams.search = filterParams.search;
      const response = await employeeAPI.employeeReport(queryParams);
      setTotalEmployees(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data?.results;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      createdAt: data.createdAt,
      search: data.search,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setCreatedAt('');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      createdAt: '',
      keyword: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleDateChange = (date: Date) => {
    setCreatedAt(date);
    methods.setValue('createdAt', date);
  };

  const handleExport = async () => {
    let dataArray: any = [];
    dataArray = await employeeList?.map((res: any, index: number) => {
      return {
        [strings.sn]: index + 1,
        [strings.last_name]: res.last_name,
        [strings.first_name]: res.first_name,
        [strings.date_added]: res.created_at,
        [strings.number_of_jobs_worked]: res.number_of_jobs,
        [strings.total_hours_worked]: res.total_man_hours,
      };
    });

    csvExport(dataArray, `EmployeeReport-${DateFormat(new Date())}` + ' .xlsx');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.report}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.reports.employee}>
              {strings.employee}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.employee_report}</Heading>
          <IconButton
            size="sm"
            fontSize="md"
            onClick={handleExport}
            variant="outline"
            rounded="sm"
            colorScheme="purple"
            aria-label="Export"
            icon={<BiExport />}
          />
        </Flex>

        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <Input
                  type="text"
                  placeholder={strings.search}
                  {...methods.register('search')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.date}</FormLabel>
                <InputGroup>
                  <InputRightElement
                    children={<BiCalendar color="green.500" />}
                  />
                  <ReactDatePicker
                    dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                    customInput={<CreatedAtInput value={createdAt} />}
                    onChange={handleDateChange}
                    selected={createdAt}
                    value={DateFormatMDY(createdAt)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.last_name}</Th>
                  <Th>{strings.first_name}</Th>
                  <Th>{strings.date_added}</Th>
                  <Th isNumeric>{strings.number_of_jobs_worked}</Th>
                  <Th isNumeric>{strings.total_hours_worked}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isEmployeeListLoading &&
                  employeeList?.map(
                    (employeeData: EmployeeSchema, index: number) => (
                      <EmployeeListItem
                        employeeData={employeeData}
                        key={employeeData.id}
                        index={startingSN + index}
                      />
                    )
                  )}
                {isEmployeeListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isEmployeeListLoading}
              totalCount={totalEmployees}
              resultCount={employeeList?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {employeeList?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isEmployeeListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default EmployeeListReport;
