import {
  Box,
  Center,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { EN, ESP, Logo } from 'assets/images';
import { strings } from 'config/localization';
import {
  THEME_ANNOUNCEMENT,
  THEME_CHAT,
  THEME_COMPANY,
  THEME_FAQ,
  THEME_FIELD_REPORT,
  THEME_HAZARD,
  THEME_LOCATION,
  THEME_MAP_FEATURE,
  THEME_PPE,
  THEME_REPORTS,
  THEME_SETTING,
  THEME_TASK,
  THEME_USER,
} from 'constants/common';
import routes from 'constants/routes';
import { LanguageTypesSchema } from 'constants/schema';
import { ThemeContext } from 'context/ThemeContext';
import React, { useContext, useState } from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import {
  BiBuildings,
  BiChart,
  BiChat,
  BiChevronDown,
  BiFile,
  BiHelpCircle,
  BiLocationPlus,
  BiMapPin,
  BiMask,
  BiMeteor,
  BiRss,
  BiTask,
  BiUser,
} from 'react-icons/bi';
import HideControl from 'services/HideControl';
import history from 'utils/history';

const SplashScreen: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  const currentLanguage = strings.getLanguage();
  const [currentFlag, setCurrentFlag] = useState(() =>
    currentLanguage === 'esp' ? ESP : EN
  );

  const handleLanguage = (Language: LanguageTypesSchema) => {
    setCurrentFlag(Language === 'en' ? EN : ESP);

    strings.setLanguage(Language);
    localStorage.setItem('language', Language);
    window.location.reload();
  };

  return (
    <>
      <Stack spacing="8">
        <Stack direction="row" justifyContent="center">
          <Stack direction="column" spacing="8">
            <Stack spacing="4">
              <Center className="logoDiv">
                <Image src={Logo} w={[10, 16, 20, 24]} />
              </Center>
              <Stack>
                <Text
                  align="center"
                  color={'green.600'}
                  fontSize={['sm', 'lg', 'xl', '2xl']}
                  fontWeight="medium">
                  ESG onSite
                </Text>
              </Stack>
            </Stack>
          </Stack>

          <Stack pos="absolute" right="10%">
            <Menu>
              <MenuButton
                p={2}
                transition="all 0.2s"
                _hover={{ borderBottom: 'gray.300' }}
                _expanded={{ borderBottom: 'gray.400' }}>
                <Flex>
                  <MenuItem icon={<Image src={currentFlag} w="6" h="6" />}>
                    {currentLanguage?.toLocaleUpperCase()}
                  </MenuItem>
                  <Box pt="2">
                    <BiChevronDown />
                  </Box>
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => handleLanguage('esp')}
                  icon={<Image src={ESP} w="6" h="6" />}>
                  ESP
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => handleLanguage('en')}
                  icon={<Image src={EN} w="6" h="6" />}>
                  EN
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
        <Center>
          <SimpleGrid
            columns={[2, 3, 4, 4]}
            spacing="8"
            maxW="container.lg"
            w="full">
            <HideControl
              hideFor="company-list"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_COMPANY);
                  history.push(routes.companies.list);
                }}>
                <Tooltip hasArrow label={strings.company_management}>
                  <Box
                    bg="brown.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'brown.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiBuildings} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.company}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="location-list"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_LOCATION);
                  history.push(routes.locations.list);
                }}>
                <Tooltip hasArrow label={strings.location_management}>
                  <Box
                    bg="purple.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'purple.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiLocationPlus} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.location}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="task"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_TASK);
                  history.push(routes.workType.list);
                }}>
                <Tooltip hasArrow label={strings.tasks}>
                  <Box
                    bg="teal.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'teal.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiTask} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.tasks}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="ppe-list"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_PPE);
                  history.push(routes.ppe.list);
                }}>
                <Tooltip hasArrow label={strings.ppe_management}>
                  <Box
                    bg="red.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'red.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiMask} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.ppe}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="user-list"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_USER);
                  history.push(routes.users.list);
                }}>
                <Tooltip hasArrow label={strings.user_management}>
                  <Box
                    bg="blue.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'blue.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiUser} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.user_management}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="hazard-list"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_HAZARD);
                  history.push(routes.hazards.hazard.list);
                }}>
                <Tooltip hasArrow label={strings.hazard}>
                  <Box
                    bg="cyan.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'cyan.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiMeteor} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.hazard}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="announcement-list"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_ANNOUNCEMENT);
                  history.push(routes.announcements.list);
                }}>
                <Tooltip hasArrow label={strings.announcement}>
                  <Box
                    bg="burlywood.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'burlywood.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiRss} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.announcement}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="reports"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_REPORTS);
                  history.push(routes.reports.jsa.list);
                }}>
                <Tooltip hasArrow label={strings.report}>
                  <Box
                    bg="malibu.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'malibu.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiChart} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.report}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="chats"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_CHAT);
                  history.push(routes.chats.list);
                }}>
                <Tooltip hasArrow label={strings.chat}>
                  <Box
                    bg="darkbrown.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'darkbrown.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiChat} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.chat}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="faqs"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_FAQ);
                  history.push(routes.faqs.list);
                }}>
                <Tooltip hasArrow label={strings.faqs}>
                  <Box
                    bg="yellow.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'yellow.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiHelpCircle} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.faqs}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="map-feature"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_MAP_FEATURE);
                  history.push(routes.mapFeatures.view);
                }}>
                <Tooltip hasArrow label={strings.map_features}>
                  <Box
                    bg="purple.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'purple.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiMapPin} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.map_features}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="field-report"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_FIELD_REPORT);
                  history.push(routes.fieldReporting.environmentTracker.list);
                }}>
                <Tooltip hasArrow label={strings.field_report}>
                  <Box
                    bg="purple3.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'purple3.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={BiFile} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.field_report}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
            <HideControl
              hideFor="settings"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Link
                sx={linkStyled}
                onClick={() => {
                  setTheme(THEME_SETTING);
                  history.push(routes.settings.rolePermissions.list);
                }}>
                <Tooltip hasArrow label={strings.settings}>
                  <Box
                    bg="green2.400"
                    sx={navBoxStyles}
                    _hover={{ bg: 'green2.500', boxShadow: '2xl' }}>
                    <Stack sx={innerBoxStyled}>
                      <Icon as={AiOutlineSetting} sx={iconStyles} />
                      <Text sx={titleStyled}>{strings.settings}</Text>
                    </Stack>
                  </Box>
                </Tooltip>
              </Link>
            </HideControl>
          </SimpleGrid>
        </Center>
      </Stack>
    </>
  );
};
export default SplashScreen;

const navBoxStyles = {
  p: [4, 4, 4, 10],
  color: 'white',
  rounded: 'sm',
  cursor: 'pointer',
  transition: 'ease-in-out',
  transitionDuration: '0.35s',
};

const iconStyles = {
  w: [6, 8, 12, 12],
  h: [6, 8, 12, 12],
  m: 'auto',
};

const innerBoxStyled = {
  spacing: [2, 2, 4, 4],
  direction: 'column',
  alignItem: 'center',
};

const titleStyled = {
  color: 'white',
  align: 'center',
  textAlign: 'center',
  fontSize: [8, 10, 12, 14],
};
const linkStyled = {
  textDecoration: 'none',
};
