import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import SOPSResource from 'api/sops';
import { CenterSpinner } from 'components/common/CenterSpinner';
import SOPSForm from 'components/sops/SOPSForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { SOPSSchema } from 'constants/schema';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditSOPS: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();

  const methods = useForm<SOPSSchema>();

  const sopsAPI = new SOPSResource();

  const sopsQuery = useQuery(
    [`sops${id}-edit`, id],
    () => sopsAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateSOPS = useMutation((data: any) => sopsAPI.update(id, data));

  const onSubmit = (data: any) => {
    const formData = {
      company_id: data.company_id.value,
      work_type_id: data.work_type_id.value,
      sop_link: data.sop_link,
    };
    updateSOPS.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.sops_updated_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('sopsList');
        history.push(routes.sops.list);
      },
    });
  };

  if (sopsQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (sopsQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.sops_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.sops.list}>
            {strings.sops}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.edit_sops}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_sops}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateSOPS.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.sops_update_failed}
                </Alert>
              )}
              <SOPSForm sopsData={sopsQuery.data.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateSOPS.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditSOPS;
