import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FieldTypeResource from 'api/field_type';
import FieldTypeForm from 'components/fieldTypes/FieldTypeForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const CreateFieldType: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<any>();
  const fieldTypeAPI = new FieldTypeResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addFieldType = useMutation((data: any) => fieldTypeAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.field_type_success_msg,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('fieldTypeList');
      history.push(routes.fieldReporting.fieldType.list);
    },
    onError: (error: any) => {
      setErrorMessage(error?.response?.data?.errors);
    },
  });

  const onSubmit = (data: any) => {
    let formData = {
      title: data?.title,
      field_report: data?.field_report?.value,
    };
    addFieldType.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.fieldReporting.fieldType.list}>
            {strings.field_type_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.fieldReporting.fieldType.create}>
            {strings.add_new_field_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.add_new_field_type}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addFieldType.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.field_type_creation_failed}
                </Alert>
              )}
              <FieldTypeForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addFieldType.isLoading}>
                  {strings.add_field_type}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateFieldType;
