import {
  Button,
  Center,
  Icon,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import ChatResource from 'api/chat';
import React, { useEffect, useRef, useState } from 'react';
import { BiPaperPlane } from 'react-icons/bi';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQueryClient } from 'react-query';
import { socket } from 'services/socket';
import ChatMessageDetails from './ChatMessageDetails';
interface Props {
  conversation: string;
}

const ChatBot: React.FC<Props> = (props) => {
  const { conversation } = props;
  const [scrollEl, setScrollEl] = useState<any>();
  const txtAreaChatRef = useRef<HTMLTextAreaElement>(null);
  const chatAPI = new ChatResource();
  const queryClient = useQueryClient();
  const [chatMessages, setChatMessages] = useState<any>([]);
  const [nextMessage, setNextMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAllMessage = async (conversation: any) => {
    const response = await chatAPI.getAllMessage(conversation);
    if (response?.data?.results.length > 1) {
      setChatMessages(response?.data?.results.reverse());
      setNextMessage(response?.data?.next);
    } else {
      setChatMessages(response?.data?.results);
    }
  };

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  });
  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    fetchAllMessage(conversation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  useEffect(() => {
    // Receiving Message
    socket.on('message_recieved', (data: any) => {
      queryClient.invalidateQueries('fetchSearchChatUser');
      if (data?.data?.conversation === conversation) {
        setChatMessages((prev: any) => [...prev, data?.data]);
        scrollEl.scrollTop = scrollEl.scrollHeight;
      }
    });
    return () => {
      socket.off('message_recieved');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollEl, conversation, socket]);

  /**
   * handle Chat by enter key in textArea
   */
  const handleChatByEnter = (e: any) => {
    if (e.key === 'Enter' && txtAreaChatRef.current?.value) {
      //save to database
      saveChat(txtAreaChatRef.current?.value);
      queryClient.invalidateQueries('messageList');
      e.preventDefault();
    }
  };

  /**
   * Save chat message
   */
  const saveChat = async (message: String) => {
    if (message.trim() === '') return;
    const socketData = {
      text: message,
    };
    const messageDetails = await chatAPI.sendMessage(conversation, socketData);
    if (messageDetails) {
      txtAreaChatRef.current!.value = '';
      txtAreaChatRef.current?.focus();
    }
  };

  /**
   * handle Chat APP
   */
  const handleChat = () => {
    // save to database
    if (txtAreaChatRef.current?.value?.trim())
      saveChat(txtAreaChatRef.current?.value);
  };

  const executeScroll = async (container: any) => {
    if (container.scrollTop === 0 && nextMessage) {
      setLoading(true);
      const messages = await chatAPI.nextMessage(nextMessage);
      setNextMessage(messages?.data?.next);
      if (messages?.data?.results.length > 0) {
        const oldMessage = messages?.data?.results.reverse();
        setChatMessages([...oldMessage, ...chatMessages]);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Stack
      direction="column"
      pos="relative"
      p="1"
      bg="white"
      shadow="box"
      rounded="md"
      maxH="700px"
      overflow="auto">
      <PerfectScrollbar
        onScrollY={(ref) => {
          executeScroll(ref);
        }}
        containerRef={(ref: any) => {
          setScrollEl(ref);
        }}>
        <Stack h="calc (100vh-184px)" p="4">
          {loading && (
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          )}
          {chatMessages?.map((message: any, index: any) => (
            <ChatMessageDetails
              message={message}
              key={index + message.conversation}
            />
          ))}
        </Stack>
      </PerfectScrollbar>
      <Stack pt="2">
        <InputGroup size="md">
          <Textarea
            paddingRight="16"
            overflow="hidden"
            ref={txtAreaChatRef}
            onKeyPress={handleChatByEnter}
            placeholder="Aa"
          />
          <InputRightElement p="10">
            <Button
              size="md"
              rounded="3xl"
              bg="green.300"
              p="2"
              onClick={handleChat}
              _hover={{ color: 'white', bgColor: 'green.500' }}>
              <Icon as={BiPaperPlane} w="6" h="6" color="white" />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Stack>
    </Stack>
  );
};

export default ChatBot;
