import Resource from 'api/resource';
import http from 'utils/http';
class CompanyResource extends Resource {
  constructor() {
    super('company/');
  }

  itemList(query?: any) {
    return http({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    });
  }

  allList(query?: any) {
    return http({
      url: '/company/list/',
      method: 'get',
      params: query,
    });
  }

  approve(id: number) {
    return http({
      url: 'company-approve/' + id + '/',
      method: 'put',
      data: {
        is_approved: true,
      },
    });
  }

  changeStatus(data?: any) {
    return http({
      url: 'company/toggle-is-active/',
      method: 'post',
      data: data,
    });
  }

  relatedUsers(query?: any) {
    return http({
      url: 'company-users/',
      method: 'get',
      params: query,
    });
  }

  relatedLocations(id: any) {
    return http({
      url: `company/${id}/jobsites/`,
      method: 'get',
    });
  }

  report(query?: any) {
    return http({
      url: `jsa-company-report/`,
      method: 'get',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }
  // For Excel Report
  export() {
    return http({
      url: `jsa-company-report-export/`,
      method: 'get',
    });
  }

  companyMember() {
    return http({
      url: `company/members/`,
      method: 'get',
    });
  }

  updateCompany(id: any, resource: any) {
    return http({
      url: 'company/update/' + id,
      method: 'put',
      data: resource,
    });
  }

  /** open when Map pin clicked
   * Open Hazard
   */
  companyJobSiteHazard(query?: any) {
    return http({
      url: 'company/jobsite/hazards/',
      method: 'get',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }
}

export { CompanyResource as default };
