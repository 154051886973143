import { IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormatMDY } from 'utils';

interface Props {
  jsaData: any;
  index: number;
}
const JSAListItemReport: React.FC<Props> = (props) => {
  const { jsaData, index } = props;

  return (
    <Tr key={jsaData.id}>
      <Td>{index}</Td>
      <Td>{jsaData?.customer_name || '-'}</Td>
      <Td>{DateFormatMDY(jsaData?.created_at)}</Td>
      <Td>{jsaData?.company_name || '-'}</Td>
      <Td> {jsaData?.job_title}</Td>
      <Td>{jsaData?.completed_by || '-'}</Td>
      <Td>
        <RouterLink
          to={routes.reports.jsa.view.replace(':id', jsaData.id.toString())}>
          <Tooltip hasArrow label={strings.view_jsa_report}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_jsa_report}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default JSAListItemReport;
