import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import WorkTypeResource from 'api/work_type';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import WorkTypeListItem from 'components/WorkType/WorkTypeListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  WORK_TYPE_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import { WorkTypeSchema } from 'constants/schema';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { getStartingSerialNumber } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  search: string;
}

const WorkTypeList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const workTypeAPI = new WorkTypeResource();

  const [totalWorkType, setTotalWorkType] = useState<number | undefined>(
    undefined
  );
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '',
  });

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalWorkType,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: workTypeList, isLoading: isWorkTypeListLoading } = useQuery(
    [
      'workTypeList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.search,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.search) queryParams.search = filterParams.search;
      const response = await workTypeAPI.list(queryParams);
      setTotalWorkType(response?.data?.work_type?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.work_type;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: data.search,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleCSVDownload = async () => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Work Type');
    worksheet.addRow(WORK_TYPE_HEADER);

    workbook.csv.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel',
      });
      FileSaver.saveAs(blob, 'work-type-template.csv');
    });
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.work_type}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.work_type_list}</Heading>
          <Stack direction="row">
            <Button
              size="sm"
              colorScheme="primary"
              type="button"
              onClick={() => history.push(routes.workType.create)}>
              {strings.add_new_work_type}
            </Button>
            <CsvUpload
              url="work-types/upload/"
              setRefetch={setRefetch}
              headerSchema={WORK_TYPE_HEADER}
              label="Upload Work Type"
              icon={false}
            />
            <Button
                size="sm"
                colorScheme="primary"
                type="button"
                onClick={handleCSVDownload}>
                {strings.work_type_template}
              </Button>
          </Stack>
        </Flex>
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <Input
                  type="text"
                  placeholder={strings.search}
                  {...methods.register('search')}
                />
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button
                  isLoading={isLoading === 'filter'}
                  colorScheme="primary"
                  type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  isLoading={isLoading === 'reset'}
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.company}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isWorkTypeListLoading &&
                  workTypeList?.results?.map(
                    (workTypeData: WorkTypeSchema, index: number) => (
                      <WorkTypeListItem
                        workTypeData={workTypeData}
                        key={workTypeData.id}
                        index={startingSN + index}
                      />
                    )
                  )}
                {isWorkTypeListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isWorkTypeListLoading}
              totalCount={totalWorkType}
              resultCount={workTypeList?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {workTypeList?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isWorkTypeListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default WorkTypeList;
