import axios from 'axios';
import { useEffect, useState } from 'react';
import http from 'utils/http';

function useFileUpload(url: string) {
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [formdata, setFormdata] = useState<any>(null);
  const [uploadFile, setUploadFile] = useState<any>(null);

  let bucketStorageUrl = url.replace('import-csv', 'attachment');

  const uploadProgressHandler = (progressEvent: any) => {
    let percent = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadPercent(percent);
  };

  useEffect(() => {
    if (!formdata) return;
    let attachementData = new FormData();
    if (uploadFile) attachementData.append('csv_file', uploadFile);
    //TODO: let cancleUpload: any;
    if (formdata) {
      setLoading(true);
      http({
        url: bucketStorageUrl,
        method: 'POST',
        data: attachementData,
        onUploadProgress: uploadProgressHandler,
      })
        .then(() => {
          http({
            url,
            method: 'POST',
            data: formdata,
            //TODO: cancle token
            // cancelToken: new axios.CancelToken((c) => (cancleUpload = c)),
          })
            .then((res) => {
              setLoading(false);
              setResult(res);
            })
            .catch((err) => {
              if (axios.isCancel(err)) return;
              setLoading(false);
              setError(err.response);
            });
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          setLoading(false);
          setError(err.response);
        });
    }
    return () => {
      setUploadPercent(0);
      setFormdata(null);
    };
  }, [bucketStorageUrl, formdata, uploadFile, url]);

  return { uploadPercent, error, loading, result, setFormdata, setUploadFile };
}

export default useFileUpload;
