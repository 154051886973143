import { Avatar, IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormatMDY } from 'utils';

interface Props {
  jsaData: any;
  index: number;
}
const JobSummariesListItemReport: React.FC<Props> = (props) => {
  const { jsaData, index } = props;

  return (
    <Tr key={jsaData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.reports.jobSummaries.view.replace(
            ':id',
            jsaData.id.toString()
          )}>
          <Avatar size="sm" rounded="full" src={jsaData.image} />
        </RouterLink>
      </Td>
      <Td>{jsaData?.notes || '-'}</Td>
      <Td>{DateFormatMDY(jsaData?.created_at) || '-'}</Td>
      <Td>
        <RouterLink
          to={routes.reports.jobSummaries.view.replace(
            ':id',
            jsaData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_job_summaries_report}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_job_summaries_report}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default JobSummariesListItemReport;
