import PermissionRequest from 'constants/PermissionRequest';
import React from 'react';
import { connect } from 'react-redux';
import { checkSomePermissions } from 'utils/esg';

interface Props {
  hideFor: string;
  children?: any;
  renderNoAccess?: any;
  users: any;
}
const HideControl: React.FC<Props> = ({
  hideFor,
  children,
  renderNoAccess,
  users,
}) => {
  switch (hideFor) {
    case 'company-list':
      if (checkSomePermissions(users, [PermissionRequest['read:company']])) {
        return renderNoAccess(children);
      }
      break;
    case 'location-list':
      if (checkSomePermissions(users, [PermissionRequest['read:location']])) {
        return renderNoAccess(children);
      }
      break;
    case 'task':
      if (
        checkSomePermissions(users, [
          PermissionRequest['read:work-type'],
          PermissionRequest['read:sop'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'work-type-list':
      if (checkSomePermissions(users, [PermissionRequest['read:work-type']])) {
        return renderNoAccess(children);
      }
      break;
    case 'sop-list':
      if (checkSomePermissions(users, [PermissionRequest['read:sop']])) {
        return renderNoAccess(children);
      }
      break;
    case 'ppe-list':
      if (checkSomePermissions(users, [PermissionRequest['read:ppe']])) {
        return renderNoAccess(children);
      }
      break;
    case 'user-list':
      if (checkSomePermissions(users, [PermissionRequest['read:user']])) {
        return renderNoAccess(children);
      }
      break;
    case 'hazard-list':
      if (checkSomePermissions(users, [PermissionRequest['read:hazard']])) {
        return renderNoAccess(children);
      }
      break;
    case 'hazard-manage':
      if (checkSomePermissions(users, [PermissionRequest['manage:hazard']])) {
        return renderNoAccess(children);
      }
      break;
    case 'announcement-list':
      if (
        checkSomePermissions(users, [PermissionRequest['read:announcement']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'announcement-check-list':
      if (
        users?.role === 'service_company_admin' ||
        users?.role === 'operator_company_admin'
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'map-feature':
      if (
        checkSomePermissions(users, [PermissionRequest['read:map-feature']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'reports':
      if (
        checkSomePermissions(users, [
          PermissionRequest['read:company-report'],
          PermissionRequest['read:employee-report'],
          PermissionRequest['read:jsa-report'],
          PermissionRequest['read:jsa-summary-report'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'chats':
      if (checkSomePermissions(users, [PermissionRequest['manage:chat']])) {
        return renderNoAccess(children);
      }
      break;
    case 'company-reports':
      if (
        checkSomePermissions(users, [PermissionRequest['read:company-report']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'employee-reports':
      if (
        checkSomePermissions(users, [PermissionRequest['read:employee-report']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'jsa-reports':
      if (checkSomePermissions(users, [PermissionRequest['read:jsa-report']])) {
        return renderNoAccess(children);
      }
      break;
    case 'job-summaries-reports':
      if (
        checkSomePermissions(users, [
          PermissionRequest['read:jsa-summary-report'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'settings':
      if (users.user_role.toLowerCase() === 'superadmin') {
        return renderNoAccess(children);
      }
      break;
    case 'field-report':
      if (
        checkSomePermissions(users, [
          PermissionRequest['read:field-report'],
          PermissionRequest['read:field-type'],
          PermissionRequest['read:user-field-report'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'environment-tracker':
      if (
        checkSomePermissions(users, [PermissionRequest['read:field-report']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'field-type':
      if (checkSomePermissions(users, [PermissionRequest['read:field-type']])) {
        return renderNoAccess(children);
      }
      break;
    case 'user-field-report':
      if (
        checkSomePermissions(users, [
          PermissionRequest['read:user-field-report'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'deactivate-request-service':
      if (users.user_role.toLowerCase() === 'superadmin') {
        return renderNoAccess(children);
      }
      break;
    case 'profile-badges':
      if (users?.user_role?.toLowerCase() !== 'superadmin') {
        return renderNoAccess(children);
      }
      break;
    case 'faqs':
      if (
        checkSomePermissions(users, [PermissionRequest['manage:data-report']])
      ) {
        return renderNoAccess(children);
      }
      break;
  }

  return renderNoAccess();
};

HideControl.defaultProps = {
  renderNoAccess: () => null,
};

export default connect((state: any) => ({
  users: state?.data?.auth?.user?.user_information,
}))(HideControl);
