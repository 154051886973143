import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BiHide, BiShow } from 'react-icons/bi';
import { validatePassword } from 'utils/validate';

const ChangePassword: React.FC = () => {
  const newPassword = useRef({});
  const oldPassword = useRef({});
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  newPassword.current = watch('password', '');
  oldPassword.current = watch('old_password', '');

  return (
    <Stack direction="column" spacing="5">
      <FormControl isInvalid={!!errors?.old_password}>
        <FormLabel>{strings.current_password}</FormLabel>
        <InputGroup>
          <Input
            width="50%"
            type={showOldPassword ? 'text' : 'password'}
            placeholder={strings.current_password}
            {...register('old_password', {
              required: strings.current_password_is_incorrect,
            })}
          />
          <InputLeftElement
            width="97%"
            children={
              <IconButton
                icon={showOldPassword ? <BiHide /> : <BiShow />}
                onClick={handleClickOldPassword}
                aria-label={showOldPassword ? strings.hide : strings.show}
                size="sm"
              />
            }
          />
        </InputGroup>
        <FormErrorMessage>
          {errors?.old_password && errors?.old_password?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.password}>
        <FormLabel display="flex">{strings.new_password}</FormLabel>
        <InputGroup size="md">
          <Input
            width={'50%'}
            type={showNewPassword ? 'text' : 'password'}
            placeholder={strings.new_password}
            {...register('password', {
              required: strings.new_password,
              validate: (value) => {
                if (!validatePassword(value)) return strings.valid_password_msg;
                if (value === oldPassword.current) return strings.old_new_match;
              },
            })}
          />
          <InputLeftElement
            width="97%"
            children={
              <IconButton
                icon={showNewPassword ? <BiHide /> : <BiShow />}
                onClick={handleClickNewPassword}
                aria-label={showNewPassword ? strings.hide : strings.show}
                size="sm"
              />
            }
          />
        </InputGroup>
        <FormHelperText>{strings.password_hint}</FormHelperText>
        <FormErrorMessage>
          {errors?.password && errors?.password?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.confirm_password}>
        <FormLabel display="flex">{strings.confirm_password}</FormLabel>
        <InputGroup>
          <Input
            width="50%"
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder={strings.confirm_password}
            {...register('confirm_password', {
              required: strings.confirm_password,
              validate: (value) =>
                value === newPassword.current || strings.password_not_match,
            })}
          />
          <InputLeftElement
            width="97%"
            children={
              <IconButton
                icon={showConfirmPassword ? <BiHide /> : <BiShow />}
                onClick={handleClickConfirmPassword}
                aria-label={showConfirmPassword ? strings.hide : strings.show}
                size="sm"
              />
            }
          />
        </InputGroup>
        <FormErrorMessage>
          {errors?.confirm_password && errors?.confirm_password?.message}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  );
};

export default ChangePassword;
