import { Avatar, Flex, Heading, Stack, Tag } from '@chakra-ui/react';
import { setCurrentChatUser } from 'actions/data/chat';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { socket } from 'services/socket';
interface Props {
  name: string;
  conversation: string;
  attachment: string;
  isSeen: boolean;
  unreadChatCount?: number;
}

const ChatListItem: React.FC<Props> = (props) => {
  const { name, conversation, attachment, isSeen, unreadChatCount = 0 } = props;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { currentChatUserDetails } = useSelector(
    (state: any) => ({
      currentChatUserDetails: state?.data?.chat.currentChatUser,
    }),
    shallowEqual
  );

  const handleCurrentChatUser = async () => {
    queryClient.invalidateQueries('fetchSearchChatUser');
    socket.emit(
      'join_chat',
      {
        conversation_id: conversation,
      },
      (cb: any) => {}
    );
    dispatch(setCurrentChatUser({ name, conversation, attachment }));
  };

  useEffect(() => {
    if (unreadChatCount > 0) {
    }
  }, [unreadChatCount]);

  return (
    <>
      <Stack
        _hover={{ cursor: 'pointer' }}
        position="relative"
        rounded="sm"
        p="1"
        onClick={() => handleCurrentChatUser()}>
        <Stack
          direction={['column', 'column']}
          spacing="2"
          p={['1', '2', '4', '4']}
          shadow="box"
          bg="white"
          sx={
            currentChatUserDetails.conversation === conversation
              ? { boxShadow: '0px 3px 0px 0px #8ef2d3' }
              : { boxShadow: 'none' }
          }>
          <Stack direction={['column', 'column', 'row', 'row']} width="100%">
            <Stack width={['25%', '10%']}>
              <Avatar
                showBorder
                shadow="md"
                size={'sm'}
                name={name}
                src={attachment}
              />
            </Stack>
            <Flex
              justify="space-between"
              width={['75%', '90%']}
              pl={['2', '1']}
              direction={['column', 'column', 'row', 'row']}>
              <Heading
                width="75%"
                size="xs"
                fontWeight="medium"
                alignSelf="center"
                wordBreak="break-all">
                {name}
              </Heading>
            </Flex>
            Th
          </Stack>
        </Stack>
        {unreadChatCount > 0 &&
          currentChatUserDetails.conversation !== conversation && (
            <Tag
              bg="red.400"
              rounded="full"
              color="white"
              fontSize="10px"
              position="absolute"
              right="3"
              bottom="3"
              size="sm">
              {unreadChatCount}
            </Tag>
          )}
      </Stack>
    </>
  );
};

export default ChatListItem;
