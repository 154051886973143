import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import CompanyActiveCrew from './CompanyActiveCrew';
import CrewList from './CrewList';
import HazardList from './HazardList';
import JSAList from './JSAList';
interface Props {
  jsaData: any;
  hazardData: any;
  hasHazard: boolean;
  hasCrew: boolean;
  crewData: any;
  hasCompanyUser: boolean;
  companyId: any;
}

const JSAForm: React.FC<Props> = (props) => {
  const {
    jsaData,
    hazardData,
    hasHazard,
    crewData,
    hasCrew,
    hasCompanyUser,
    companyId,
  } = props;

  return (
    <>
      {props.jsaData && (
        <Tabs variant="unstyled" isFitted defaultIndex={0}>
          <TabList border={'1px solid #3182CE'}>
            <Tab _selected={{ color: 'white', bg: '#7F7F7F' }}>
              {strings.jsa}
            </Tab>
            {hasCrew && (
              <Tab _selected={{ color: 'white', bg: '#4473C5' }}>
                {strings.crew_member}
              </Tab>
            )}
            {hasHazard && (
              <Tab _selected={{ color: 'white', bg: '#ED7D31' }}>
                {strings.hazard}
              </Tab>
            )}
            {hasCompanyUser && (
              <Tab _selected={{ color: 'white', bg: '#71AD46' }}>
                {strings.company_active_crew}
              </Tab>
            )}
          </TabList>

          <TabPanels>
            {jsaData && (
              <TabPanel>
                <JSAList query={jsaData} />
              </TabPanel>
            )}

            {hasCrew && (
              <TabPanel>
                <CrewList query={crewData} />
              </TabPanel>
            )}

            {hasHazard && (
              <TabPanel>
                <HazardList query={hazardData} />
              </TabPanel>
            )}

            {hasCompanyUser && (
              <TabPanel>
                <CompanyActiveCrew companyId={companyId} signJSAId={crewData} />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

export default JSAForm;
