export const messageSentStyles = {
  alignSelf: 'flex-end',
  textAlign: 'left',
  // maxW: 'calc(100% - 80px)',
  direction: 'column',
  bg: 'green.100',
  p: '6',
  borderTopLeftRadius: '2xl',
  borderTopRightRadius: '2xl',
  borderBottomRightRadius: '2xl',
  borderBottomLeftRadius: '2xl',
};
export const messageSentDateTimeStyled = {
  alignSelf: 'flex-end',
  textAlign: 'right',
  maxW: 'calc(100% - 80px)',
  direction: 'column',
};
export const messageReceiveStyled = {
  direction: 'column',
  spacing: '1',
  bg: 'gray.100',
  width: 'fit-content',
  maxW: 'calc(100% - 80px)',
  p: '6',
  borderTopLeftRadius: '2xl',
  borderTopRightRadius: '2xl',
  borderBottomRightRadius: '2xl',
  borderBottomLeftRadius: '2xl',
};
