import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import HazardTypeResource from 'api/hazard_type';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const ViewHazardType: React.FC = () => {
  const { id }: any = useParams();
  const hazardTypeAPI = new HazardTypeResource();
  const toast = useToast();

  const hazardTypeQuery = useQuery<any>(
    [`hazard${id}`, id],
    () => hazardTypeAPI.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const hazardTypeData = hazardTypeQuery?.data;

  if (hazardTypeQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (hazardTypeQuery.isError) {
    toast({
      title: 'Hazard Type detail not found',
      status: 'error',
      isClosable: true,
    });
    history.push(routes.hazards.hazardType.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.hazards.hazardType.list}>
              {strings.hazard_type}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.hazard_details}
          </Heading>
          <Stack direction="row" spacing="6">
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() =>
                history.push(routes.hazards.hazardType.edit.replace(':id', id))
              }>
              {strings.edit_hazard_type}
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.name}</FormLabel>
                <Box sx={infoStyles}>{hazardTypeData?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
                <Box sx={infoStyles}>{hazardTypeData?.description || '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewHazardType;
