export const getIcon = (data: any) => {
  var svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 464.68 464.68"><defs><style>.cls-1{fill:#7f8080;}.cls-1,.cls-2,.cls-3,.cls-4{stroke:#000;stroke-miterlimit:10;}.cls-2{${
    data?.is_crew_member ? 'fill:#70ae45;' : 'stroke:#000;fill-opacity: .01;'
  }}.cls-3{${
    data?.is_hazard ? 'fill:#ed7d2d;' : 'stroke:#000;fill-opacity: .01;'
  }}.cls-4{${
    data?.is_company_user ? 'fill:#4473C5;' : 'stroke:#000;fill-opacity: .01;'
  }</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><path class="cls-1" d="M232.34.5c79.34,0,95.64,91.85,60.4,126.28-29.45,28.77-60.4,89.77-60.4,103.56,0-13.79-30.94-74.79-60.39-103.56C136.7,92.35,153,.5,232.34.5Z"/><path class="cls-2" d="M.5,232.34C.5,153,92.35,136.7,126.78,172c28.77,29.45,89.77,60.39,103.56,60.39-13.79,0-74.79,31-103.56,60.4C92.35,328,.5,311.68.5,232.34Z"/><path class="cls-3" d="M232.34,464.18C153,464.18,136.7,372.33,172,337.9c29.45-28.77,60.39-89.77,60.39-103.56,0,13.79,31,74.79,60.4,103.56C328,372.33,311.68,464.18,232.34,464.18Z"/><path class="cls-4" d="M464.18,232.34c0,79.34-91.85,95.64-126.28,60.4-28.77-29.45-89.77-60.4-103.56-60.4,13.79,0,74.79-30.94,103.56-60.39C372.33,136.7,464.18,153,464.18,232.34Z"/></g></g></svg>`;
  return encodeURIComponent(svg);
};
