import {
  Avatar,
  Box,
  Button, 
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useState } from 'react';

interface Props {
  endSummaryData: any;
}


const JSAEndSummary: React.FC<Props> = (props) => {
  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };
  const [isImageModalOpen, setImageModelOpen] = useState(false);
  const { endSummaryData } = props;
  
  const openImage = () => {
    setImageModelOpen(true)
  }

  const onImageModalClose = () => {
    setImageModelOpen(false);
  };

  return (
    <>
      <Flex justify="space-between" marginTop={3}>
        <Heading size="md" textTransform="capitalize">
          {strings.jsa_end_summary}
        </Heading>
      </Flex>
      <Stack
        bg="white"
        p={['3', '6']}
        shadow="box"
        rounded="sm"
        marginTop={3}
        marginBottom={3}>
        <Grid
          gap="8"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.image}</FormLabel>
              <Box sx={infoStyles}>
                <img src={endSummaryData?.image} onClick={openImage} />
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.notes}</FormLabel>
              <Box sx={infoStyles}>{endSummaryData?.notes || '-'}</Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      
      <Modal
          isOpen={isImageModalOpen}
          isCentered
          onClose={onImageModalClose}
          size ={'4xl'}>
          <ModalOverlay />
          <ModalContent>
          <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Image src={endSummaryData?.image} width='100%'/>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </>
  );
};
export default JSAEndSummary;
