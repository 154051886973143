import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FieldReportingResource from 'api/field_reporting';
import UserFieldReportResource from 'api/user_field_report';
import { CenterSpinner } from 'components/common/CenterSpinner';
import HazardAssignForm from 'components/hazard/HazardAssignForm';
import FieldMeasurementList from 'components/userFieldReport/FieldMeasurement';
import UserFieldReportListItem from 'components/userFieldReport/UserFieldReportListItem';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { FieldReportingSchema } from 'constants/schema';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { truncateString } from 'utils';

const ViewFieldReporting: React.FC = () => {
  const { id }: any = useParams();
  const fieldReportingAPI = new FieldReportingResource();

  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const userFieldReportAPI = new UserFieldReportResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addUserFieldReport = useMutation(
    (data: any) => userFieldReportAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.field_report_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userFieldReportList');
        history.push(
          routes.fieldReporting.environmentTracker.view.replace(':id', id)
        );
      },
      onError: (error: any) => {
        setErrorMessage(error?.response?.data?.errors);
      },
    }
  );

  const onSubmit = (data: any) => {
    let formData = {
      company: fieldReportingQuery?.data?.company,
      field_type: data?.field_type?.value,
      description: data?.description,
      field_report_data: data?.field_report_data,
      field_report: id,
    };
    addUserFieldReport.mutate(formData);
  };

  const fieldReportingQuery = useQuery<FieldReportingSchema>(
    [`fieldReportingQuery${id}`, id],
    () =>
      fieldReportingAPI.get(id).then((res) => {
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (fieldReportingQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (fieldReportingQuery.isError) {
    history.push(routes.fieldReporting.environmentTracker.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  const handleButtonClick = () => {
    history.push(
      routes.fieldReporting.environmentTracker.create_field_type.replace(
        ':id',
        id
      )
    );
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.fieldReporting.environmentTracker.list}>
              {strings.field_report}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {truncateString(fieldReportingQuery?.data?.name || '')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.field_reporting_details}
          </Heading>
          <HideControl
            hideFor="hazard-manage"
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            {!fieldReportingQuery?.data?.name && (
              <Stack direction="row" spacing="6">
                <HazardAssignForm hazardId={id} />
              </Stack>
            )}
          </HideControl>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.name}</FormLabel>
                <Box sx={infoStyles}>
                  {fieldReportingQuery?.data?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
                <Box sx={infoStyles}>
                  {fieldReportingQuery?.data?.description || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.created_by}</FormLabel>
                <Box sx={infoStyles}>
                  {`${fieldReportingQuery?.data?.created_by?.first_name} ${fieldReportingQuery?.data?.created_by?.last_name}` ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
                <Box sx={infoStyles}>
                  {fieldReportingQuery?.data?.company_detail?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid w="100%">
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.measurement_units}
              </FormLabel>
              <FieldMeasurementList
                fieldMeasurement={fieldReportingQuery?.data?.field_report_unit}
              />
            </FormControl>
          </Grid>
        </Stack>

        <Flex justify="space-between">
          <Heading size="md">{strings.field_report}</Heading>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() => handleButtonClick()}>
            {strings.add_new_user_field_report}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.field_report}</Th>
                  <Th>{strings.field_type}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!fieldReportingQuery.isLoading &&
                  fieldReportingQuery?.data?.user_field_report?.map(
                    (userFieldReport: any, index: number) => (
                      <UserFieldReportListItem
                        userFieldReportData={userFieldReport}
                        index={index + 1}
                        page="environment-tracker"
                        key={userFieldReport.id}
                        companyName={fieldReportingQuery?.data?.name}
                        fieldReport={fieldReportingQuery?.data?.company}
                      />
                    )
                  )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewFieldReporting;
