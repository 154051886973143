import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FieldTypeResource from 'api/field_type';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FieldTypeForm from 'components/fieldTypes/FieldTypeForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditFieldType: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<any>();

  const fieldTypeAPI = new FieldTypeResource();

  const fieldTypeQuery = useQuery(
    [`field-type${id}-edit`, id],
    () => fieldTypeAPI.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateFieldType = useMutation((data: any) =>
    fieldTypeAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    let formData = {
      title: data?.title,
      field_report: data?.field_report?.value,
    };
    updateFieldType.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.field_type_update_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('fieldTypeList');
        history.push(routes.fieldReporting.fieldType.list);
      },
    });
  };

  if (fieldTypeQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (fieldTypeQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.field_type_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.fieldReporting.fieldType.list}>
            {strings.field_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.fieldReporting.fieldType.edit}>
            {strings.edit_field_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_field_type}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateFieldType.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.field_type_update_err_msg}
                </Alert>
              )}
              <FieldTypeForm fieldTypeData={fieldTypeQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateFieldType.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditFieldType;
