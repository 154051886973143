export enum ChatActionTypes {
  SET_CURRENT_CHAT_USER = 'SET_CURRENT_CHAT_USER',
  SET_CHAT_ITEM_LIST = 'SET_CHAT_ITEM_LIST',
}

export interface ChatAction {
  data: boolean;
  type: ChatActionTypes;
}

/**
 * Action to get current chat user in chatbot.
 * @param {object} currentChatUser
 * @returns {object}
 */
export function setCurrentChatUser(currentChatUser: any): ChatAction {
  return {
    data: currentChatUser,
    type: ChatActionTypes.SET_CURRENT_CHAT_USER,
  };
}

/**
 *
 * @param chatItems
 * @returns
 */
export function setChatItemList(chatItems: any): ChatAction {
  return {
    data: chatItems,
    type: ChatActionTypes.SET_CHAT_ITEM_LIST,
  };
}
