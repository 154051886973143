import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import SOPSResource from 'api/sops';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';

interface Props {
  sopsData: any;
  index: number;
}
const SOPSListItem: React.FC<Props> = (props) => {
  const { sopsData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const sopsAPI = new SOPSResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteSOPS = useMutation(() => sopsAPI.destroy(sopsData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('sopsList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.sop_delete_success_msg,
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteSOPS.mutate();
  };

  return (
    <Tr key={sopsData.id}>
      <Td>{index}</Td>
      <Td>{sopsData?.work_type?.name}</Td>
      <Td>{sopsData?.company?.name}</Td>
      <Td>
        <Link href={sopsData?.sop_link} isExternal>
          {truncateString(sopsData?.sop_link) || '-'}
        </Link>
      </Td>

      <Td>
        <RouterLink
          to={routes.sops.view.replace(':id', sopsData.id.toString())}>
          <Tooltip hasArrow label={strings.view_sop}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_sop}
              color="green.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.sops.edit.replace(':id', sopsData.id.toString())}>
          <Tooltip hasArrow label={strings.edit_sop}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_sop}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_sop}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_sop}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_sop}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteSOPS.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default SOPSListItem;
