import { ChakraProvider } from '@chakra-ui/react';
import 'assets/css/App.css';
// import '@fontsource/poppins/700.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import 'assets/css/theme.css';
import { strings } from 'config/localization';
import theme from 'config/theme';
import ThemeProvider from 'context/ThemeContext';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from 'router/Router';
import '../assets/css/font.css';
import '../assets/fonts/product-sans/Product Sans Bold Italic.ttf';
import '../assets/fonts/product-sans/Product Sans Bold.ttf';
import '../assets/fonts/product-sans/Product Sans Italic.ttf';
import '../assets/fonts/product-sans/Product Sans Regular.ttf';
import ErrorBoundary from './errors/ErrorBoundary';

const queryClient = new QueryClient();

const App = () => {
  let interfaceLang = strings.getInterfaceLanguage();
  if (interfaceLang !== 'en' && interfaceLang !== 'esp') {
    interfaceLang = 'en';
  }
  let storedLang = localStorage.getItem('language') ?? interfaceLang;
  strings.setLanguage(storedLang);

  return (
    <ThemeProvider>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </ThemeProvider>
  );
};

export default App;
