import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import HazardResource from 'api/hazard';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import HazardListItem from 'components/hazard/HazardListItem';
import { strings } from 'config/localization';
import CreatedAtInput, {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import { HazardSchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { BiCalendar, BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormatMDY, getStartingSerialNumber } from 'utils';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  reported_on: string;
  search: string;
  is_resolved: string;
}

const HazardList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const hazardAPI = new HazardResource();
  const [reportedOn, setReportedOn] = useState<any>();

  const [totalHazard, setTotalHazard] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    reported_on: '',
    search: '',
    is_resolved: '',
  });

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalHazard,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: hazardList, isLoading: isHazardListLoading } = useQuery(
    [
      'hazardList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        reported_on: filterParams.reported_on,
        search: filterParams.search,
        is_resolved: filterParams.is_resolved,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.search,
        reported_on: filterParams.reported_on,
        is_resolved: filterParams.is_resolved,
      };
      if (filterParams.search) queryParams.search = filterParams.search;
      if (filterParams.reported_on)
        queryParams.reported_on = DateFormatMDY(filterParams.reported_on);
      if (filterParams.is_resolved)
        queryParams.is_resolved = filterParams.is_resolved;
      const response = await hazardAPI.list(queryParams);
      setTotalHazard(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      reported_on: data.reported_on,
      search: data.name,
      is_resolved: data.is_resolved,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setReportedOn('');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: '',
      reported_on: '',
      is_resolved: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleDateChange = (date: Date) => {
    setReportedOn(date);
    methods.setValue('reported_on', date);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.hazard}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.hazards.hazard.list}>
              {strings.all_hazard}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.hazard_list}</Heading>
          <Button
            size="sm"
            colorScheme="primary"
            type="button"
            onClick={() => history.push(routes.hazards.hazard.create)}>
            {strings.add_new_hazard}
          </Button>
        </Flex>
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <Input
                  type="text"
                  placeholder={strings.search}
                  {...methods.register('name')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.date}</FormLabel>
                <InputGroup>
                  <InputRightElement
                    children={<BiCalendar color="green.500" />}
                  />

                  <ReactDatePicker
                    dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                    customInput={<CreatedAtInput />}
                    onChange={handleDateChange}
                    selected={reportedOn}
                    value={DateFormatMDY(reportedOn)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.is_resolved}</FormLabel>
                <Select
                  placeholder={strings.select}
                  rounded="sm"
                  {...methods.register('is_resolved')}>
                  <option value="true">{strings.yes}</option>
                  <option value="false">{strings.no}</option>
                </Select>
              </FormControl>

              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.location}</Th>
                  <Th>{strings.reported_by}</Th>
                  <Th>{strings.reported_date}</Th>
                  <Th>{strings.is_resolved}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isHazardListLoading &&
                  hazardList?.results?.map(
                    (hazardData: HazardSchema, index: number) => (
                      <HazardListItem
                        hazardData={hazardData}
                        key={hazardData.id}
                        index={startingSN + index}
                      />
                    )
                  )}
                {isHazardListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isHazardListLoading}
              totalCount={totalHazard}
              resultCount={hazardList?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {hazardList?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isHazardListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default HazardList;
