import {
  IconButton,
  ListItem,
  Td,
  Tooltip,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiEdit, BiRightArrowCircle, BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  permissionRoleData: any;
  index: number;
}
const RolePermissionsListItem: React.FC<Props> = (props) => {
  const { permissionRoleData, index } = props;

  const getPermissions = (data: any) => {
    return (
      <>
        <UnorderedList spacing="6" color="gray.500">
          {data.slice(0, 5).map((res: any) => (
            <ListItem key={res} style={{ textTransform: 'capitalize' }}>
              {res.split('_').join(' ')}
            </ListItem>
          ))}
        </UnorderedList>
        {data.length > 5 && (
          <RouterLink
            to={routes.settings.rolePermissions.view.replace(
              ':id',
              permissionRoleData.id.toString()
            )}>
            {/* <Tooltip hasArrow label={strings.view_permission_and_role}>
              <IconButton
                icon={<BiRightArrowCircle />}
                variant="link"
                aria-label={strings.view_permission_and_role}
                // color="green.500"
                size="lg"
              />
            </Tooltip> */}
          </RouterLink>
        )}
        {data.length === 0 && '-'}
      </>
    );
  };

  return (
    <Tr key={permissionRoleData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.settings.rolePermissions.view.replace(
            ':id',
            permissionRoleData?.id.toString()
          )}>
          {strings.getString(
            permissionRoleData?.name.toLowerCase().split(' ').join('_')
          )}
        </RouterLink>
      </Td>
      <Td>{getPermissions(permissionRoleData?.permissions)}</Td>
      <Td>
        <RouterLink
          to={routes.settings.rolePermissions.view.replace(
            ':id',
            permissionRoleData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_permission_and_role}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_permission_and_role}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.settings.rolePermissions.edit.replace(
            ':id',
            permissionRoleData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit_permission_and_role}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_permission_and_role}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default RolePermissionsListItem;
