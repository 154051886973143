import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import WorkTypeResource from 'api/work_type';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { WorkTypeSchema } from 'constants/schema';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { truncateString } from 'utils';

interface Props {
  workTypeData: WorkTypeSchema;
  index: number;
}
const WorkTypeListItem: React.FC<Props> = (props) => {
  const { workTypeData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const workTypeAPI = new WorkTypeResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteWorkType = useMutation(
    () => workTypeAPI.destroy(workTypeData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('workTypeList');

        if (res.data[0].success) {
          setDeleteModalOpen(false);
          toast({
            title: strings.work_type_delete_success_msg,
            status: 'success',
            isClosable: true,
          });
        }
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteWorkType.mutate();
  };

  return (
    <Tr key={workTypeData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.workType.view.replace(':id', workTypeData?.id.toString())}>
          {workTypeData.name}
        </RouterLink>
      </Td>
      {/* <Td>
        <Link href={workTypeData?.sop_link} isExternal>
          {truncateString(workTypeData?.sop_link) || '-'}
        </Link>
      </Td> */}
      <Td>{truncateString(workTypeData?.description) || '-'}</Td>
      <Td>{truncateString(workTypeData?.company_details?.name) || '-'}</Td>
      <Td>
        <RouterLink
          to={routes.workType.view.replace(':id', workTypeData.id.toString())}>
          <Tooltip hasArrow label={strings.view_work_type}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_work_type}
              color="green.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.workType.edit.replace(':id', workTypeData.id.toString())}>
          <Tooltip hasArrow label={strings.edit_work_type}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_work_type}
              color="blue.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
        <AccessControl
          allowedPermissions={[
            PermissionRequest['read:work-type'],
            PermissionRequest['manage:work-type'],
          ]}
          renderNoAccess={(data: any) => data}>
          <Tooltip hasArrow label={strings.delete_work_type}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_work_type}
              color="red.300"
              size={'lg'}
              onClick={onDeletePress}
            />
          </Tooltip>
        </AccessControl>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_work_type}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteWorkType.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default WorkTypeListItem;
