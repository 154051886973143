import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY || '');

Geocode.setLanguage('en');

// Enable or disable logs. Its optional.
Geocode.enableDebug();

export async function GoogleGeoCode(lat: string, lng: string) {
  return Geocode.fromLatLng(lat, lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      let city, state, country;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'locality':
              city = response.results[0].address_components[i].long_name;
              break;
            case 'administrative_area_level_1':
              state = response.results[0].address_components[i].long_name;
              break;
            case 'country':
              country = response.results[0].address_components[i].long_name;
              break;
          }
        }
      }
      return {
        city: city,
        state: state,
        country: country,
        address: address,
      };
    },

    // (error) => {
    //   window.console.error('Error', error);
    // })
  ).catch((err) =>{
    console.log(err)
    throw new Error('Map is not ready yet!', err)
    // window.console.error("Error", err)
  });
}
