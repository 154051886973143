import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import VendorResource from 'api/user';
import {
  activeStyles,
  normalStyles,
  reactSelectStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import VendorListItem from 'components/reports/VendorListItemReport';
import CreatedAtInput, {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import React, { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import {
  BiCalendar,
  BiChevronLeft,
  BiChevronRight,
  BiExport,
} from 'react-icons/bi';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { DateFormat, DateFormatMDY, getStartingSerialNumber } from 'utils';
import { csvExport } from 'utils/fileExport';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  createdAt: string;
  keyword: string;
  searchCompany: string;
}

const VendorListReport: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const vendorAPI = new VendorResource();
  const [createdAt, setCreatedAt] = useState<any>();
  const [totalVendor, setTotalVendor] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    createdAt: '',
    keyword: '',
    searchCompany: '',
  });
  const companyAPI = new CompanyResource();

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [
      `company-list`,
      {
        search: filterParams.searchCompany,
        is_active: true,
        is_approved: true,
      },
    ],
    () =>
      companyAPI
        .itemList({ search: filterParams.searchCompany, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.results;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalVendor,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: vendorList, isLoading: isVendorListLoading } = useQuery(
    [
      'vendorList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        createdAt: filterParams.createdAt,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.createdAt)
        queryParams.createdAt = DateFormatMDY(filterParams.createdAt);
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await companyAPI.report(queryParams);
      setTotalVendor(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data?.results;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      createdAt: data.createdAt,
      keyword: data.name,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setCreatedAt('');
    methods.setValue('company', '');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      createdAt: '',
      keyword: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchCompany: value,
    }));
  };

  const handleDateChange = (date: Date) => {
    setCreatedAt(date);
    methods.setValue('createdAt', date);
  };

  const handleExport = async () => {
    let dataArray: any = [];
    dataArray = await vendorList?.map((res: any, index: number) => {
      return {
        'S.N.': index + 1,
        'Company Name': res?.company,
        'Date Added': DateFormatMDY(res?.date_added) || '-',
        'Date Removed': DateFormatMDY(res?.date_added) || '-',
        Location: res?.location || '-',
        'Number of Jobs Worked': res?.number_of_jobs,
        'Total Hours Worked': res?.total_hours_worked,
        'Total Users': res?.total_workers,
      };
    });
    csvExport(dataArray, `VendorReport-${DateFormat(new Date())}` + ' .xlsx');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md">Vendor Report</Heading>
          <IconButton
            size="sm"
            fontSize="md"
            onClick={handleExport}
            variant="outline"
            rounded="sm"
            colorScheme="purple"
            aria-label="Export"
            icon={<BiExport />}
          />
        </Flex>

        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>Date</FormLabel>
                <InputGroup>
                  <InputRightElement
                    children={<BiCalendar color="green.500" />}
                  />
                  <ReactDatePicker
                    dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                    customInput={<CreatedAtInput value={createdAt} />}
                    onChange={handleDateChange}
                    selected={createdAt}
                    value={DateFormatMDY(createdAt)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Company</FormLabel>
                <Controller
                  control={methods.control}
                  name="company"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="company"
                      placeholder={'Select Company'}
                      isLoading={isCompanyLoading}
                      options={companyOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onInputChange={handleCompanyChange}
                    />
                  )}
                />
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  Filter
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  Reset Filter
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Company Name</Th>
                  <Th>Date Added</Th>
                  <Th>Date Removed</Th>
                  <Th>Location</Th>
                  <Th isNumeric>Number of Jobs Worked</Th>
                  <Th isNumeric>Total Hours Worked</Th>
                  <Th isNumeric>Total Users</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isVendorListLoading &&
                  vendorList?.map((vendorData: any, index: number) => (
                    <VendorListItem
                      vendorData={vendorData}
                      key={vendorData.id}
                      index={startingSN + index}
                    />
                  ))}
                {isVendorListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isVendorListLoading}
              totalCount={totalVendor}
              resultCount={vendorList?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {vendorList?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isVendorListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default VendorListReport;
