import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import AnnouncementResource from 'api/announcement';
import AnnouncementForm from 'components/announcement/AnnouncementForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { AnnouncementSchema } from 'constants/schema';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { buildFormData } from 'utils';
import { getFullName } from 'utils/esg';

const EditAnnouncement: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<AnnouncementSchema>();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const annoucementAPI = new AnnouncementResource();

  const announcementQuery = useQuery(
    [`announcement${id}-edit`, id],
    () => annoucementAPI.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateAnnouncement = useMutation((data: any) =>
    annoucementAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    data.written_by = getFullName(loggedInUser?.user_information);
    const formData = buildFormData(data);
    updateAnnouncement.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.announcement_update_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('announcementList');
        history.push(routes.announcements.list);
      },
    });
  };

  if (announcementQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (announcementQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.announcement_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.announcements.list}>
            {strings.announcement}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.announcements.edit}>
            {strings.edit_announcement}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_announcement}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateAnnouncement.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.announcement_update_failed}
                </Alert>
              )}
              <AnnouncementForm announcementData={announcementQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateAnnouncement.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditAnnouncement;
