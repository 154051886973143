import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import CustomTagTitle from 'components/common/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useState } from 'react';
import {
  BiEdit,
  BiListMinus,
  BiListPlus,
  BiShow,
  BiTrash,
} from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { truncateString } from 'utils';
import { getFullName } from 'utils/esg';

interface Props {
  userData: any;
  index: number;
}
const UserListItem: React.FC<Props> = (props) => {
  const { userData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const userAPI = new UserResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteUser = useMutation(() => userAPI.destroy(userData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('userList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.user_delete_success_msg,
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const changeStatusUser = useMutation(
    () =>
      userAPI.changeStatus({
        user_id: userData.id,
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('userList');

        setStatusModalOpen(false);
        toast({
          title: userData.is_active
            ? strings.user_deactivated
            : strings.user_activated,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onStatusPress = () => {
    setStatusModalOpen(true);
  };

  const onStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const onStatusConfirm = () => {
    changeStatusUser.mutate();
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteUser.mutate();
  };

  return (
    <Tr key={userData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.users.profile.replace(':id', userData.id.toString())}>
          <HStack>
            <Avatar size="sm" rounded="full" src={userData.profile_image} />
            <CustomTagTitle
              type="text"
              status={userData?.is_active}
              name={getFullName(userData) || '-'}
            />
          </HStack>
        </RouterLink>
      </Td>
      <Td>{userData?.work_email || '-'}</Td>
      <Td>{truncateString(userData?.company_details?.name) || '-'}</Td>
      <Td>
        {strings.getString(
          userData?.role?.name.toLowerCase().split(' ').join('_')
        ) || '-'}
      </Td>
      <Td>
        <RouterLink
          to={routes.users.profile.replace(':id', userData.id.toString())}>
          <Tooltip hasArrow label={strings.view_user}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_user}
              color="green.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.users.edit.replace(':id', userData.id.toString())}>
          <Tooltip hasArrow label={strings.edit_user}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_user}
              color="blue.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
        <AccessControl
          allowedPermissions={[
            PermissionRequest['read:user'],
            PermissionRequest['manage:user'],
          ]}
          renderNoAccess={(data: any) => data}>
          <>
            <Tooltip
              hasArrow
              label={
                userData.is_active ? strings.hide_user : strings.show_user
              }>
              <IconButton
                icon={userData.is_active ? <BiListMinus /> : <BiListPlus />}
                variant="link"
                aria-label={
                  userData.is_active ? strings.hide_user : strings.show_user
                }
                color="red.300"
                size={'lg'}
                onClick={onStatusPress}
              />
            </Tooltip>
            <Tooltip hasArrow label={strings.delete_user}>
              <IconButton
                icon={<BiTrash />}
                variant="link"
                aria-label={strings.delete_user}
                color="red.300"
                size={'lg'}
                onClick={onDeletePress}
              />
            </Tooltip>
          </>
        </AccessControl>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_user}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteUser.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isStatusModalOpen}
          isCentered
          onClose={onStatusModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {userData.is_active ? strings.hide_user : strings.show_user}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {userData.is_active
                ? strings.confirm_hide_user
                : strings.confirm_show_user}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onStatusConfirm}
                  isLoading={changeStatusUser.isLoading}>
                  {strings.submit}
                </Button>

                <Button variant="outline" onClick={onStatusModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default UserListItem;
