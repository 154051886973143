import Resource from 'api/resource';
import http from 'utils/http';
class LocationResource extends Resource {
  constructor() {
    super('jobsite/');
  }

  jsaCompletedListReport(query?: any) {
    return http({
      url: 'jsa-completed-list/',
      method: 'get',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }

  jsaCompletedListReportExport() {
    return http({
      url: 'jsa-completed-list/',
      method: 'get',
    });
  }

  jsaCompletedListReportById(id: any) {
    return http({
      url: 'jsa-completed-list/' + id + '/',
      method: 'get',
    });
  }

  jobSummariesListReport(query?: any) {
    return http({
      url: 'jsa-end-work-summary/',
      method: 'get',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }

  jobSummariesListReportById(id: any) {
    return http({
      url: 'jsa-end-work-summary/' + id + '/',
      method: 'get',
    });
  }
}

export { LocationResource as default };
