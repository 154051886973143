import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import FieldReportingResource from 'api/field_reporting';
import FieldTypeResource from 'api/field_type';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useRef, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  fieldReportUnit?: any;
  fieldReportId: number;
}

interface FilterParams {
  currentPage: number;
  pageSize: number;
  search_company: string;
  search_field_report: string;
  search_field_type: string;
}

const EnvironmentTrackerUserFieldReportForm: React.FC<Props> = (props) => {
  const { fieldReportUnit, fieldReportId } = props;

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search_company: '', // for company List
    search_field_report: '',
    search_field_type: '',
  });

  const modalForm = useRef() as React.MutableRefObject<HTMLFormElement>;

  const {
    handleSubmit: handleModalSubmit,
    register: registerModal,
    setValue: setValueModal,
    formState: { errors: errorModal },
  } = useForm<any>();

  // Field Type List
  const fieldTypeAPI = new FieldTypeResource();

  const fieldReportAPI = new FieldReportingResource();

  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isModalOpen, setModalOpen] = useState(false);

  const { data: fieldTypeList, isLoading: isFieldTypeLoading } = useQuery(
    [`field-report-field-type`],
    () =>
      fieldReportAPI
        .fieldType(fieldReportId, {
          search: filterParams.search_field_type,
          limit: 50,
        })
        .then((res) => res?.data?.results),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  console.log(fieldTypeList)
  const fieldTypeOptions = fieldTypeList?.map((fieldType: any) => ({
    label: fieldType.title,
    value: fieldType.field_type_uuid,
  }));

  const addFieldType = useMutation((data: any) => fieldTypeAPI.store(data), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries('field-report-field-type');
      const field_type = {
        label: res?.data?.data?.title,
        value: res?.data?.data?.field_type_uuid,
      };
      setValue('field_type', field_type);
    },
    onError: (error: any) => {
      setErrorMessage(error?.response?.data?.errors);
    },
  });

  const handleFieldTypeChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      search_field_type: value,
    }));
  };

  const handleButtonClick = () => {
    setValueModal('title', '');
    setModalOpen(true);
  };

  const onModalClose = () => {
    setValueModal('title', '');
    setModalOpen(false);
  };

  const onModalSubmit = (data: any) => {
    data.field_report = fieldReportId;
    addFieldType.mutate(data);
    setModalOpen(false);
  };

  return (
    <>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.field_type} isRequired>
              <Flex justify="space-between">
                <FormLabel>{strings.field}</FormLabel>
                <Button
                  size="xs"
                  colorScheme="primary"
                  onClick={handleButtonClick}>
                  {strings.add_new_field_type}
                </Button>
              </Flex>

              <Controller
                control={control}
                name="field_type"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="field_type"
                    isLoading={isFieldTypeLoading}
                    placeholder={strings.select_field_type}
                    options={fieldTypeOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                    onInputChange={handleFieldTypeChange}
                  />
                )}
              />
            </FormControl>
          </GridItem>

          {fieldReportUnit?.map((reportUnit: any, index: number) => (
            <GridItem key={reportUnit?.id}>
              <FormControl isInvalid={!!errors?.name}>
                <FormLabel>
                  {reportUnit.measurement} ({reportUnit.unit})
                </FormLabel>
                <Input
                  {...register(`field_report_data.${index}.value`, {
                    maxLength: {
                      value: 100,
                      message: strings.too_many_characters,
                    },
                    required: true,
                  })}
                  type="number"
                  placeholder={reportUnit.measurement}
                />
                <Input
                  value={reportUnit.id}
                  {...register(`field_report_data.${index}.unit_measure_id`, {
                    maxLength: {
                      value: 100,
                      message: strings.too_many_characters,
                    },
                  })}
                  type="hidden"
                  placeholder={reportUnit.measurement}
                />
                <FormErrorMessage>
                  {errorModal?.name && errorModal?.name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          ))}

          <GridItem>
            <FormControl isInvalid={!!errors?.description} isRequired>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description', {
                  required: 'Required',
                })}
                placeholder={strings.write_here}
              />
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>

      <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.add_new_field_type}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form ref={modalForm} onSubmit={handleModalSubmit(onModalSubmit)}>
              <Stack direction="column" spacing="4">
                {addFieldType.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.field_type_creation_failed}
                  </Alert>
                )}
                <GridItem>
                  <FormControl isInvalid={!!errors?.name}>
                    <Input
                      {...registerModal(`title`, {
                        maxLength: {
                          value: 100,
                          message: strings.too_many_characters,
                        },
                        required: true,
                      })}
                      type="text"
                      placeholder={strings.field_type_name}
                    />
                    <FormErrorMessage>
                      {errors?.name && errors?.name?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <Flex>
                  <Spacer />
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={addFieldType.isLoading}>
                      {strings.add_field_type}
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnvironmentTrackerUserFieldReportForm;
