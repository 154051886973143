import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import React, { Component } from 'react';
class MapContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      center: {
        lat: +this.props.center.lat,
        lng: +this.props.center.lng,
      },
      position: {
        lat: +this.props.center.lat,
        lng: +this.props.center.lng,
      },
    };
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onInfoWindowClose = this.onInfoWindowClose.bind(this);
    this.onMapClicked = this.onMapClicked.bind(this);
    this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
    this.getMarkers = this.getMarkers.bind(this);
  }

  onMarkerClick = (props, marker) => {
    console.log('Marker', marker);
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
    this.props.handleJSA({
      jobsite: props?.jobsite,
      lat: props?.position?.lat,
      lng: props?.position?.lng,
      companyId: props?.companyId,
      is_company_user: props?.is_company_user,
      is_crew_member: props?.is_crew_member,
      is_hazard: props?.is_hazard,
      signedJSA: props?.signedJSA,
    });
  };

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = (t, map, coord) => {
    if (this.props.onMapClick === false) return;
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
    }
  };

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    this.props.setLatitude(latLng.lat());
    this.props.setLongitude(latLng.lng());
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('lat', this.props.center.lat === nextProps.center.lat);
  //   console.log('lng', this.props.center.lng === nextProps.center.lng);
  //   if (
  //     this.props.center.lat === nextProps.center.lat ||
  //     this.props.center.lng === nextProps.center.lng
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  getMarkers = () => {
    if (
      this.props.mapData === undefined ||
      this.props.mapData.length === undefined
    ) {
      return (
        <Marker
          draggable={this.props.draggable}
          onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
          name={this.props.title}
          onClick={this.onMarkerClick}
          position={this.state.position}
        />
      );
    } else {
      return this.props.mapData.map((data, index) => {
        return (
          <Marker
            key={index}
            signedJSA={data.signedJSA}
            name={data.name}
            jobsite={data.jobsite}
            isClosed={data.isClosed}
            companyId={data.companyId}
            is_company_user={data.is_company_user}
            is_crew_member={data.is_crew_member}
            is_hazard={data.is_hazard}
            icon={{
              anchor: new google.maps.Point(16, 16),
              scaledSize: new window.google.maps.Size(50, 50),
              url: data.url,
            }}
            onClick={this.onMarkerClick}
            position={{ lat: data.lat, lng: data.lng }}
          />
        );
      });
    }
  };

  centerMoved(mapProps, map, props) {
    // props.setLatitude(map.getCenter().lat());
    // props.setLatitude(map.getCenter().lng());
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.center.lat !== prevProps.center.lat ||
      this.props.center.lng !== prevProps.center.lng
    ) {
      this.setState({
        center: {
          lat: this.props.center.lat,
          lng: this.props.center.lng,
        },
        position: {
          lat: this.props.center.lat,
          lng: this.props.center.lng,
        },
      });
    }
  }

  render() {
    return (
      <>
        <Map
          style={this.props.mapStyle}
          google={this.props.google}
          onClick={this.onMapClicked}
          onDragend={(t, map, coord) => {
            if (this.props.onMapDragEnd)
              return this.centerMoved(t, map, this.props);
          }}
          zoom={this.state.center.lat > 0 ? 12 : 9}
          initialCenter={this.state.center}
          center={this.state.center}>
          {this.getMarkers()}

          <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}>
            <div>
              <h4>{this.state.selectedPlace.name}</h4>
            </div>
          </InfoWindow>
        </Map>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
})(MapContainer);
