import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import RoleResource from 'api/role';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { validEmail, validPhone } from 'utils/validate';
interface Props {
  userData?: any;
}

const UserForm: React.FC<Props> = (props) => {
  const { userData } = props;
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<any>();
  const [companyStatus,setCompanyStatus] = useState(false)

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '', // for company List
  });

  const companyAPI = new CompanyResource();
  const rolesAPI = new RoleResource();

  useEffect(() => {
    if (!userData) return;
    const company = {
      label: userData?.company_details.name,
      value: userData?.company_details.id,
    };
    setValue('company', company);
    const role = {
      label: userData?.role.name,
      value: userData?.role.id,
    };
    setValue('role', role);
    if(userData.role.name.toLowerCase() == 'superadmin') setCompanyStatus(false) 
    else setCompanyStatus(true)
  }, [userData, setValue]);

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [
      `company-list`,
      { search: filterParams.search, is_active: true, is_approved: true },
    ],
    () =>
      companyAPI
        .allList({ search: filterParams.search, limit: 50 })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const rolesQuery = useQuery(
    'user-roles-for-user-edit',
    () => rolesAPI.list().then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const roleList = rolesQuery?.data?.results;
  const roleOptions = roleList
    ?.filter((role: any) => {
      if (
        loggedInUser?.user_information?.user_role.toLowerCase() !== 'superadmin'
      )
        return role.name.toLowerCase() !== 'superadmin';
      return role.name.toLowerCase() !== '';
    })
    .map((role: any) => ({
      label: role.name,
      value: role.id,
    }));

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      search: value,
    }));
  };
  
    const handlecompanyStatus = (event:any) => {
      const role = event?.label
      if(role.toLowerCase() === 'superadmin') setCompanyStatus(false)
      else setCompanyStatus(true)
    };
  

  if (!userData && rolesQuery.isLoading) return <CenterSpinner />;

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.first_name} isRequired>
              <FormLabel>{strings.first_name}</FormLabel>
              <Input
                {...register('first_name', {
                  required: strings.first_name,
                })}
                type="text"
                defaultValue={userData?.first_name}
                placeholder={strings.first_name}
              />
              <FormErrorMessage>
                {errors?.first_name && errors?.first_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.middle_name}>
              <FormLabel>{strings.middle_name}</FormLabel>
              <Input
                {...register('middle_name')}
                type="text"
                defaultValue={userData?.middle_name}
                placeholder={strings.middle_name}
              />
              <FormErrorMessage>
                {errors?.middle_name && errors?.middle_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.last_name} isRequired>
              <FormLabel>{strings.last_name}</FormLabel>
              <Input
                type="text"
                defaultValue={userData?.last_name}
                placeholder={strings.last_name}
                {...register('last_name', { required: strings.last_name })}
              />
              <FormErrorMessage>
                {errors?.last_name && errors?.last_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.email}>
              <FormLabel>{strings.personal_email}</FormLabel>
              <Input
                defaultValue={userData?.email}
                placeholder={strings.personal_email}
                {...register('email', {
                  validate: (value) => {
                    if (value)
                      return validEmail(value) || strings.valid_email_msg;
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.username}>
              <FormLabel>{strings.username}</FormLabel>
              <Input
                defaultValue={userData?.username}
                placeholder={strings.username}
                {...register('username')}
              />
              <FormErrorMessage>
                {errors?.username && errors?.username?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.work_email} isRequired>
              <FormLabel>{strings.work_email}</FormLabel>
              {!userData?.work_email && (
                <Input
                  defaultValue={userData?.work_email}
                  placeholder={strings.work_email}
                  {...register('work_email', {
                    required: strings.work_email,
                    validate: (value) =>
                      validEmail(value) || strings.valid_email_msg,
                  })}
                />
              )}
              {userData?.work_email && (
                <Input
                  defaultValue={userData?.work_email}
                  disabled={true}
                  placeholder={strings.work_email}
                />
              )}
              <FormErrorMessage>
                {errors?.work_email && errors?.work_email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.phone} isRequired>
              <FormLabel>{strings.phone}</FormLabel>
              {!userData?.phone && (
                <Input
                  type="number"
                  {...register('phone', {
                    required: strings.phone,
                    validate: (value) =>
                      validPhone(value) || strings.valid_contact_number_msg,
                  })}
                  defaultValue={userData?.phone}
                  placeholder={strings.phone}
                />
              )}
              {userData?.phone && (
                <Input
                  defaultValue={userData?.phone}
                  disabled={true}
                  placeholder={strings.phone}
                />
              )}

              <FormErrorMessage>
                {errors?.phone && errors?.phone?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.role} isRequired>
              <FormLabel>{strings.role}</FormLabel>

              <Controller
                control={control}
                name="role"
                rules={{
                  required: strings.select_role,
                }}
                render={({ field }) => (
                  <ReactSelect
                 
                    {...field}
                    id="role"
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      handlecompanyStatus(selectedOption);
                    }}
                    value={field.value ? field.value : null}
                    placeholder={strings.select_role}
                    options={roleOptions}
                    styles={reactSelectStyles}
                   
                  />
                )}
              />



              {/* <Select
                placeholder={userData?.role ? '' : 'Select Role'}
                id="role"
                rounded="sm"
                {...register('role', { required: 'Required' })}
                defaultValue={userData?.role?.id ?? undefined}>
                {rolesData?.map((role: RoleSchema) => (
                  <option key={role.id} value={role.id}>
                    {capitalizeFirstLetter(role.name)}
                  </option>
                ))}
              </Select> */}
              <FormErrorMessage>
                {errors?.role && errors?.role?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        { companyStatus == true && (
          <GridItem>
            <FormControl isInvalid={!!errors?.company} isRequired>
              <FormLabel>{strings.company}</FormLabel>
              <Controller
                control={control}
                name="company"
                rules={{
                  required: strings.select_company,
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="company"
                    value={field.value ? field.value : null}
                    placeholder={strings.select_company}
                    isLoading={isCompanyLoading}
                    options={companyOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                    onInputChange={handleCompanyChange}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.company && errors?.company?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        )}  
        </Grid>
      </Stack>
      {/* <Box>
        <Button
          variant="link"
          onClick={onToggle}
          size="sm"
          pt="2"
          leftIcon={
            <Icon
              as={isOpen ? BiChevronUp : BiChevronDown}
              w="6"
              h="6"
              bg="primary.400"
              color="white"
              rounded="full"
            />
          }>
          Manage Extra Rights
        </Button>

        <Collapse in={isOpen} animateOpacity>
          <UserRightsManager permissionGroupQuery={permissionGroupQuery} />
        </Collapse>
      </Box> */}
    </form>
  );
};

export default UserForm;
