import {
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiShow } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { getStartingSerialNumber, truncateString } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

interface Props {
  query: any;
}

const HazardList: React.FC<Props> = (props) => {
  const { query } = props;
  const [isLoading, setIsLoading] = useState<string | null>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const companyAPI = new CompanyResource();

  const [totalJSACompanyList, setTotalJSACompanyList] = useState<
    number | undefined
  >(undefined);

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalJSACompanyList,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: jsaCompanyListData, isLoading: isJSACompanyListLoading } =
    useQuery(
      [
        'jsaCompanyListData',
        {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
        },
        query,
      ],
      async () => {
        const queryParams: any = {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
        };
        const queryList = { ...queryParams, ...query };
        const response = await companyAPI.companyJobSiteHazard(queryList);
        setTotalJSACompanyList(response?.data?.data?.meta?.total);
        response && setIsLoading(null);
        return response?.data?.data;
      }
    );

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.is_resolved}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isJSACompanyListLoading &&
                  jsaCompanyListData?.results?.map(
                    (res: any, index: number) => {
                      return (
                        <Tr key={res?.id}>
                          <Td>{startingSN + index}</Td>
                          <Td>{res?.hazard_type?.name || '-'}</Td>
                          <Td>
                            {truncateString(res?.additional_notes) || '-'}
                          </Td>
                          <Td>{res?.is_resolved ? strings.yes : strings.no}</Td>
                          <Td>
                            <Link
                              isExternal
                              href={routes.hazards.hazard.view.replace(
                                ':id',
                                res.id.toString()
                              )}>
                              <Tooltip hasArrow label={strings.view_hazard}>
                                <IconButton
                                  icon={<BiShow />}
                                  variant="link"
                                  aria-label={strings.view_hazard}
                                  color="green.300"
                                  size="lg"
                                />
                              </Tooltip>
                            </Link>
                          </Td>
                        </Tr>
                      );
                    }
                  )}
                {isJSACompanyListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isJSACompanyListLoading}
              totalCount={totalJSACompanyList}
              resultCount={jsaCompanyListData?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {jsaCompanyListData?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isJSACompanyListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default HazardList;
