import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import ChatRegister from 'components/chat/ChatRegister';
import Sidebar from 'components/layout/Sidebar';
import React from 'react';
import PrivateRouter from 'router/PrivateRouter';

const Dashboard: React.FC = () => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1140px)');
  return (
    <Flex>
      <ChatRegister />
      <Sidebar />
      <Box maxHeight="100vh" overflowY="scroll" flex="1">
        <Box p={isLargerThan1280 ? 8 : 4}>
          <PrivateRouter />
        </Box>
      </Box>
    </Flex>
  );
};

export default Dashboard;
