import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import HazardResource from 'api/hazard';
import HazardForm from 'components/hazard/HazardForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { GoogleGeoCode } from 'services/GoogleGeoCode';
import { buildFormData } from 'utils';

const CreateHazard: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<any>();
  const hazardAPI = new HazardResource();
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [position, setPosition] = useState({ lat: 0, lng: 0 });

  const [errorMessage, setErrorMessage] = useState<any>();

  const addHazard = useMutation((data: any) => hazardAPI.create(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.hazard_success_msg,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('hazardList');
      history.push(routes.hazards.hazard.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey][0]);
      else setErrorMessage(strings.hazard_creation_failed);
    },
  });

  
  const onSubmit = async (data: any) => {
    try{
      const address = await GoogleGeoCode(data?.latitude, data?.longitude);
      const jsonData = {
        hazard_type: data?.hazard_type?.value,
        // is_ammonium_present: data?.is_ammonium_present,
        is_hazard: data?.is_hazard,
        reporter_name: data?.reporter_name,
        reporter_phone: data?.reporter_phone,
        additional_notes: data?.additional_notes,
        location: JSON.stringify({
          lat: data?.latitude,
          lng: data?.longitude,
          city: address?.city || '',
          state: address?.state || '',
          address: address?.address || '',
          zip: '',
        }),
        image: data?.image,
      };
      const formData = buildFormData(jsonData);
      addHazard.mutate(formData);
    }
    catch(err: any){
      toast({
        title: "Google maps is not ready yet, reload page!",
        status: 'error',
        isClosable: true,
      });
    }
  };
  const fetchUserLocation = () =>{
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setCenter({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
        setPosition({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
      },
      (err) => {
        toast({
          title: strings.enable_your_location,
          status: 'info',
          isClosable: true,
        });
      }
    );
  }

  useEffect(() => {
    fetchUserLocation();
  }, []);
  
  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.hazards.hazard.list}>
            {strings.hazard}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.hazards.hazard.create}>
            {strings.add_new_hazard}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.add_new_hazard}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addHazard.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <HazardForm center={center} position={position} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addHazard.isLoading}>
                  {strings.add_hazard}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateHazard;
