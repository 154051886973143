import {
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import MapFeatureResource from 'api/map_feature';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiShow } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { DateFormatMDY, getStartingSerialNumber, truncateString } from 'utils';
import { getFullName } from 'utils/esg';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

interface Props {
  query: any;
}

const JSAList: React.FC<Props> = (props) => {
  const { query } = props;
  const [isLoading, setIsLoading] = useState<string | null>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const mapFeatureAPI = new MapFeatureResource();

  const [totalJSAList, setTotalJSAList] = useState<number | undefined>(
    undefined
  );

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalJSAList,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: jsaListData, isLoading: isJSAListLoading } = useQuery(
    [
      'jsaListData',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
      query,
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const queryList = { ...queryParams, ...query };
      const response = await mapFeatureAPI.list(queryList);
      setTotalJSAList(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data;
    }
  );

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Company</Th>
                  <Th>{strings.completed_by}</Th>
                  <Th>Location</Th>
                  <Th>Work Type</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.date}</Th>
                  {/* <Th>{strings.description}</Th> */}
                  
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isJSAListLoading &&
                  jsaListData?.results?.map((res: any, index: number) => {
                    return (
                      <Tr key={res?.id}>
                        <Td>{startingSN + index}</Td>
                        <Td>{res?.company_name_user}</Td>
                        <Td>
                          {getFullName(res?.jobsite?.submitted_by) || '-'}
                        </Td>
                        <Td>
                          <Link
                            isExternal
                            href={routes.reports.jsa.view.replace(
                              ':id',
                              res.id.toString()
                            )}>
                            {res?.jobsite?.name}
                          </Link>
                        </Td>
                        <Td>{res?.work_type_name}</Td>
                        <Td>{res?.customer_name || '-'}</Td>
                        <Td>
                          {/* {DateFormatMDY(res?.jobsite?.submitted_on) || '-'} */}
                          {DateFormatMDY(res?.created_at) || '-'}
                        </Td>
                        {/* <Td>{truncateString(res?.jobsite?.notes) || '-'}</Td> */}
                        <Td>
                          <Link
                            isExternal
                            href={routes.reports.jsa.view.replace(
                              ':id',
                              res?.id.toString()
                            )}>
                            <Tooltip hasArrow label={strings.view_jsa_report}>
                              <IconButton
                                icon={<BiShow />}
                                variant="link"
                                aria-label={strings.view_jsa_report}
                                color="green.300"
                                size="lg"
                              />
                            </Tooltip>
                          </Link>
                        </Td>
                      </Tr>
                    );
                  })}
                {isJSAListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isJSAListLoading}
              totalCount={totalJSAList}
              resultCount={jsaListData?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {jsaListData?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isJSAListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default JSAList;
