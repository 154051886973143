import axios from 'axios';
import http from 'utils/http';

export function login(data: any) {
  return http({
    url: '/login/',
    method: 'post',
    data: data,
    headers: {
      Devicetype: 'web',
    },
  });
}

export function getAuthPermissions(headers: any) {
  return http({
    url: '/get-my-permissions/?limit=10000',
    method: 'get',
    headers,
  });
}

export function getAuthUser(headers: any) {
  return http({
    url: '/user-profile/',
    method: 'get',
    headers,
  });
}

export function updateAuthUser(data: any, headers: any) {
  return http({
    url: '/user-profile/',
    method: 'put',
    data: data,
    headers,
  });
}

export function logout(refreshToken: string) {
  return http({
    url: '/auth/logout',
    method: 'post',
    data: { refresh_token: refreshToken },
  });
}

const CancelToken = axios.CancelToken;
let cancel: any;

export function refreshUser(refreshToken: string) {
  if (cancel) {
    cancel(); // cancel request
  }

  return http({
    url: '/auth/token',
    method: 'post',
    data: { refresh_token: refreshToken },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}

export function forgotPassword(data: any) {
  return http({
    url: '/send-verification-code/',
    method: 'post',
    data: data,
  });
}

export function changePassword(data: any, headers: any) {
  return http({
    url: '/password/change/',
    method: 'post',
    data: data,
    headers,
  });
}

export function resetPassword(data: any) {
  return http({
    url: '/password/reset/confirm/',
    method: 'post',
    data: data,
  });
}

export function setNewPassword(data: any) {
  return http({
    url: '/set-password',
    method: 'post',
    data: data,
  });
}

export function verifyEmail(data: any) {
  return http({
    url: '/verify-email',
    method: 'post',
    data: data,
  });
}

export function resendVerificationEmail(data: any) {
  return http({
    url: '/resend-verify-email',
    method: 'post',
    data: data,
  });
}

export function setFCMToken(data: any, deviceId: any) {
  return http({
    url: '/set-fcm-token/',
    method: 'post',
    data: data,
    headers: {
      devicetype: 'web',
      'device-id': deviceId,
    },
  });
}
