import Resource from 'api/resource';
import http from 'utils/http';
class HazardResource extends Resource {
  constructor() {
    super('hazard/');
  }

  assignToCompany(hazardId: any, data: any) {
    return http({
      url: `/hazard-assign/${hazardId}/`,
      method: 'put',
      data: data,
    });
  }

  create(resource: any, headers?: any) {
    return http({
      url: '/hazard-report/',
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  update(hazardId: any, resource?: any) {
    return http({
      url: `hazard/${hazardId}/`,
      method: 'put',
      data: resource,
      headers: {
        'Device-Type': 'web',
      },
    });
  }
}

export { HazardResource as default };
