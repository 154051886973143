import { Center, Image, Stack, Text } from '@chakra-ui/react';
import { AppStore, PlayStore } from 'assets/icons';
import { Logo } from 'assets/images';
import React from 'react';

const DownloadPage: React.FC = () => {
  return (
    <Center h="100vh">
      <Stack direction="column" spacing="12">
        <Stack spacing="4">
          <Center>
            <Image w="16" src={Logo} />
          </Center>
          <Stack>
            <Text
              align="center"
              fontSize="lg"
              fontWeight="medium"
              color={'green.600'}>
              ESG ONSITE
            </Text>
            <Text align="center" fontSize="3xl" fontWeight="bold">
              Download
            </Text>
            <Text align="center" fontSize="lg" fontWeight="bold">
              our new app
            </Text>
            <Text
              align="center"
              fontSize="lg"
              fontWeight="medium"
              padding={'20px'}>
              ESG Onsite is a system for maintaining safety at Oil & Gas job
              sites. ESG lets you quickly complete JSA forms, review on-site
              hazards, and notify the whole site of emergencies.
            </Text>
          </Stack>
          <Stack
            direction="row"
            spacing="12"
            display="flex"
            alignItems="center"
            justifyContent="space-around">
            <Image
              color="gray.400"
              w="20%"
              src={AppStore}
              alt="Image"
              title="Image"
              className="logo-icon"
            />
            <Image
              color="gray.400"
              w="20%"
              src={PlayStore}
              alt="Image"
              title="Image"
              className="logo-icon"
            />
          </Stack>
        </Stack>
      </Stack>
    </Center>
  );
};

export default DownloadPage;
