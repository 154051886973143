import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import UserResource from 'api/user';
import {
  activeStyles,
  normalStyles,
  reactSelectStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import UserListItem from 'components/user/UserListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  USER_HEADER,
  USER_LIST,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import ReactSelect from 'react-select';
import AccessControl from 'services/AccessControl';
import { getStartingSerialNumber } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  company: string;
  search: string;
  searchCompany: string;
}

const UserList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const userAPI = new UserResource();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    company: '',
    search: '',
    searchCompany: '',
  });
  const companyAPI = new CompanyResource();
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [`company-list`, { search: filterParams.searchCompany }],
    () =>
      companyAPI
        .allList({ search: filterParams.searchCompany, limit: 50 })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const [totalUsers, setTotalUsers] = useState<number | undefined>(undefined);

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalUsers,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: userList, isLoading: isUserListLoading } = useQuery(
    [
      'userList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        company: filterParams.company,
        search: filterParams.search,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        company: filterParams.company,
        search: filterParams.search,
      };
      const response = await userAPI.list(queryParams);
      setTotalUsers(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      company: data.company ? data?.company?.value : '',
      search: data.search,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = async () => {
    methods.reset({
      company: '',
      search: '',
    });
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      company: '',
      search: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchCompany: value,
    }));
  };

  const handleCSVDownload = async () => {
    const companyList = await companyAPI
      .itemList({
        search: filterParams.searchCompany,
        limit: companyQuery.count,
      })
      .then((res) => res?.data?.data);

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('User');

    let companySheet = workbook.addWorksheet('Data');

    companyList?.results?.map((company: any, index: any) => {
      let count = index + 1;
      companySheet.getCell('A' + count).value = company.name;
    });

    worksheet.addRow(USER_HEADER);
    worksheet.addRow(USER_LIST)
    const dataValidationTo = companyQuery.count - 1;
    for (let i = 2; i < dataValidationTo; i++) {
      worksheet.getCell('I' + i).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: ['"Y,N"'],
      };
      worksheet.getCell('H' + i).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: ['=Data!$A$1:$A$10000'],
      };
    }

    workbook.csv.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel',
      });
      FileSaver.saveAs(blob, 'bulk-upload-template.csv');
    });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.user}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.all_users}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.user_management}</Heading>
          <Stack direction="row">
            <AccessControl
              allowedPermissions={[PermissionRequest['manage:user']]}>
              <Button
                size="sm"
                colorScheme="primary"
                type="button"
                onClick={() => history.push(routes.users.create)}>
                {strings.add_new_user}
              </Button>
            </AccessControl>
            <AccessControl
              allowedPermissions={[PermissionRequest['approve:user']]}>
              <CsvUpload
                url="upload-csv-users/"
                setRefetch={setRefetch}
                headerSchema={USER_HEADER}
              />
              <Button
                size="sm"
                colorScheme="primary"
                type="button"
                onClick={handleCSVDownload}>
                {strings.bulk_upload_template}
              </Button>
            </AccessControl>
          </Stack>
        </Flex>
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <Input
                  type="text"
                  placeholder={strings.search}
                  {...methods.register('search')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.company}</FormLabel>
                <Controller
                  control={methods.control}
                  name="company"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="company"
                      placeholder={strings.select_company}
                      isLoading={isCompanyLoading}
                      options={companyOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onInputChange={handleCompanyChange}
                    />
                  )}
                />
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.email}</Th>
                  <Th>{strings.company}</Th>
                  <Th>{strings.role}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isUserListLoading &&
                  userList?.results?.map(
                    (userData: UserSchema, index: number) => {
                      if (loggedInUser.user_id !== userData.id)
                        return (
                          <UserListItem
                            userData={userData}
                            key={userData.id}
                            index={startingSN + index}
                          />
                        );
                    }
                  )}
                {isUserListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isUserListLoading}
              totalCount={totalUsers}
              resultCount={userList?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {userList?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isUserListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default UserList;
