import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import PPEResource from 'api/ppe';
import CustomTagTitle from 'components/common/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { PPESchema } from 'constants/schema';
import React, { useState } from 'react';
import {
  BiEdit,
  BiListMinus,
  BiListPlus,
  BiShow,
  BiTrash,
} from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { truncateString } from 'utils';

interface Props {
  ppeData: PPESchema;
  is_superadmin: boolean;
  index: number;
}
const PPEListItem: React.FC<Props> = (props) => {
  const { ppeData, index, is_superadmin } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const ppeAPI = new PPEResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);

  const deletePPE = useMutation(() => ppeAPI.destroy(ppeData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('ppeList');
      setDeleteModalOpen(false);
      toast({
        title: strings.ppe_delete_success_msg,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deletePPE.mutate();
  };

  const changeStatusPPE = useMutation(
    () =>
      ppeAPI.update(ppeData.id, {
        is_active: !ppeData.is_active,
        name: ppeData.name,
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('ppeList');

        setStatusModalOpen(false);
        toast({
          title: ppeData.is_active
            ? strings.ppe_deactivated
            : strings.ppe_activated,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const onStatusPress = () => {
    setStatusModalOpen(true);
  };

  const onStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const onStatusConfirm = () => {
    changeStatusPPE.mutate();
  };

  return (
    <Tr key={ppeData.id}>
      <Td>{index}</Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={ppeData?.is_active}
          name={ppeData?.name || '-'}
        />
      </Td>
      <Td>{ppeData.is_mandatory ? strings.yes : strings.no}</Td>
      {is_superadmin? 
      <Td>{truncateString(ppeData?.company_details?.name) || '-'}</Td>
      : ""
      }
      <Td>
        <RouterLink to={routes.ppe.view.replace(':id', ppeData.id.toString())}>
          <Tooltip hasArrow label={strings.view_ppe}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_ppe}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        {is_superadmin? 
        <>
        <RouterLink to={routes.ppe.edit.replace(':id', ppeData.id.toString())}>
          <Tooltip hasArrow label={strings.edit_ppe}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_ppe}
              color="blue.300"
              size="lg"
              />
          </Tooltip>
        </RouterLink>
        <AccessControl
        allowedPermissions={[
          PermissionRequest['read:ppe'],
          PermissionRequest['manage:ppe'],
        ]}
        renderNoAccess={(data: any) => data}>
          <>
            <Tooltip
              hasArrow
              label={
                ppeData.is_mandatory
                ? strings.this_is_mandatory_ppe
                : ppeData.is_active
                ? strings.hide_ppe
                : strings.show_ppe
              }>
              <IconButton
                icon={ppeData.is_mandatory ? <BiListMinus /> : <BiListPlus />}
                variant="link"
                aria-label={
                  ppeData.is_active ? strings.hide_ppe : strings.show_ppe
                }
                color="red.300"
                size={'lg'}
                onClick={onStatusPress}
                disabled={ppeData.is_mandatory ? true : false}
                />
            </Tooltip>
            <Tooltip hasArrow label={strings.delete_ppe}>
              <IconButton
                icon={<BiTrash />}
                variant="link"
                aria-label={strings.delete_ppe}
                color="red.300"
                size="lg"
                onClick={onDeletePress}
                />
            </Tooltip>
          </>
        </AccessControl>
        </>
        :''
        }
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_ppe}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.confirm_delete}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deletePPE.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isStatusModalOpen}
          isCentered
          onClose={onStatusModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {ppeData.is_active ? strings.hide_ppe : strings.show_ppe}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {ppeData.is_active
                ? strings.confirm_ppe_hide_msg
                : strings.confirm_ppe_show_msg}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onStatusConfirm}
                  isLoading={changeStatusPPE.isLoading}>
                  {strings.submit}
                </Button>

                <Button variant="outline" onClick={onStatusModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default PPEListItem;
