import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FieldTypeResource from 'api/field_type';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

interface Props {
  fieldTypeData: any;
  index: number;
}
const FieldTypeListItem: React.FC<Props> = (props) => {
  const { fieldTypeData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const fieldTypeAPI = new FieldTypeResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteFieldType = useMutation(
    () => fieldTypeAPI.destroy(fieldTypeData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('fieldTypeList');

        setDeleteModalOpen(false);
        toast({
          title: strings.field_type_delete_success_msg,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteFieldType.mutate();
  };

  return (
    <Tr key={fieldTypeData.id}>
      <Td>{index}</Td>
      <Td>{fieldTypeData?.title || '-'}</Td>
      <Td>{fieldTypeData?.field_report_detail?.name || '-'}</Td>
      <Td>
        <RouterLink
          to={routes.fieldReporting.fieldType.view.replace(
            ':id',
            fieldTypeData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_field_type}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_field_type}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.fieldReporting.fieldType.edit.replace(
            ':id',
            fieldTypeData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit_field_type}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_field_type}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <AccessControl
          allowedPermissions={[
            PermissionRequest['read:field-type'],
            PermissionRequest['manage:field-type'],
          ]}
          renderNoAccess={(data: any) => data}>
          <Tooltip hasArrow label={strings.delete_field_type}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_field_type}
              color="red.300"
              size="lg"
              onClick={onDeletePress}
            />
          </Tooltip>
        </AccessControl>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_field_type}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteFieldType.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default FieldTypeListItem;
