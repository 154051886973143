import {
  Avatar,
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tr,
  useToast,
} from '@chakra-ui/react';
import DeactiveUserResources from 'api/deactivate_request';
import CustomTagTitle from 'components/common/CustomTagTitle';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';
import { getFullName } from 'utils/esg';

interface Props {
  userData: any;
  index: number;
}
const UserRequestDeactivelist: React.FC<Props> = (props) => {
  const { userData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const userDeactivateRequestAPI = new DeactiveUserResources();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const changeStatusUser = useMutation(
    () =>
      userDeactivateRequestAPI.destroy({
        user_id: userData.id,
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('userList');

        setStatusModalOpen(false);
        toast({
          title: userData.is_active
            ? strings.user_deactivated
            : strings.user_activated,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onStatusPress = () => {
    setStatusModalOpen(true);
  };

  const onStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const onStatusConfirm = () => {
    changeStatusUser.mutate();
  };

  return (
    <Tr key={userData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.users.profile.replace(':id', userData.id.toString())}>
          <HStack>
            <Avatar size="sm" rounded="full" src={userData.profile_image} />
            <CustomTagTitle
              type="text"
              status={userData?.is_active}
              name={getFullName(userData) || '-'}
            />
          </HStack>
        </RouterLink>
      </Td>
      <Td>{userData?.work_email || '-'}</Td>
      <Td>{truncateString(userData?.company_details?.name) || '-'}</Td>
      {userData?.role?.name || '-'}
      <Td></Td>
      <Td>
        <Modal
          isOpen={isStatusModalOpen}
          isCentered
          onClose={onStatusModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {userData.is_active ? strings.hide_user : strings.show_user}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {userData.is_active
                ? strings.confirm_hide_user
                : strings.confirm_show_user}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onStatusConfirm}
                  isLoading={changeStatusUser.isLoading}>
                  {strings.submit}
                </Button>

                <Button variant="outline" onClick={onStatusModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default UserRequestDeactivelist;
