import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FieldReportingResource from 'api/field_reporting';
import UserFieldReportResource from 'api/user_field_report';
import { CenterSpinner } from 'components/common/CenterSpinner';
import EnvironmentTrackerUserFieldReportForm from 'components/userFieldReport/EnvironmentTrackerUserFieldReportForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { FieldReportingSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const CreateUserFieldReport: React.FC = () => {
  const { id: fieldReportId } = useParams<{ id: string }>();

  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;

  const history = useHistory();
  const methods = useForm<any>();
  const userFieldReportAPI = new UserFieldReportResource();
  const fieldReportingAPI = new FieldReportingResource();
  const [errorMessage, setErrorMessage] = useState<any>();

  const addUserFieldReport = useMutation(
    (data: any) => userFieldReportAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.user_field_report_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userFieldReportList');
        history.goBack();
      },
      onError: (error: any) => {
        setErrorMessage(error?.response?.data?.errors);
      },
    }
  );

  const fieldReportingQuery = useQuery<FieldReportingSchema>(
    [`fieldReportingQuery${fieldReportId}`, fieldReportId],
    () =>
      fieldReportingAPI.get(fieldReportId).then((res) => {
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  if (fieldReportingQuery.isLoading) {
    return <CenterSpinner />;
  }
  console.log(fieldReportingQuery.data)
  if (fieldReportingQuery.isError) {
    history.push(routes.fieldReporting.environmentTracker.list);
  }

  const onSubmit = (data: any) => {
    console.log(data)
    let formData = {
      company: fieldReportingQuery?.data?.company,
      field_type_uuid: data?.field_type?.value,
      description: data?.description,
      field_report_data: data?.field_report_data,
      field_report_uuid: fieldReportingQuery?.data?.field_report_uuid,
    };
    addUserFieldReport.mutate(formData);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.fieldReporting.userFieldReport.list}>
              {strings.user_field_report_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.fieldReporting.userFieldReport.create}>
              {strings.add_new_user_field_report}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md">{strings.add_user_field_report}</Heading>
        </Flex>

        <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {addUserFieldReport.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.user_field_report_creation_failed}
                  </Alert>
                )}
                <EnvironmentTrackerUserFieldReportForm
                  fieldReportUnit={fieldReportingQuery?.data?.field_report_unit}
                  fieldReportId={Number(fieldReportId)}
                />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={addUserFieldReport.isLoading}>
                    {strings.add_user_field_report}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </>
  );
};

export default CreateUserFieldReport;
