import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import WorkTypeResource from 'api/work_type';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { validURL } from 'utils/validate';
interface Props {
  sopsData?: any;
}

interface FilterParams {
  companySearch: string;
  workTypeSearch: string;
}

const SOPSForm: React.FC<Props> = (props) => {
  const { sopsData } = props;
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    companySearch: '',
    workTypeSearch: '',
  });

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      companySearch: value,
    }));
  };

  const companyAPI = new CompanyResource();

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [`company-list-item`, { search: filterParams.companySearch }],
    () =>
      companyAPI
        .allList({ search: filterParams.companySearch, limit: 50 })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const workType = new WorkTypeResource();

  const { data: workTypeQuery, isLoading: isWorkTypeLoading } = useQuery(
    [`workType-list-item`, { search: filterParams.workTypeSearch }],
    () =>
      workType
        .itemList({ search: filterParams.workTypeSearch, limit: 50 })
        .then((res) => res?.data?.work_type),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const workTypeList = workTypeQuery?.results;
  const workTypeOptions = workTypeList?.map((workType: any) => ({
    label: workType.name,
    value: workType.id,
  }));

  const handleWorkTypeChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      workTypeSearch: value,
    }));
  };

  useEffect(() => {
    if (!sopsData) return;
    const company: any = {
      label: sopsData?.company?.name,
      value: sopsData?.company?.id,
    };
    setValue('company_id', company);
    const work_type: any = {
      label: sopsData?.work_type?.name,
      value: sopsData?.work_type?.id,
    };
    setValue('work_type_id', work_type);
  }, [sopsData, setValue]);

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.work_type} isRequired>
              <FormLabel>{strings.work_type}</FormLabel>
              <Controller
                control={control}
                name="work_type_id"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="work_type_id"
                    isLoading={isWorkTypeLoading}
                    placeholder={strings.select_work_type}
                    options={workTypeOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                    onInputChange={handleWorkTypeChange}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.work_type && errors?.work_type?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.company} isRequired>
              <FormLabel>{strings.company}</FormLabel>
              <Controller
                control={control}
                name="company_id"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="company_id"
                    isLoading={isCompanyLoading}
                    placeholder={strings.select_company}
                    options={companyOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                    onInputChange={handleCompanyChange}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.company && errors?.company?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.sop_link} isRequired>
              <FormLabel>{strings.sop_link}</FormLabel>
              <Input
                {...register('sop_link', {
                  required: strings.sop_link,
                  validate: (value) => {
                    if (value)
                      return validURL(value) || strings.valid_url_address_msg;
                  },
                })}
                type="text"
                defaultValue={sopsData?.sop_link}
                placeholder="SOP Link"
              />
              <FormErrorMessage>
                {errors?.sop_link && errors?.sop_link?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default SOPSForm;
