import Resource from 'api/resource';
import http from 'utils/http';
class PermissionResources extends Resource {
  constructor() {
    super('roles-and-permissions/');
  }

  setPermissions(data?: any) {
    return http({
      url: 'role-permission/',
      method: 'put',
      data: data,
    });
  }
}

export { PermissionResources as default };
