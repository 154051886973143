import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Spinner,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  permissionGroupQuery: any; // used to check mark for checkbox
  permissionList: any;
}

const RolePermissionForm: React.FC<Props> = (props) => {
  const { permissionGroupQuery, permissionList } = props;
  const { register } = useFormContext();

  if (permissionGroupQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      border="1px"
      borderColor="gray.100"
      rounded="sm"
      mt="4"
      padding={'20px'}>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl>
            <FormLabel>{strings.role}</FormLabel>
            <Input
              defaultValue={permissionGroupQuery?.data?.name}
              disabled={true}
            />
          </FormControl>
        </GridItem>
      </Grid>
      <Grid
        marginTop={'20px'}
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl>
            <FormLabel>{strings.permission}</FormLabel>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
        flex="1">
        {permissionList?.map((permission: any) => {
          const checkStatus = permissionGroupQuery?.data?.permission.some(
            (p: any) => {
              return permission.value === p;
            }
          );
          return (
            <GridItem key={permission?.value}>
              {checkStatus ? (
                <>
                  <input
                    type="checkbox"
                    id={permission?.value}
                    defaultChecked={true}
                    {...register(`permissions.${permission?.value}`)}
                  />
                </>
              ) : (
                <>
                  <input
                    type="checkbox"
                    id={permission?.value}
                    {...register(`permissions.${permission?.value}`)}
                  />
                </>
              )}
              <label
                htmlFor={permission?.value}
                style={{
                  textTransform: 'uppercase',
                  fontSize: '14px',
                  marginLeft: '0.5rem',
                }}>
                {permission?.name}
              </label>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default RolePermissionForm;
