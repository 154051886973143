import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import JSAResource from 'api/location';
import { MapLogo } from 'assets/images';
import { CenterSpinner } from 'components/common/CenterSpinner';
import JSACrewMembers from 'components/reports/JSACrewMembers';
import JSAEndSummary from 'components/reports/JSAEndSummary';
import JSAEquipment from 'components/reports/JSAEquipment';
import JSAPreJobHazard from 'components/reports/JSAPreJobHazard';
import JSATimeLine from 'components/reports/JSATimeLine';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { DateFormatMDY, TimeFormat, truncateString } from 'utils';
import history from 'utils/history';

const JSAViewReport: React.FC = () => {
  const { id }: any = useParams();
  const jsaApi = new JSAResource();
  const printRef: any = useRef();

  const jsaQuery = useQuery<any>(
    [`jsa${id}`, id],
    () => jsaApi.jsaCompletedListReportById(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const pageStyle = `
    @page {
      margin: 2cm;
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
    documentTitle: `${'JSA Report' + ' ' + jsaQuery?.data?.job_title || ''}`,

  });

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  if (jsaQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (jsaQuery.isError) {
    history.push(routes.reports.jsa.list);
  }
  console.log(jsaQuery.data)

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.reports.jsa.list}>
              {strings.jsa}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {truncateString(jsaQuery?.data?.job_title || '')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.jsa_details}
          </Heading>
          <Stack direction="row" spacing="6">
            <Button size="sm" colorScheme="primary" onClick={handlePrint}>
              {strings.print}
            </Button>
          </Stack>
        </Flex>

        <Box>
          <Heading size="md" textTransform="capitalize">
            {strings.jsa_info}
          </Heading>
          <Stack
            bg="white"
            p={['3', '6']}
            shadow="box"
            rounded="sm"
            marginTop={3}
            marginBottom={3}>
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.location_name}
                  </FormLabel>
                  <Box sx={infoStyles}>{jsaQuery?.data?.job_title || '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.operator_name}
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {jsaQuery?.data?.completed_by || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.work_type}</FormLabel>
                  <Box sx={infoStyles}>{jsaQuery?.data?.work_type_name || '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.location_coordinates}
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {jsaQuery?.data?.location?.lat},{' '}
                    {jsaQuery?.data?.location?.lng}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.date_start}</FormLabel>
                  <Box sx={infoStyles}>
                    {DateFormatMDY(jsaQuery?.data?.created_at) || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.date_end}</FormLabel>
                  <Box sx={infoStyles}>
                    {DateFormatMDY(
                      jsaQuery?.data?.jsa_endwork_summary?.updated_at
                    ) || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.time_start}</FormLabel>
                  <Box sx={infoStyles}>
                    {TimeFormat(jsaQuery?.data?.created_at)}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.time_end}</FormLabel>
                  <Box sx={infoStyles}>
                    {TimeFormat(
                      jsaQuery?.data?.jsa_endwork_summary?.updated_at
                    )}
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>

          <JSACrewMembers crewMembersData={jsaQuery?.data?.crews} />
          <JSAEquipment ppeData={jsaQuery?.data?.ppe} />
          <JSAPreJobHazard
            hazardData={jsaQuery?.data?.pre_hazards}
            stepData={jsaQuery?.data?.steps}
          />

          <JSATimeLine timeLineData={jsaQuery?.data?.time_line} />
          {jsaQuery?.data?.is_closed && (
            <JSAEndSummary endSummaryData={jsaQuery?.data?.jsa_endwork_summary} />
          )} 
          
          
        </Box>

        {/* To print using react-to-print package */}
        <div style={{ display: 'none' }}>
          <Box ref={printRef}>
            <Box>
              <Image src={MapLogo} alt="ESG onSite" />
            </Box>
            <SimpleGrid columns={2} spacing={4} mb={5} mt={5}>
              <Box>
                <Text sx={labelStyles}>
                  {strings.location_name} : {jsaQuery?.data?.job_title || '-'}
                </Text>
                <Text sx={labelStyles}>
                  {strings.customer_name}:{' '}
                  {jsaQuery?.data?.customer_name || '-'}
                </Text>
                <Text sx={labelStyles}>
                  {strings.operator_name}: {jsaQuery?.data?.completed_by || '-'}
                </Text>
              </Box>
              <Box>
                <Text sx={labelStyles}>
                  {strings.date_start}:{' '}
                  {DateFormatMDY(jsaQuery?.data?.created_at) || '-'}
                  {'  '}
                  {strings.date_end}:{' '}
                  {DateFormatMDY(
                    jsaQuery?.data?.jsa_endwork_summary?.updated_at
                  ) || '-'}
                </Text>
                <Text sx={labelStyles}>
                  {strings.time_start}: {TimeFormat(jsaQuery?.data?.created_at)}
                  {'  '} {strings.time_end}:{' '}
                  {DateFormatMDY(
                    jsaQuery?.data?.jsa_endwork_summary?.updated_at
                  ) || '-'}
                </Text>
              </Box>
              <Box>
                <Text sx={labelStyles}>
                  {strings.location_coordinates}:{' '}
                  {jsaQuery?.data?.location?.lat},{' '}
                  {jsaQuery?.data?.location?.lng}
                </Text>
              </Box>
              <Box>
                <Text sx={labelStyles}>
                  {strings.work_type}: {jsaQuery?.data?.work_type_name}
                </Text>
              </Box>
            </SimpleGrid>

            <JSACrewMembers crewMembersData={jsaQuery?.data?.crews} />
            <JSAEquipment ppeData={jsaQuery?.data?.ppe} />
            <JSAPreJobHazard
              hazardData={jsaQuery?.data?.pre_hazards}
              stepData={jsaQuery?.data?.steps}
            />
            <JSATimeLine timeLineData={jsaQuery?.data?.time_line} />
            
            {jsaQuery?.data?.is_closed && (
            <JSAEndSummary endSummaryData={jsaQuery?.data?.jsa_endwork_summary} />
          )} 
          </Box>
        </div>
      </Stack>
    </>
  );
};

export default JSAViewReport;
