import { Avatar, Center, Stack, Text, Tooltip } from '@chakra-ui/react';
import {
  messageReceiveStyled,
  messageSentStyles,
} from 'components/chat/ChatStyle';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { shallowEqual, useSelector } from 'react-redux';
import { getFullName } from 'utils/esg';

interface Props {
  message: any;
}

const ChatMessageDetails: React.FC<Props> = (props) => {
  const { message } = props;

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const getMessage = () => {
    if (message.is_admin && loggedInUser?.user_id === message?.user?.id) {
      return (
        <>
          <Stack direction="row" spacing="4" sx={{ alignSelf: 'flex-end' }}>
            <Text wordBreak="break-all" sx={messageSentStyles}>
              {message.text}
            </Text>
            <Center>
              <Tooltip
                hasArrow
                label={getFullName(loggedInUser?.user_information)}>
                <Avatar
                  src={
                    loggedInUser?.user_information?.image ||
                    loggedInUser?.user_information?.profile_image
                  }
                  name={getFullName(loggedInUser?.user_information)}
                />
              </Tooltip>
            </Center>
          </Stack>
        </>
      );
    } else if (message.is_admin) {
      return (
        <>
          <Stack direction="row" spacing="4">
            <Center>
              <Tooltip hasArrow label={getFullName(message?.user)}>
                <Avatar
                  name={getFullName(message?.user)}
                  src={message?.user?.image}
                />
              </Tooltip>
            </Center>
            <Text wordBreak="break-all" sx={messageReceiveStyled}>
              {message.text}
            </Text>
          </Stack>
        </>
      );
    } else {
      return (
        <>
          <Stack direction="row" spacing="4">
            <Center>
              <Tooltip hasArrow label={getFullName(message?.user)}>
                <Avatar
                  name={getFullName(message?.user)}
                  src={message?.user?.image}
                />
              </Tooltip>
            </Center>
            <Text wordBreak="break-all" sx={messageReceiveStyled}>
              {message.text}
            </Text>
          </Stack>
        </>
      );
    }
  };

  return <>{getMessage()}</>;
};

export default ChatMessageDetails;
