import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTagTitle from 'components/common/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { CompanySchema } from 'constants/schema';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { truncateString } from 'utils';
import history from 'utils/history';

const ViewCompany: React.FC = () => {
  const { id }: any = useParams();
  const companyApi = new CompanyResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  // get logged in user
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const companyQuery = useQuery<CompanySchema>(
    [`company${id}`, id],
    () => companyApi.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const approveCompany = useMutation((id: any) => companyApi.approve(id));

  const handleApproveCompany = () => {
    approveCompany.mutate(id, {
      onSuccess: (res: any) => {
        toast({
          title: strings.company_has_been_approved,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('companyList');
        history.push(routes.companies.list);
      },
    });
  };

  if (companyQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (companyQuery.isError) {
    history.push(routes.companies.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.companies.list}>
              {strings.company_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {truncateString(companyQuery?.data?.name || '')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.company_details}
          </Heading>
          {((companyQuery?.data?.created_by &&
            companyQuery?.data?.created_by === loggedInUser?.user_id) ||
            loggedInUser?.user_information?.role.toLowerCase() ===
              'superadmin') && (
            <Stack direction="row" spacing="6">
              {!companyQuery?.data?.is_approved && (
                <AccessControl
                  allowedPermissions={[PermissionRequest['approve:company']]}
                  renderNoAccess={(data: any) => data}>
                  <Button
                    size="sm"
                    colorScheme="primary"
                    onClick={() => handleApproveCompany()}>
                    {strings.approve_company}
                  </Button>
                </AccessControl>
              )}
              <Button
                size="sm"
                colorScheme="primary"
                onClick={() =>
                  history.push(routes.companies.edit.replace(':id', id))
                }>
                {strings.edit_company}
              </Button>
            </Stack>
          )}
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.name}</FormLabel>
                <Box sx={infoStyles}>
                  <CustomTagTitle
                    type="text"
                    status={companyQuery?.data?.is_active}
                    name={companyQuery?.data?.name || '-'}
                  />
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.division}</FormLabel>
                <Box sx={infoStyles}>{companyQuery?.data?.division || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.sub_division}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.subdivision || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.email}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.company_email || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.phone}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.company_phone || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.address}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.location?.address || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.address} 1</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.location?.address1 || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.city}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.location?.city || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.state}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.location?.state || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.zip_code}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.location?.zip || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.is_approved}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.is_approved
                    ? strings.approved
                    : strings.pending || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.status}</FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.is_active ? 'Active' : 'Inactive' || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.user_manager_contact_name}
                </FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.user_manager_contact_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.user_manager_phone_number}
                </FormLabel>
                <Box sx={infoStyles}>
                  {companyQuery?.data?.user_manager_contact || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.about}</FormLabel>
                <Box sx={infoStyles}>
                  {truncateString(companyQuery?.data?.about || '-')}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
          {companyQuery?.data?.image && (
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.company_picture}
                  </FormLabel>
                  <Box sx={infoStyles}>
                    <Image
                      boxSize="150px"
                      src={companyQuery?.data?.image}
                      alt={companyQuery?.data?.name}
                    />
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default ViewCompany;
