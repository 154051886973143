import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import PPEResource from 'api/ppe';
import {
  activeStyles,
  normalStyles,
  reactSelectStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PPEListItem from 'components/ppe/PPEListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { PPESchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  company: '';
  search: string;
  is_mandatory: string;
  searchCompany: '';
}

const PPEList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const ppeAPI = new PPEResource();

  const [totalPPE, setTotalPPE] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '',
    company: '',
    is_mandatory: '',
    searchCompany: '',
  });
  const companyAPI = new CompanyResource();
  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [`company-list`, { search: filterParams.searchCompany }],
    () =>
      companyAPI
        .allList({ search: filterParams.searchCompany, limit: 50 })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalPPE,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: ppeList, isLoading: isPPEListLoading } = useQuery(
    [
      'ppeList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.search,
        company: filterParams.company,
        is_mandatory: filterParams.is_mandatory,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        company: filterParams.company,
        search: filterParams.search,
      };
      if (filterParams.is_mandatory !== '') {
        queryParams.is_mandatory =
          filterParams.is_mandatory === 'true' ? true : false;
      }
      const response = await ppeAPI.list(queryParams);
      setTotalPPE(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: data.search,
      is_mandatory: data.is_mandatory || '',
      company: data.company ? data?.company?.value : '',
    }));
  };

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchCompany: value,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset({
      company: '',
      search: '',
    });
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: '',
      is_approved: '',
      company: '',
    }));
    setIsLoading(() => '');
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.ppe}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.ppe.list}>
              {strings.all_ppe}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.ppe_list}</Heading>
          <Button
            size="sm"
            colorScheme="primary"
            type="button"
            onClick={() => history.push(routes.ppe.create)}>
            {strings.add_new_ppe}
          </Button>
        </Flex>
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <Input
                  type="text"
                  placeholder={strings.search}
                  {...methods.register('search')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.is_mandatory}</FormLabel>
                <Select
                  placeholder={strings.select}
                  id="is_mandatory"
                  rounded="sm"
                  {...methods.register('is_mandatory')}>
                  <option value="true">{strings.yes}</option>
                  <option value="false">{strings.no}</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.company}</FormLabel>
                <Controller
                  control={methods.control}
                  name="company"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="company"
                      placeholder={strings.select_company}
                      isLoading={isCompanyLoading}
                      options={companyOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onInputChange={handleCompanyChange}
                    />
                  )}
                />
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.is_mandatory}</Th>
                  {ppeList?.is_superadmin ?
                    <Th>{strings.company}</Th>
                    : ''
                  }
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isPPEListLoading &&
                  ppeList?.results?.map((ppeData: PPESchema, index: number) => (
                    <PPEListItem
                      ppeData={ppeData}
                      is_superadmin={ppeList?.is_superadmin}
                      key={ppeData.id}
                      index={startingSN + index}
                    />
                  ))}
                {isPPEListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isPPEListLoading}
              totalCount={totalPPE}
              resultCount={ppeList?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {ppeList?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isPPEListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default PPEList;
