import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import WorkTypeResource from 'api/work_type';
import { CenterSpinner } from 'components/common/CenterSpinner';
import WorkTypeForm from 'components/WorkType/WorkTypeForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { WorkTypeSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditWorkType: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);
  const [workSteps, setWorkSteps] = useState<any>([]);

  const methods = useForm<WorkTypeSchema>();
  const methodStep = useForm<any>();

  const workTypeAPI = new WorkTypeResource();

  const workTypeQuery = useQuery(
    [`work-type${id}-edit`, id],
    () =>
      workTypeAPI.get(id).then((res) => {
        setWorkSteps(res?.data?.work_steps);
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateWorkType = useMutation((data: any) =>
    workTypeAPI.update(id, data)
  );
    
  const onSubmit = (data: any) => {
    data.steps = workTypeQuery?.data?.work_steps;
    const formData = {
      name: data.name,
      steps: workTypeQuery?.data?.work_steps,
      company: data?.company?.value,
      description: data?.description
    }
    updateWorkType.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.work_type_update_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('workTypeList');
        history.push(routes.workType.list);
      }, 
    });
  };

  // const onSubmitWorkStep = (data: any) => {
  //   setWorkSteps([...workSteps, data]);
  //   onModalClose();
  // };

  // const onModalClose = () => {
  //   setModalOpen(false);
  //   methodStep.setValue('operating_procedure', '');
  //   methodStep.setValue('hazard_identification', '');
  //   methodStep.setValue('hazard_relief', '');
  // };

  if (workTypeQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (workTypeQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.work_type_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.workType.list}>
            {strings.work_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.workType.edit}>
            {strings.edit_work_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_work_type}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateWorkType.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.work_type_update_failed}
                </Alert>
              )}
              <WorkTypeForm workTypeData={workTypeQuery.data} />
              {/* <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{strings.step}</Th>
                      <Th>{strings.operating_procedure}</Th>
                      <Th>{strings.hazard_identification}</Th>
                      <Th>{strings.hazard_relief}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {workSteps.length > 0 &&
                      workSteps.map((step: any, index: number) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>
                            {step.operating_procedure
                              ? strings.required
                              : strings.optional}
                          </Td>
                          <Td>
                            {step.hazard_identification
                              ? strings.required
                              : strings.optional}
                          </Td>
                          <Td>
                            {step.hazard_relief
                              ? strings.required
                              : strings.optional}
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Stack direction="row" spacing={4}>
                <Button
                  colorScheme="primary"
                  onClick={() => setModalOpen(true)}>
                  + {strings.add_step}
                </Button>
              </Stack> */}
              <ButtonGroup mt={10}>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateWorkType.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>

      {/* <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.add_step}</ModalHeader>
          <ModalCloseButton />
          <FormProvider {...methodStep}>
            <form onSubmit={methodStep.handleSubmit(onSubmitWorkStep)}>
              <ModalBody>
                <WorkTypeStepForm />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button colorScheme="primary" type="submit">
                    {strings.add_work_type}
                  </Button>
                  <Button variant="outline" onClick={onModalClose}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal> */}
    </Stack>
  );
};

export default EditWorkType;
