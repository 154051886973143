import {
  THEME_ANNOUNCEMENT,
  THEME_CHAT,
  THEME_COMPANY,
  THEME_DASHBOARD,
  THEME_FAQ,
  THEME_FIELD_REPORT,
  THEME_HAZARD,
  THEME_LOCATION,
  THEME_PPE,
  THEME_REPORTS,
  THEME_SETTING,
  THEME_TASK,
  THEME_USER,
} from 'constants/common';
import React, { createContext, useEffect, useMemo, useState } from 'react';
interface ThemeContextInterface {
  theme: any;
  setTheme?: any;
}

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeContext = createContext<ThemeContextInterface>({
  theme: 'default',
});

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState('theme-green');

  useEffect(() => {
    document.body.classList.remove(
      THEME_USER,
      THEME_COMPANY,
      THEME_LOCATION,
      THEME_PPE,
      THEME_TASK,
      THEME_SETTING,
      THEME_HAZARD,
      THEME_ANNOUNCEMENT,
      THEME_CHAT,
      THEME_REPORTS,
      THEME_FAQ,
      THEME_DASHBOARD,
      THEME_FIELD_REPORT
    );
    if (theme) document.body.classList.add(theme);
  }, [theme, setTheme]);

  useEffect(() => {
    if (theme) document.body.classList.add(theme);
  }, [theme, setTheme]);

  const defaultValue = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme, setTheme]
  );

  return (
    <ThemeContext.Provider value={defaultValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
