import { Td, Tr } from '@chakra-ui/react';
import React from 'react';
import { DateFormatMDY } from 'utils';

interface Props {
  reportData: any;
  index: number;
}
const CompanyListItemReport: React.FC<Props> = (props) => {
  const { reportData, index } = props;

  return (
    <Tr key={reportData.id}>
      <Td>{index}</Td>
      <Td>{reportData?.company}</Td>
      <Td>{DateFormatMDY(reportData?.date_added) || '-'}</Td>
      <Td>{DateFormatMDY(reportData?.date_removed) || '-'}</Td>
      <Td>{reportData?.location || '-'}</Td>
      <Td isNumeric>{reportData?.number_of_jobs}</Td>
      <Td isNumeric>{reportData?.total_hours_worked}</Td>
      <Td isNumeric>{reportData?.total_workers}</Td>
    </Tr>
  );
};

export default CompanyListItemReport;
