import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import HazardTypeResource from 'api/hazard_type';
import HazardTypeForm from 'components/hazardType/HazardTypeForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const CreateHazardType: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<any>();
  const hazardTypeAPI = new HazardTypeResource();

  // get logged in user detail
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const [errorMessage, setErrorMessage] = useState<any>();

  const addHazardType = useMutation((data: any) => hazardTypeAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.hazard_type_success_msg,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('hazardTypeList');
      history.push(routes.hazards.hazardType.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      setErrorMessage(error?.response?.data?.message)
      // if (objKey) setErrorMessage(error?.response?.data[objKey][0]);
      // else setErrorMessage(strings.hazard_type_err_msg);
    },
  });

  const onSubmit = async (data: any) => {
    const jsonData = {
      name: data?.name,
      description: data?.description,
      company_id:
        loggedInUser?.user_information?.company_id > 0
          ? loggedInUser?.user_information?.company_id
          : null,
    };
    addHazardType.mutate(jsonData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.hazards.hazardType.list}>
            {strings.hazard_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.hazards.hazardType.create}>
            {strings.add_new_hazard_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.add_new_hazard_type}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addHazardType.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <HazardTypeForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addHazardType.isLoading}>
                  {strings.add_hazard_type}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateHazardType;
