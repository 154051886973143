import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserFieldReportResource from 'api/user_field_report';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FieldMeasurementList from 'components/userFieldReport/FieldMeasurement';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { truncateString } from 'utils';
import history from 'utils/history';

const ViewUserFieldReport: React.FC = () => {
  const { id }: any = useParams();
  const userFieldReportApi = new UserFieldReportResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const userFieldReportQuery = useQuery<any>(
    [`user-field-report${id}`, id],
    () => userFieldReportApi.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (userFieldReportQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userFieldReportQuery.isError) {
    history.push(routes.fieldReporting.userFieldReport.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.fieldReporting.userFieldReport.list}>
              {strings.user_field_report}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {truncateString(userFieldReportQuery?.data?.title || '')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.user_field_report_details}
          </Heading>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
                <Box sx={infoStyles}>
                  {userFieldReportQuery?.data?.company_detail?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.field_report}</FormLabel>
                <Box sx={infoStyles}>
                  {userFieldReportQuery?.data?.fieldReport?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.field_type}</FormLabel>
                <Box sx={infoStyles}>
                  {userFieldReportQuery?.data?.fieldType?.title || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
                <Box sx={infoStyles}>
                  {userFieldReportQuery?.data?.description || '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid w="100%">
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.measurement_unit}</FormLabel>
              <FieldMeasurementList
                fieldMeasurement={
                  userFieldReportQuery?.data?.unit_measure_value
                }
                hasfieldMeasurementValue={true}
              />
            </FormControl>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewUserFieldReport;
