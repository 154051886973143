import Resource from 'api/resource';
import http from 'utils/http';

class ChatResource extends Resource {
  constructor() {
    super('chats/');
  }

  // Chat Item List
  currentConversation(query?: any) {
    return http({
      url: `get-conversations/`,
      method: 'get',
      params: query,
    });
  }

  sendMessage(id: any, resource: any) {
    return http({
      url: `/help-chats/${id}/`,
      method: 'post',
      data: resource,
    });
  }

  getAllMessage(id: any) {
    return http({
      url: `/help-chats/${id}/`,
      method: 'get',
    });
  }

  nextMessage(nextUrl: string) {
    return http({
      url: nextUrl,
      method: 'get',
    });
  }
}

export { ChatResource as default };
