const esp = {
  login: 'Acceso',
  log_in: 'Iniciar sesión',
  auth_message:
    'No está autorizado para iniciar sesión. Póngase en contacto con el administrador.',
  invalid_login_message: 'El nombre de usuario o la contraseña no coinciden.',
  login_header: 'Ingrese sus credenciales para iniciar sesión en ESG OnSite',
  username: 'Nombre de usuario',
  username_required: 'Se requiere nombre de usuario',
  password: 'Clave',
  password_required: 'se requiere contraseña',
  forgot_password: '¿Has olvidado tu contraseña?',
  forgot_password_success_message:
    '¡Le hemos enviado un correo electrónico con su enlace de restablecimiento de contraseña!',
  forgot_password_header:
    'Por favor, introduzca el correo electrónico que utiliza para iniciar sesión en su cuenta.',
  email_address_not_found: 'Dirección de correo electrónico no encontrado.',
  email: 'Correo electrónico',
  your_email_address: 'Su dirección de correo electrónico',
  email_address_required: 'Se requiere Dirección de correo electrónico',
  valid_email_msg: 'Proporcione una dirección de correo electrónico válida',
  request_password_reset: 'Petición para la recuperación de contraseña',
  back_to_login: 'Atrás para iniciar sesión',
  register: 'Registro',
  enter_for_register_into: 'Entrar para registrarse en',
  something_is_wrong:
    'Algo está mal. Póngase en contacto con el administrador.',
  reset_password_success_msg: 'Inicie sesión con una nueva contraseña',
  reset_password: 'Restablecer la contraseña',
  reset_password_header:
    ' Ingrese los campos que utiliza para restablecer su cuenta.',
  new_password: 'Nueva contraseña',
  valid_password_msg: 'Proporcione una contraseña válida.',
  password_hint:
    'Mínimo 8 caracteres, al menos una mayúscula, una minúscula, un número y un carácter especial.',
  confirm_password: 'Confirmar contraseña',
  password_not_match: 'Las contraseñas no coinciden.',
  save_reset_password: 'Guardar Restablecer contraseña',
  set_password_success_msg: 'Inicie sesión con una nueva contraseña',
  create_password: 'Crear contraseña',
  set_password_header:
    'Ingrese los campos para crear una contraseña para su cuenta.',
  save_password: 'Guardar contraseña',
  delete_announcement: 'Eliminar anuncio',
  edit_announcement: 'Editar anuncio',
  view_announcement: 'Ver anuncio',
  announcement_delete_success_msg: 'El anuncio ha sido eliminado.',
  announcement: 'Anuncio',
  all_announcement: 'Todo Anuncio',
  announcement_list: 'Lista de anuncios',
  add_new_announcement: 'Agregar nuevo anuncio',
  search: 'Búsqueda',
  filter: 'Filtrar',
  reset_filter: 'Restablecer filtro',
  title: 'Título',
  link: 'Enlace',
  description: 'Descripción',
  written_by: 'Escrito por',
  action: 'Acción',
  announcement_add_msg: 'Se ha creado el anuncio.',
  announcement_management: 'Gestión de anuncios',
  announcement_creation_failed: 'La creación del anuncio falló.',
  add_announcement: 'Agregar anuncio',
  cancel: 'Cancelar',
  announcement_update_msg: 'Anuncio actualizado con éxito.',
  announcement_not_found: 'Anuncio no encontrado',
  announcement_update_failed: 'La actualización del anuncio falló.',
  update: 'Actualizar',
  announcement_details: 'Detalles del anuncio',
  company_feed: 'Feed de empresa',
  jobsite_feed: 'Feed del sitio de trabajo',
  all_user: 'Todos los usuarios',
  image: 'Imagen',
  new_message_from: 'Nuevo mensaje de',
  chat: 'Charlar',
  submit: 'Enviar',
  confirm_hide_company_msg:
    '¿Estás seguro de que quieres ocultar esta empresa?',
  confirm_show_company_msg:
    '¿Estás seguro de que quieres mostrar esta empresa?',
  hide_company: 'Ocultar empresa',
  show_company: 'Mostrar empresa',
  view_company: 'Ver empresa',
  edit_company: 'Editar empresa',
  delete_company: 'Eliminar empresa',
  company_activated: 'La empresa ha sido activada.',
  company_deactivated: 'La empresa ha sido desactivada.',
  company_delete_success_msg: 'La empresa ha sido eliminada.',
  admin_email: 'Correo electrónico del administrador',
  admin_phone: 'Teléfono del administrador',
  company: 'Compañía',
  all_company: 'Toda la empresa',
  company_list: 'Lista de empresas',
  add_new_company: 'Añadir nueva empresa',
  is_approved: '¿Esta aprobado?',
  select: 'Seleccione',
  pending: 'Pendiente',
  approved: 'Aprobado',
  name: 'Nombre',
  address: 'Dirección',
  contact: 'Contacto',
  company_create_msg: 'Se ha creado la empresa',
  company_create_err_msg: 'Error al crear la empresa.',
  company_management: 'Administracion de COMPAÑIA',
  add_company: 'Agregar empresa',
  not_allow_to_edit_company: 'No tiene permiso para editar esta empresa.',
  commpany_updated_successfully: 'Empresa actualizada con éxito.',
  company_creation_failed: 'Error al crear la empresa.',
  company_not_found: 'Empresa no encontrada',
  company_has_been_approved: 'La empresa ha sido aprobada.',
  company_details: 'Detalles de la compañía',
  approve_company: 'Aprobar empresa',
  division: 'División',
  sub_division: 'Subdivisión',
  phone: 'Teléfono',
  city: 'Ciudad',
  state: 'Estado',
  zip_code: 'Código postal',
  status: 'Estado',
  user_manager_contact_name: 'Nombre de contacto del administrador de usuarios',
  user_manager_phone_number: 'Número de teléfono del administrador de usuarios',
  about: 'Sobre',
  company_picture: 'Imagen de la empresa',
  faq: 'Preguntas más frecuentes',
  all_faq: 'Todas las preguntas frecuentes',
  faq_list: 'Lista de preguntas frecuentes',
  field_report_delete_success_msg: 'El informe de campo ha sido eliminado.',
  view_field_report: 'Ver informe de campo',
  edit_field_report: 'Editar informe de campo',
  delete_field_report: 'Eliminar informe de campo',
  field: 'Campo',
  select_field: 'Seleccionar campo',
  field_report_success_msg: 'Se ha creado el informe de campo.',
  field_report_err_msg: 'Falló la creación del informe de campo.',
  field_reporting: 'Informes de campo',
  add_new_field_report: 'Agregar nuevo informe de campo',
  add_field_report: 'Agregar informe de campo',
  all_field_report: 'Informe de todos los campos',
  field_report_list: 'Lista de informes de campo',
  fields: 'Campos',
  created_by: 'Creado por',
  select_company: 'Seleccionar empresa',
  field_report: 'Informe de campo',
  field_reporting_details: 'Detalles de informes de campo',
  add_new_user_field_report: 'Agregar nuevo informe de campo de usuario',
  field_type: 'Tipo de campo',
  measurement_units: 'Unidades de medida',
  view_field_type: 'Ver tipo de campo',
  edit_field_type: 'Editar tipo de campo',
  delete_field_type: 'Eliminar tipo de campo',
  field_type_delete_success_msg: 'Se ha eliminado el tipo de campo.',
  field_type_success_msg: 'Se ha creado el tipo de campo.',
  field_type_management: 'Gestión de tipos de campo',
  add_new_field_type: 'Agregar nuevo tipo de campo',
  field_type_creation_failed: 'La creación del tipo de campo falló.',
  add_field_type: 'Agregar tipo de campo',
  field_type_update_success_msg: 'Tipo de campo actualizado con éxito.',
  field_type_not_found: 'Tipo de campo no encontrado',
  field_type_update_err_msg: 'La actualización del tipo de campo falló.',
  all_field_type: 'Todo tipo de campo',
  field_type_list: 'Lista de tipos de campo',
  field_type_details: 'Detalles del tipo de campo',
  hazard_delete_success_msg: 'Se ha eliminado el peligro.',
  select_hazard_type: 'Seleccionar tipo de peligro',
  hazard_assign_success_msg: 'Peligro asignado con éxito',
  view_hazard: 'Ver peligro',
  delete_hazard: 'Eliminar peligro',
  view_hazard_type: 'Ver tipo de peligro',
  delete_hazard_type: 'Eliminar tipo de peligro',
  edit_hazard_type: 'Editar tipo de peligro',
  assign_to: 'Asignar a',
  hazard_success_msg: 'Se ha creado un peligro',
  hazard_creation_failed: 'Falló la creación de peligro.',
  enable_your_location: 'Habilite su ubicación.',
  hazard: 'Peligro',
  add_new_hazard: 'Agregar nuevo peligro',
  add_hazard: 'Agregar peligro',
  hazard_updated_success_msg: 'Peligro actualizado con éxito.',
  hazard_not_found: 'Peligro no encontrado',
  edit_hazard: 'Editar peligro',
  hazard_update_failed: 'Actualización de peligro fallida.',
  all_hazard: 'todo peligro',
  date: 'Fecha',
  is_resolved: '¿Esta resuelto?',
  yes: 'Sí',
  no: 'No',
  location: 'Ubicación',
  reported_by: 'Reportado por',
  reported_date: 'Fecha informada',
  type: 'Escribe',
  hazard_list: 'Lista de peligros',
  hazard_details: 'Detalles de peligro',
  reporter_phone: 'Teléfono del reportero',
  resolved_by: '¿Resuelto por?',
  choose_location: 'Elegir la ubicación',
  upload_hazard_image: 'Cargar imagen de peligro',
  hazard_image: 'Imagen de peligro',
  notes: 'notas',
  reporter_name: 'Nombre del reportero',
  hazard_type_success_msg: 'Se ha creado el tipo de peligro.',
  hazard_type_err_msg: 'Error al crear el tipo de peligro.',
  hazard_type: 'Tipo de peligro',
  add_new_hazard_type: 'Agregar nuevo tipo de peligro',
  add_hazard_type: 'Agregar tipo de peligro',
  hazard_type_update_msg: 'Tipo de peligro actualizado con éxito.',
  hazard_type_detail_not_found: 'No se encontró el detalle del tipo de peligro',
  hazard_type_updated_failed: 'La actualización del tipo de peligro falló.',
  all_hazard_types: 'Todos los tipos de peligro',
  hazard_type_list: 'Lista de tipos de peligro',
  hazard_type_delete_success_msg: 'Se ha eliminado el tipo de peligro.',
  work_type_delete_success_msg: 'Se ha eliminado el tipo de trabajo.',
  view_work_type: 'Ver tipo de trabajo',
  delete_work_type: 'Eliminar tipo de trabajo',
  work_type_success_msg: 'Se ha creado el tipo de trabajo.',
  work_type: 'Tipo de trabajo',
  add_new_work_type: 'Agregar nuevo tipo de trabajo',
  work_type_creation_failed: 'La creación del tipo de trabajo falló.',
  add_work_type: 'Agregar tipo de trabajo',
  work_type_update_msg: 'Tipo de trabajo actualizado con éxito.',
  work_type_not_found: 'Tipo de trabajo no encontrado',
  edit_work_type: 'Editar tipo de trabajo',
  work_type_update_failed: 'La actualización del tipo de trabajo falló.',
  step: 'Paso',
  operating_procedure: 'Procedimiento de operación',
  hazard_identification: 'Identificación de peligros',
  hazard_relief: 'Alivio de peligros',
  required: 'Requerido',
  optional: 'Opcional',
  add_step: 'Agregar paso',
  work_type_details: 'Detalles del tipo de trabajo',
  work_type_list: 'Lista de tipos de trabajo',
  view_location: 'Ver ubicación',
  edit_location: 'Editar ubicación',
  delete_location: 'Eliminar ubicación',
  location_success_msg: 'Se ha creado la ubicación.',
  location_management: 'Gestión de ubicaciones',
  add_new_location: 'Agregar nueva ubicación',
  location_creation_failed: 'La creación de la ubicación falló.',
  add_location: 'Añade una ubicación',
  location_update_msg: 'Ubicación actualizada con éxito.',
  location_not_found: 'Ubicación no encontrada',
  location_update_failed: 'La actualización de la ubicación falló.',
  all_location: 'Toda la ubicación',
  coordinate: 'Coordinar',
  location_list: 'Lista de ubicaciones',
  location_details: 'Detalles de ubicación',
  operator: 'Operador',
  location_contact_name: 'Ubicación Nombre de contacto',
  location_contact: 'Ubicación Contacto',
  customer_contact_name: 'Nombre de contacto del cliente',
  customer_contact: 'Contacto con el cliente',
  map_features: 'Características del mapa',
  map_reports: 'Informes de mapas',
  confirm_ppe_hide_msg: '¿Estás seguro de que quieres ocultar este PPE?',
  confirm_ppe_show_msg: '¿Estás seguro de que quieres mostrar este PPE?',
  this_is_mandatory_ppe: 'Este es un EPI obligatorio.',
  hide_ppe: 'Ocultar EPI',
  show_ppe: 'Mostrar EPI',
  ppe_activated: 'Se ha activado el EPI.',
  ppe_deactivated: 'El EPP ha sido desactivado.',
  ppe_delete_success_msg: 'Se ha eliminado el EPI.',
  view_ppe: 'Ver EPP',
  edit_ppe: 'Editar EPP',
  delete_ppe: 'Eliminar EPP',
  ppe_success_msg: 'Se ha creado el EPI.',
  ppe_management: 'Gestión de EPI',
  add_new_ppe: 'Agregar nuevo EPP',
  ppe_creation_failed: 'La creación del PPE falló.',
  add_ppe: 'Añadir EPP',
  ppe_updated_success_msg: 'PPE actualizado con éxito.',
  ppe_not_found: 'EPP no encontrado',
  ppe_update_failed: 'La actualización del EPP falló.',
  ppe: 'EPP',
  all_ppe: 'Todos los EPP',
  is_mandatory: '¿Es obligatorio?',
  ppe_list: 'Lista de EPI',
  ppe_details: 'Detalles del EPP',
  profile_updated_successfully: 'perfil actualizado con éxito',
  login_with_new_password: 'Inicie sesión con una nueva contraseña',
  current_password_is_incorrect: 'La contraseña actual es incorrecta.',
  my_profile: 'Mi perfil',
  edit_profile: 'Editar perfil',
  profile_update_failed: 'Error al actualizar el perfil.',
  save_now: 'Guardar ahora',
  change_password: 'Cambia la contraseña',
  change_now: 'Cambia ahora',
  invalid_profile_upload: 'Carga de perfil no válida',
  file_must_be_an_image_type: 'El archivo debe ser un tipo de imagen.',
  invalid_file_size: 'Tamaño de archivo no válido',
  file_size_less_5MB: 'El tamaño del archivo debe ser inferior a 5 MB',
  user_not_found: 'Usuario no encontrado',
  clear_profile_image: 'Borrar imagen de perfil',
  undo_action_afterwards:
    '¿Está seguro? No puede deshacer esta acción después.',
  uploading_profile: 'Cargando perfil',
  cell_phone: 'Teléfono móvil',
  work_email: 'Correo electrónico del trabajo',
  work_phone: 'Teléfono del trabajo',
  allergies: 'alergias',
  blood_type: 'Tipo de sangre',
  certification_notes: 'Notas de certificación',
  emergency_contact_name: 'nombre del contacto de emergencia',
  emergency_contact_phone: 'Teléfono de contacto de emergencia',
  badges: 'Insignias',
  upload_profile: 'Subir perfil',
  invalid_file_type: 'Tipo de archivo invalido',
  sn: 'S. N.',
  company_name: 'nombre de empresa',
  date_added: 'Fecha Agregada',
  date_removed: 'Fecha de eliminación',
  number_of_jobs_worked: 'Número de trabajos trabajados',
  total_hours_worked: 'Total de horas trabajadas',
  total_users: 'Usuarios totales',
  report: 'Reporte',
  company_report: 'Informe de la empresa',
  select_location: 'Seleccionar ubicación',
  last_name: 'Apellido',
  first_name: 'Primer nombre',
  employee: 'Empleado',
  employee_report: 'Informe de empleado',
  job_summaries: 'Resúmenes de trabajo',
  job_summaries_report: 'Informe de resúmenes de trabajos',
  view_job_summaries_report: 'Ver informe de resúmenes de trabajos',
  work_description: 'Descripción del trabajo',
  job_summaries_info: 'Información de resúmenes de trabajo',
  print: 'Impresión',
  signed_jsa_info: 'Información JSA firmada',
  job_title: 'Título profesional',
  completed_by: 'Completado por',
  customer: 'Cliente',
  crew_member: 'miembro de la tripulación',
  view_jsa_report: 'Ver informe JSA',
  latitude: 'Latitud',
  longitude: 'Longitud',
  jsa: 'JSA',
  jsa_report: 'Informe JSA',
  jsa_details: 'Detalles de JSA',
  jsa_info: 'Información de JSA',
  jsa_completed_by: 'JSA completado por',
  location_of_work: 'Lugar de Trabajo',
  jsa_creator: 'Creador de JSA',
  location_name: 'Nombre del lugar',
  customer_name: 'Nombre del cliente',
  operator_name: 'Nombre del operador',
  date_start: 'Fecha de inicio',
  date_end: 'Fecha de finalización',
  location_coordinates: 'Coordenadas de ubicación',
  user_coordinates: 'Coordenadas de usuario',
  foreman: 'Capataz',
  time_start: 'Hora de inicio',
  time_end: 'Fin del tiempo',
  permission_updated_successfully: 'Permiso actualizado con éxito.',
  permission_not_found: 'Permiso no encontrado',
  settings: 'Ajustes',
  role_and_permissions: 'Rol y permisos',
  edit_permissions: 'Editar permisos',
  permission_update_failed: 'La actualización del permiso falló.',
  role_and_permission_list: 'Lista de roles y permisos',
  permission: 'Permiso',
  permissions: 'permisos',
  role: 'Role',
  sop_delete_success_msg: 'Se han eliminado los SOP',
  view_sop: 'Ver POE',
  edit_sop: 'Editar POE',
  delete_sop: 'Eliminar POE',
  sop_success_msg: 'Se han creado SOP',
  sops: 'POE',
  add_sops: 'Agregar SOP',
  add_new_sops: 'Agregar nuevos SOP',
  sops_creation_failed: 'La creación de SOP falló.',
  sops_updated_msg: 'SOP actualizados con éxito.',
  sops_not_found: 'SOP no encontrados.',
  edit_sops: 'Editar POE',
  sops_update_failed: 'La actualización de los SOP falló.',
  all_sops: 'Todos los POE',
  sops_list: 'Lista de SOP',
  sops_details: 'Detalles de los SOP',
  tasks: 'Tareas',
  user_management: 'Gestión de usuarios',
  faqs: 'preguntas frecuentes',
  user_field_report_delete_success_msg:
    'El informe de campo de usuario se ha eliminado.',
  view_user_field_report: 'Ver informe de campo de usuario',
  edit_user_field_report: 'Editar informe de campo de usuario',
  delete_user_field_report: 'Eliminar informe de campo de usuario',
  user_field_report_success_msg: 'Se ha creado el informe de campo de usuario.',
  user_field_report_management: 'Gestión de informes de campo de usuario',
  user_field_report_creation_failed:
    'La creación del informe de campo de usuario falló.',
  add_user_field_report: 'Agregar informe de campo de usuario',
  user_field_report_update_msg:
    'Informe de campo de usuario actualizado con éxito.',
  user_field_report_not_found: 'Informe de campo de usuario no encontrado',
  user_field_report: 'Informe de campo de usuario',
  user_field_report_udpate_failed:
    'La actualización del informe de campo de usuario falló.',
  all_user_field_report: 'Informe de campo de todos los usuarios',
  user_field_report_list: 'Lista de informes de campo de usuario',
  select_field_report: 'Seleccionar informe de campo',
  select_field_type: 'Seleccionar tipo de campo',
  user_field_report_details: 'Detalles del informe de campo de usuario',
  measurement_unit: 'Unidad de medida',
  approve_user: 'Aprobar usuario',
  users: 'Usuarios',
  user_profile: 'Perfil del usuario',
  user_approved: 'El usuario ha sido aprobado.',
  confirm_hide_user: '¿Estás seguro de que quieres ocultar a este usuario?',
  confirm_show_user: '¿Estás seguro de que quieres mostrar a este usuario?',
  hide_user: 'Ocultar usuario',
  show_user: 'Mostrar usuario',
  user_activated: 'El usuario ha sido activado.',
  user_deactivated: 'El usuario ha sido desactivado.',
  user_delete_success_msg: 'El usuario ha sido eliminado.',
  view_user: 'Ver usuario',
  delete_user: 'borrar usuario',
  user_create_success_msg: 'El usuario ha sido creado.',
  user_create_err_msg: 'Error al crear el usuario.',
  add_new_user: 'Añadir nuevo usuario',
  add_user: 'Agregar usuario',
  user_update_success_msg: 'El usuario ha sido actualizado.',
  user_update_err_msg: 'Actualización de usuario fallida.',
  edit_user: 'editar usuario',
  request_to_deactivate: 'Solicitud de desactivación',
  user: 'Usuario',
  all_users: 'Todos los usuarios',
  bulk_upload_template: 'Plantilla de carga masiva',
  work_type_template: 'Plantilla de tipo de trabajo',
  show_rows: 'Mostrar filas',
  showing: 'Demostración',
  out_of: 'fuera de',
  no_result_found: 'No se han encontrado resultados',
  loading: 'Cargando',
  dont_have_permission:
    'No tienes permiso para acceder a él. Póngase en contacto con el administrador.',
  upload_csv: 'Subir CSV',
  file_upload_success_msg: 'Archivo subido con éxito.',
  maximum_upload_attachments_size_upto_15MB:
    'Tamaño máximo de carga de archivos adjuntos de hasta 15 MB.',
  upload_valid_data: 'Cargue datos válidos.',
  upload_files: 'Subir archivos',
  uploading: 'Cargando',
  processing: 'Procesando',
  internal_server_error: 'error de servidor interno',
  delete: 'Borrar',
  valid_url_address_msg: 'Proporcione una dirección URL válida.',
  write_here: 'Escribe aquí...',
  upload_image: 'Cargar imagen',
  view: 'Vista',
  edit: 'Editar',
  confirm_delete: 'Estás seguro que quieres eliminar esto ?',
  active: 'Activo',
  only_1_file_allowed: 'Solo se permite 1 archivo.',
  drag_drop_title_5MB:
    'Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos. Sube archivos de hasta 5 MB.',
  maximum_upload_attachments_size_upto_5MB:
    'Tamaño máximo de carga de archivos adjuntos de hasta 5 MB.',
  file_size_too_large: 'El tamaño del archivo es demasiado grande.',
  delete_files: 'Borrar archivos',
  too_many_characters: 'demasiados personajes',
  valid_contact_number_msg: 'Proporcione un número de contacto válido.',
  upload_company_logo: 'Cargar el logotipo de la empresa',
  something_went_wrong: 'Perdón, algo salió mal.',
  team_notified:
    'Nuestro equipo ha sido notificado y lo solucionaremos tan pronto como podamos.',
  reload_page: 'Recargar página',
  take_me_home: 'Llévame a casa',
  invalid_credentials: 'Credenciales no válidas',
  collapse_sidebar: 'Contraer barra lateral',
  expand_sidebar: 'Expandir barra lateral',
  logout: 'Cerrar sesión',
  is_active: '¿Está activo?',
  company_active_crew: 'Tripulación activa de la empresa',
  current_password: 'contraseña actual',
  hide: 'Ocultar',
  show: 'Espectáculo',
  old_new_match: 'La contraseña antigua y nueva es la misma.',
  middle_name: 'Segundo nombre',
  emergency_name: 'Nombre de emergencia',
  safety_equipment_ppe: 'Equipo de seguridad (EPI)',
  pre_job_hazard_assessment: 'Evaluación de riesgos previa al trabajo',
  time_line: 'línea de tiempo',
  date_time: 'Fecha y hora',
  view_permission_and_role: 'Ver permiso y rol',
  edit_permission_and_role: 'Editar permiso y rol',
  chats: 'charlas',
  reports: 'informes',
  dashboard: 'Tablero',
  deactivate_user_request: 'Desactivar solicitud de usuario',
  environment_tracker: 'Rastreador de entorno',
  select_work_type: 'Seleccionar tipo de trabajo',
  sop_link: 'Enlace de POE',
  personal_email: 'Email personal',
  select_role: 'Seleccionar rol',
  field_type_name: 'Nombre del tipo de campo',
  measurement: 'Medición',
  units: 'Unidades',
  value: 'Valor',
  field_data: 'Datos de campo',
  select_field_report_data: 'Seleccionar datos de informe de campo',
  zip: 'Cremallera',
  superadmin: 'superadministrador',
  field_manager: 'Gerente de campo',
  field_operator: 'Operador de campo',
  service_company_admin: 'Administrador de la empresa de servicios',
  operator_company_admin: 'Administrador de la empresa del operador',
  company_safety_manager: 'Gerente de seguridad de la empresa',
  field_safety_manager: 'Gerente de Seguridad de Campo',
  work_type_step_success_msg: 'Se ha creado el paso de tipo de trabajo.',
  work_type_step_delete_msg: 'El paso de tipo de trabajo se ha eliminado.',
  work_type_step_update_msg: 'Se ha actualizado el paso del tipo de trabajo.',
  edit_work_type_step: 'Paso Editar tipo de trabajo',
  delete_work_type_step: 'Eliminar paso de tipo de trabajo',
  edit_step: 'Editar paso',
  jsa_end_summary: 'Resumen final de JSA',
  create_work_type_step: 'Crear paso de tipo de trabajo',
  add: 'agregar',
  qr_code: 'Código Qr'
};

export default esp;
