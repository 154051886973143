import Resource from 'api/resource';
import http from 'utils/http';

class UnitMeasureResource extends Resource {
  constructor() {
    super('unit-measure/');
  }

  list(query?: any) {
    return http({
      url: 'unit-measure/',
      method: 'get',
      params: query,
    });
  }
}

export { UnitMeasureResource as default };
