import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  ScaleFade,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { resetPassword } from 'api/auth';
import { EN, ESP, Logo } from 'assets/images';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { LanguageTypesSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronDown, BiChevronLeft, BiKey, BiHide, BiShow } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import history from 'utils/history';
import { validatePassword } from 'utils/validate';

const ResetPassword: React.FC = () => {
  const currentLanguage = strings.getLanguage();
  const [show, setShow] = React.useState(false);
  const [confirmShow, setConfirmShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleClickConfirmation = () =>setConfirmShow(!confirmShow);
  const [currentFlag, setCurrentFlag] = useState(() =>
    currentLanguage === 'en' ? EN : ESP
  );
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token') || '';
  const uid = params.get('uid') || '';
  if (token === '' || uid === '') history.push(routes.auth.login);
  const newPassword = useRef({});
  const [errMsg, setErrMsg] = useState();
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm();

  const resetPasswordMutation = useMutation((data) => resetPassword(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.reset_password_success_msg,
        status: 'success',
        isClosable: true,
      });
      history.push(routes.auth.login);
    },
    onError: (error: any) => {
      if (error?.response?.data?.uid)
        setErrMsg(error?.response?.data?.uid[0]);
      if (error?.response?.data?.errors?.token)
        setErrMsg(error?.response?.data?.token[0]);
      if (error?.response?.data?.errors?.password)
        setErrMsg(error?.response?.data?.password[0]);
    },
  });
  newPassword.current = watch('new_password1', '');

  const onSubmit = (data: any) => {
    data.token = token;
    data.uid = uid;
    resetPasswordMutation.mutate(data);
  };

  const handleLanguage = (Language: LanguageTypesSchema) => {
    setCurrentFlag(Language === 'en' ? EN : ESP);

    strings.setLanguage(Language);
    localStorage.setItem('language', Language);
  };

  return (
    <Box bg="gray.50">
      <Stack pos="absolute" right="10%">
        <Menu>
          <MenuButton
            p={2}
            transition="all 0.2s"
            _hover={{ borderBottom: 'gray.300' }}
            _expanded={{ borderBottom: 'gray.400' }}>
            <Flex>
              <MenuItem icon={<Image src={currentFlag} w="6" h="6" />}>
                {currentLanguage?.toLocaleUpperCase()}
              </MenuItem>
              <Box pt="2">
                <BiChevronDown />
              </Box>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => handleLanguage('esp')}
              icon={<Image src={ESP} w="6" h="6" />}>
              ESP
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('en')}
              icon={<Image src={EN} w="6" h="6" />}>
              EN
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
      <Center h="100vh">
        <Stack direction="column" spacing="8">
          <Stack spacing="4">
            <Center className="logoDiv" mt={'30px'} mx="auto">
              <Image w="16" src={Logo} className="logoImage" />
            </Center>
            <Stack>
              <Text
                align="center"
                fontSize="lg"
                fontWeight="medium"
                color={'green.600'}>
                ESG OnSite
              </Text>
            </Stack>
          </Stack>
          <Box bg="white" p="14" shadow="box" rounded="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="8">
                <Stack direction="column" spacing="4">
                  <Heading as="h1" size="xl">
                    {strings.reset_password}
                  </Heading>
                  <Text color="gray.500">{strings.reset_password_header}</Text>
                  {resetPasswordMutation.isError && (
                    <ScaleFade in={resetPasswordMutation.isError}>
                      <Alert status="error">
                        <AlertIcon />
                        {errMsg}
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>

                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.new_password1}>
                  <FormLabel>{strings.password}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiKey} />
                      }
                    />
                    <Input
                      id="password"
                      type={show ? 'text' : 'password'}
                      placeholder={strings.new_password}
                      {...register('new_password1', {
                        required: 'New Password',
                        validate: (value) =>
                          validatePassword(value) || strings.valid_password_msg,
                      })}
                    />
                    <InputRightElement>
                        <IconButton
                          icon={show ? <BiHide /> : <BiShow />}
                          onClick={handleClick}
                          aria-label={show ? 'Hide' : 'Show'}
                          size="sm"
                        />
                      </InputRightElement>
                  </InputGroup>
                  <FormHelperText>{strings.password_hint}</FormHelperText>
                  <FormErrorMessage>
                    {errors.new_password1 && errors.new_password1?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.new_password2}>
                  <FormLabel>{strings.confirm_password}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiKey} />
                      }
                    />
                    <Input
                      id="password_confirmation"
                      type={confirmShow ? 'text' : 'password'}
                      placeholder={strings.confirm_password}
                      {...register('new_password2', {
                        required: strings.confirm_password,
                        validate: (value: any) => {
                          return (
                            value === newPassword.current ||
                            strings.password_not_match
                          );
                        },
                      })}
                    />
                    <InputRightElement>
                        <IconButton
                          icon={confirmShow ? <BiHide /> : <BiShow />}
                          onClick={handleClickConfirmation}
                          aria-label={confirmShow ? 'Hide' : 'Show'}
                          size="sm"
                        />
                      </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.new_password2 && errors.new_password2?.message}
                  </FormErrorMessage>
                </FormControl>
                <VStack spacing="6">
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={resetPasswordMutation.isLoading}>
                    {strings.save_reset_password}
                  </Button>
                  <Link to={routes.auth.login}>
                    <Button
                      variant="link"
                      color="gray.400"
                      leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                      {strings.back_to_login}
                    </Button>
                  </Link>
                </VStack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default ResetPassword;
