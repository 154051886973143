import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  SimpleGrid,
  Text,
  Textarea,
} from '@chakra-ui/react';
import HazardTypeResource from 'api/hazard_type';
import { reactSelectStyles } from 'assets/css/commonStyles';
import ImageUploader from 'components/common/ImageUploader';
import MapContainer from 'components/common/MapContainer';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { validPhone } from 'utils/validate';

interface Props {
  hazardData?: any;
  position?: any;
  center?: any;
}

const HazardForm: React.FC<Props> = (props) => {
  const { hazardData, position, center } = props;
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<any>();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const hazardTypeAPI = new HazardTypeResource();

  const { data: hazardTypeQuery, isLoading: isHazardTypeLoading } = useQuery(
    [`hazard-type-list`],
    () =>
      hazardTypeAPI
        .itemList({ limit: 50 })
        .then((res) => res?.data?.hazard_type),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const hazardTypeList = hazardTypeQuery?.results;
  const hazardTypeOptions = hazardTypeList?.map((hazardType: any) => ({
    label: hazardType.name,
    value: hazardType.id,
  }));

  useEffect(() => {
    if (position?.lat) {
      setValue('latitude', position.lat);
      setLatitude(position.lat);
    }
    if (position?.lng) {
      setValue('longitude', position.lng);
      setLongitude(position.lng);
    }
  }, [position, setValue]);

  useEffect(() => {
    if (!hazardData) return;
    setValue('latitude', hazardData?.location?.lat);
    setLatitude(hazardData?.location?.lat);
    setValue('longitude', hazardData?.location?.lng);
    setLongitude(hazardData?.location?.lng);

    setValue('is_ammonium_present', hazardData?.is_ammonium_present);

    const hazard_type: any = {
      label: hazardData?.hazard_type?.name,
      value: hazardData?.hazard_type?.id,
    };
    setValue('hazard_type', hazard_type);
  }, [hazardData, setValue]);

  return (
    <form>
      <SimpleGrid columns={2} spacing={4}>
        <Box>
          <FormControl isInvalid={!!errors?.hazard_type} isRequired>
            <FormLabel>{strings.hazard_type}</FormLabel>
            <Controller
              control={control}
              name="hazard_type"
              rules={{
                required: strings.select_hazard_type,
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="hazard_type"
                  placeholder={strings.select_hazard_type}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isHazardTypeLoading}
                  options={hazardTypeOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.hazard_type && errors?.hazard_type?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.reporter_name}>
            <FormLabel>{strings.reporter_name}</FormLabel>
            <Input
              {...register('reporter_name', {
                maxLength: {
                  value: 100,
                  message: strings.too_many_characters,
                },
              })}
              type="text"
              defaultValue={hazardData?.reported_by?.reporter_name}
              disabled={hazardData?.reported_by?.reporter_name ? true : false}
              placeholder={strings.reporter_name}
            />
            <FormErrorMessage>
              {errors?.reporter_name && errors?.reporter_name?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.reporter_phone}>
            <FormLabel>{strings.reporter_phone}</FormLabel>
            <Input
              disabled={hazardData?.reported_by?.reporter_phone ? true : false}
              {...register('reporter_phone', {
                validate: (value) => {
                  if (value)
                    return (
                      validPhone(value) || strings.valid_contact_number_msg
                    );
                },
              })}
              type="number"
              defaultValue={hazardData?.reported_by?.reporter_phone}
              placeholder={strings.reporter_phone}
            />
            <FormErrorMessage>
              {errors?.reporter_phone && errors?.reporter_phone?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.additional_notes}>
            <FormLabel>{strings.notes}</FormLabel>
            <Textarea
              {...register('additional_notes')}
              defaultValue={hazardData?.additional_notes}
              placeholder={strings.write_here}
            />
            <FormErrorMessage>
              {errors?.additional_notes && errors?.additional_notes?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            {hazardData?.image && (
              <>
                <FormLabel>{strings.hazard_image}</FormLabel>
                <Image
                  src={hazardData.image}
                  title={hazardData?.hazard_type?.name}
                />
              </>
            )}
            {!hazardData && (
              <>
                <FormLabel>{strings.hazard_image}</FormLabel>
                <ImageUploader
                  description={strings.upload_hazard_image}
                  uploadedFiles={hazardData?.image}
                  accept="image/*"
                  fileKey="image"
                />
              </>
            )}
          </FormControl>
        </Box>
      </SimpleGrid>

      <SimpleGrid marginTop={'15px'}>
        <Box height={'60vh'}>
          <FormControl>
            <FormLabel>{strings.choose_location}</FormLabel>
            <Text>
              {strings.location}: {latitude} , {longitude}
            </Text>
            <MapContainer
              mapStyle={{
                height: '50vh',
                width: '100%',
                position: 'relative',
              }}
              mapData={{
                lat: latitude,
                lng: longitude,
              }}
              center={{
                lat: latitude,
                lng: longitude,
              }}
              draggable={true}
              setLongitude={setLongitude}
              setLatitude={setLatitude}
            />
          </FormControl>
        </Box>
      </SimpleGrid>
    </form>
  );
};

export default HazardForm;
