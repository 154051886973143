import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { shallowEqual, useSelector } from 'react-redux';
import { getFullName } from 'utils/esg';
import ChatListItem from './ChatListItem';

const ChatSearch: React.FC = () => {
  const { chatItemList } = useSelector(
    (state: any) => ({
      chatItemList: state?.data?.chat.chatItemList,
    }),
    shallowEqual
  );

  return (
    <Stack direction="column" spacing="6" w="300px">
      <Box maxH="80vh" overflow="auto">
        <PerfectScrollbar>
          <Stack direction="column" spacing="4" rounded="md">
            {chatItemList.length > 0 &&
              chatItemList.map((user: any) => (
                <ChatListItem
                  key={user.id}
                  name={getFullName(user)}
                  attachment={user?.image}
                  isSeen={user?.is_seen}
                  unreadChatCount={user?.new_message_count}
                  conversation={user.conversation_id}
                />
              ))}
          </Stack>
        </PerfectScrollbar>
      </Box>
    </Stack>
  );
};

export default ChatSearch;
