import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import LocationResource from 'api/location';
import LocationForm from 'components/location/LocationForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { LocationSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { GoogleGeoCode } from 'services/GoogleGeoCode';

const CreateLocation: React.FC = () => {
  const toast = useToast();
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<LocationSchema>();
  const locationAPI = new LocationResource();

  const [errorMessage, setErrorMessage] = useState<LocationSchema>();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const addLocation = useMutation((data: any) => locationAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.location_success_msg,
        description: res.data.email,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('locationList');
      history.push(routes.locations.list);
    },
    onError: (error: any) => {
      setErrorMessage(error?.response?.data?.errors);
    },
  });

  const onSubmit = async (data: any) => {
    const address = await GoogleGeoCode(data?.latitude, data?.longitude);

    const formData = {
      company_id: data?.company?.value,
      user_id: loggedInUser?.user_id,
      geo_location: {
        lat: data?.latitude,
        lng: data?.longitude,
        city: address?.city || '',
        state: address?.state || '',
        address: address?.address || '',
        zip: '',
      },
      notes: '',
      name: data?.name,
      is_jobsite: true,
      location_contact_name: data.location_contact_name,
      location_contact: data.location_contact,
      customer_contact_name: data.customer_contact_name,
      customer_contact: data.customer_contact,
    };
    addLocation.mutate(formData);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setCenter({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
        setPosition({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
      },
      (err) => {
        toast({
          title: strings.enable_your_location,
          status: 'info',
          isClosable: true,
        });
      }
    );
  });

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.locations.list}>
            {strings.location_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.locations.create}>
            {strings.add_new_location}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.add_new_location}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addLocation.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.location_creation_failed}
                </Alert>
              )}
              <LocationForm center={center} position={position} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addLocation.isLoading}>
                  {strings.add_location}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateLocation;
