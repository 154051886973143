import {
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  useToast,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import HazardResource from 'api/hazard';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  hazardId: any;
}

const HazardAssignForm: React.FC<Props> = (props) => {
  const { hazardId } = props;
  const toast = useToast();
  const hazardAPI = new HazardResource();

  const companyAPI = new CompanyResource();

  const companyQuery = useQuery(
    [`company-list`],
    () =>
      companyAPI
        .itemList({ is_active: true, is_approved: true, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const companyList = companyQuery?.data?.results;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const assignToCompany = useMutation((data: any) =>
    hazardAPI.assignToCompany(hazardId, data.company)
  );

  const handleAssignToCompany = async (data: any) => {
    assignToCompany.mutate(data, {
      onSuccess: () => {
        toast({
          title: strings.hazard_assign_success_msg,
          status: 'success',
          isClosable: true,
        });
      },
    });
  };
  const TrailingDash = {
    fontWeight: 'normal',
    position: 'relative',
    paddingRight: '4',
    paddingLeft: '4',
    textTransform: 'capitalize',
    _after: {
      content: `""`,
      position: 'absolute',
      top: '1',
      right: '0',
      width: '1px',
      height: '25px',
      bg: 'gray.300',
    },
  };

  return (
    <>
      <form>
        <Grid
          gap="4"
          templateColumns="repeat(1, 1fr)"
          w="100%"
          sx={TrailingDash}>
          <GridItem>
            <FormControl w="100%">
              <HStack>
                <FormLabel>{strings.assign_to}</FormLabel>
                <ReactSelect
                  onChange={(e: any) => handleAssignToCompany(e.value)}
                  id="company"
                  placeholder={strings.select_company}
                  options={companyOptions}
                  styles={reactSelectStyles}
                  isSearchable
                />
              </HStack>
            </FormControl>
          </GridItem>
        </Grid>
      </form>
    </>
  );
};
export default HazardAssignForm;
