import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VisuallyHidden,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { setLoggedInUser } from 'actions/data/auth';
import { getAuthUser, updateAuthUser } from 'api/auth';
import AlertBox from 'components/alert/AlertBox';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import { useMutation, useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getFullName } from 'utils/esg';
import { validateFile, validateFileSize } from 'utils/validate';

const MyProfile: React.FC = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token } = useSelector(
    (state: any) => ({
      token: state?.data?.auth?.user?.token,
    }),
    shallowEqual
  );

  const headers = {
    Authorization: 'token ' + token,
  };

  /**
   * Referencing visually hidden input to upload button.
   */
  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * Loading state for upload and delete.
   */
  const [isLoading, setIsLoading] = useState<string | null>();
  const userQuery = useQuery<any>(
    `user-auth`,
    () => getAuthUser(headers).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  /**
   * Dispatch the current changes to the store.
   */

  const dispatch = useDispatch();

  /**
   * delete files and refetch the userQuery.
   */
  const clearFile = async () => {
    setIsLoading(() => 'delete');
    try {
    } catch (error) {
      return;
    } finally {
      setIsLoading(() => null);
      onClose();
    }
  };

  const listItemStyles = {
    d: 'flex',
    mb: '4',
  };

  const titleStyles = {
    minW: '80',
    color: 'gray.900',
    fontWeight: 'bold',
  };

  const descriptionStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  const updateUser = useMutation(
    (data: any) => {
      return updateAuthUser(data, headers);
    },
    {
      onSuccess: async (res: any) => {
        const authData = await getAuthUser(headers);
        loggedInUser.user_information.profile_image =
          authData?.data?.data?.image;
        dispatch(setLoggedInUser(loggedInUser));
        userQuery.refetch();
      },
    }
  );

  /**
   * Event handlers for file uploading.
   */
  const handleUploadButton = () => {
    fileInputRef?.current?.click();
  };

  /**
   * uploads file on bucket and refetches userQuery on success.
   */

  const handleProfileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    if (!e.target.files) return;
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    /**
     * validates the file type and size.
     */
    const validateFileType: boolean = validateFile(file?.type);
    const validateSize: boolean = validateFileSize(file?.size);

    if (validateFileType && validateSize) {
      setIsLoading(() => 'upload');
      try {
        await updateUser.mutate(formData);
      } catch (error) {
        return;
      } finally {
        setIsLoading(() => null);
      }
    }
    if (!validateFileType) {
      toast({
        title: strings.invalid_file_type,
        description: strings.file_must_be_an_image_type,
        status: 'error',
        isClosable: true,
      });
    }
    /**
     * shows toast only if file type is valid.
     */
    if (!validateSize && validateFileType) {
      toast({
        title: strings.invalid_file_size,
        description: strings.file_size_less_5MB,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getBadges = () => {
    if (userQuery?.data?.user_badges.length > 0) {
      return (
        <>
          <Wrap>
            {userQuery?.data?.user_badges.map((res: any) => (
              <WrapItem key={res.id}>
                <Avatar name={res.badges} src={res.image} title={res.badges} />
              </WrapItem>
            ))}
          </Wrap>
        </>
      );
    }
    return '-';
  };

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="6">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.profile.myProfile}>
              {strings.my_profile}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.my_profile}</Heading>
          <RouterLink to={routes.profile.edit}>
            <Button size="sm" colorScheme="primary">
              {strings.edit_profile}
            </Button>
          </RouterLink>
        </Flex>
        <Stack direction="row" spacing="4">
          <Box bg="white" shadow="box" p={['3', '6']} rounded="sm" maxH="180px">
            <Avatar size="2xl" src={userQuery?.data?.image} rounded="full">
              <IconButton
                rounded="full"
                position="absolute"
                _hover={{ color: 'green.700' }}
                right={-4}
                top={-3}
                size="sm"
                aria-label={strings.upload_profile}
                icon={<BiImageAdd />}
                onClick={handleUploadButton}
              />
              <VisuallyHidden>
                <Input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleProfileUpload}
                />
              </VisuallyHidden>
            </Avatar>
            {/**
             * Alert for file deletion.
             */}
            <AlertBox
              isOpen={isOpen}
              onClose={onClose}
              isLoading={isLoading === 'delete'}
              onDelete={clearFile}
              alertDialogHeader={strings.clear_profile_image}
              alertDialogBody={strings.undo_action_afterwards}
            />
            {/**
             * Modal is for showing image upload progress
             */}
            <Modal
              isOpen={isLoading === 'upload'}
              onClose={() => setIsLoading(() => null)}
              isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{strings.uploading_profile}</ModalHeader>
                <ModalBody>
                  <Skeleton height="20px" />
                </ModalBody>
              </ModalContent>
            </Modal>
            ;
          </Box>
          <Stack direction="column" spacing="4" flex="1">
            <Box bg="white" shadow="box" p={['3', '6']} rounded="sm">
              <List>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.username}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.username}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.name}</Text>
                  <Text sx={descriptionStyles}>
                    {getFullName(userQuery?.data)}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.email}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.email || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.cell_phone}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.phone || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.work_email}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.work_email || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.work_phone}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.work_phone || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.company}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.company?.name}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.allergies}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.allergies || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.blood_type}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.blood_type || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.certification_notes}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.certification_notes || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.emergency_contact_name}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.emergency_name || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>
                    {strings.emergency_contact_phone}
                  </Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.emergency_contact_phone || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.about}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery?.data?.about || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.badges}</Text>
                  <Text sx={descriptionStyles}>{getBadges()}</Text>
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default MyProfile;
