import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import { PPESchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  ppeData?: PPESchema;
}

const PPEForm: React.FC<Props> = (props) => {
  const { ppeData } = props;
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '', // for company List
  });
  const [operatorName, setOperatorName] = useState('');
  
  useEffect(() => {
    if (!ppeData) return;
    setValue('is_mandatory', ppeData.is_mandatory ? true : false);
    if (ppeData?.company_details?.name){
      const company = {
        label: ppeData?.company_details.name,
        value: ppeData?.company_details.id,
      }
      setValue('company', company);
    }
    
  }, [ppeData, setValue]);

  const companyAPI = new CompanyResource();

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [
      `company-list`,
      { search: filterParams.search, is_active: true, is_approved: true },
    ],
    () =>
      companyAPI
        .allList({ search: filterParams.search, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const handleFilterOperator = (operator: any) => {
    if (operator.value) {
      setOperatorName(operator.label);
    }
  };

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      search: value,
    }));
  };
  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.name}</FormLabel>
              <Input
                {...register('name', {
                  required: strings.name,
                })}
                type="text"
                defaultValue={ppeData?.name}
                placeholder={strings.name}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.company} isRequired>
              <FormLabel>{strings.company}</FormLabel>
              <Controller
                control={control}
                name="company"
                rules={{
                  required: strings.select_company,
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="company"
                    value={field.value ? field.value : null}
                    placeholder={strings.select_company}
                    isLoading={isCompanyLoading}
                    options={companyOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                    onInputChange={handleCompanyChange}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.company && errors?.company?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.is_mandatory}>
              <FormLabel>{strings.is_mandatory}</FormLabel>
              <Controller
                name="is_mandatory"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    isChecked={field.value}>
                    {strings.yes}
                  </Checkbox>
                )}
              />
              <FormErrorMessage>
                {errors?.is_mandatory && errors?.is_mandatory?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default PPEForm;
