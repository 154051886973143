import Resource from 'api/resource';
import http from 'utils/http';

class UserResource extends Resource {
  constructor() {
    super('add-user/');
  }

  changeStatus(data?: any) {
    return http({
      url: 'toggle-is-active/',
      method: 'post',
      data: data,
    });
  }

  employeeReport(query?: any) {
    return http({
      url: 'jsa-employee-report/',
      method: 'get',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }

  crewList(id: any, query?: any) {
    return http({
      url: `jsa/members/${id}/`,
      method: 'get',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }
}

export { UserResource as default };
