import { FormControl, FormLabel } from '@chakra-ui/form-control';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  FormErrorMessage,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  ScaleFade,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft, BiEnvelope, BiLock } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const Register: React.FC = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm();
  const [isError, setIsError] = useState(false);

  const onSubmit = () => {};

  return (
    <Center h="100vh">
      <Stack direction="column" spacing="12">
        <Stack spacing="4">
          <Center>
            <Image w="16" src={Logo} />
          </Center>
          <Stack>
            <Text
              align="center"
              fontSize="lg"
              fontWeight="medium"
              color={'green.600'}>
              ESG OnSite
            </Text>
          </Stack>
        </Stack>
        <Box bg="white" p="14" shadow="box" rounded="sm">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="8">
              <Stack direction="column" spacing="4">
                <Heading as="h1" size="xl">
                  {strings.register}
                </Heading>
                <Text color="gray.500">
                  {strings.enter_for_register_into} ESG OnSite
                </Text>
                {isError && (
                  <ScaleFade in={isError}>
                    <Alert status="error">
                      <AlertIcon />
                      {strings.something_is_wrong}
                    </Alert>
                  </ScaleFade>
                )}
              </Stack>
              <Stack direction="column" spacing="5">
                <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                  <FormLabel>{strings.email}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiEnvelope} />
                      }
                    />
                    <Input
                      id="email"
                      type="email"
                      placeholder={strings.your_email_address}
                      {...register('email', {
                        required: strings.email_address_required,
                        validate: (value) => 'valid_email_address',
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.email && errors.email?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                  <FormLabel>{strings.password}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiLock} />
                      }
                    />
                    <Input
                      id="password"
                      type="password"
                      placeholder={strings.password}
                      {...register('password', {
                        required: strings.password_required,
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>
                    {errors.password && errors.password?.message}
                  </FormErrorMessage>
                </FormControl>
                <VStack spacing="6">
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={formState.isSubmitting}>
                    {strings.register}
                  </Button>
                  <Link to={routes.auth.login}>
                    <Button
                      variant="link"
                      color="gray.400"
                      leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                      {strings.back_to_login}
                    </Button>
                  </Link>
                </VStack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Center>
  );
};

export default Register;
