import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PermissionResource from 'api/permissions';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import RolePermissionsListItem from 'components/rolePermissions/RolePermissionsListItem';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

const RolePermissionList: React.FC = () => {
  const permissionRolesAPI = new PermissionResource();

  const { data: permissionRolesList, isLoading: isPermissionListLoading } =
    useQuery('permission-role-list', () =>
      permissionRolesAPI.list().then((res) => res?.data?.roles)
    );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.settings}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.companies.list}>
              {strings.role_and_permissions}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.role_and_permission_list}</Heading>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.permission}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isPermissionListLoading &&
                  permissionRolesList?.map(
                    (permissionRoleData: any, index: number) => {
                      if (
                        permissionRoleData.name.toLowerCase() !==
                          'superadmin' &&
                        permissionRoleData.name.toLowerCase() !== 'user'
                      )
                        return (
                          <RolePermissionsListItem
                            permissionRoleData={permissionRoleData}
                            key={permissionRoleData.id}
                            index={index}
                          />
                        );
                    }
                  )}
                {isPermissionListLoading && (
                  <TableSkeletonLoader rows={10} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  );
};

export default RolePermissionList;
