import UserProfile from 'components/user/UserProfile';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import AnnouncementList from 'pages/dashboard/announcement/AnnouncementList';
import CreateAnnouncement from 'pages/dashboard/announcement/CreateAnnouncement';
import EditAnnouncement from 'pages/dashboard/announcement/EditAnnouncement';
import ViewAnnouncement from 'pages/dashboard/announcement/ViewAnnouncement';
import Chat from 'pages/dashboard/chat/Chat';
import CompanyList from 'pages/dashboard/companies/CompanyList';
import CreateCompany from 'pages/dashboard/companies/CreateCompany';
import EditCompany from 'pages/dashboard/companies/EditCompany';
import ViewCompany from 'pages/dashboard/companies/ViewCompany';
import FAQList from 'pages/dashboard/faq/FAQList';
import CreateFieldReporting from 'pages/dashboard/fieldReporting/CreateFieldReporting';
import FieldReportingList from 'pages/dashboard/fieldReporting/FieldReportingList';
import ViewFieldReporting from 'pages/dashboard/fieldReporting/ViewFieldReporting';
import CreateFieldType from 'pages/dashboard/fieldTypes/CreateFieldType';
import EditFieldType from 'pages/dashboard/fieldTypes/EditFieldType';
import FieldTypeList from 'pages/dashboard/fieldTypes/FieldTypeList';
import ViewFieldType from 'pages/dashboard/fieldTypes/ViewFieldType';
import CreateHazard from 'pages/dashboard/hazards/CreateHazard';
import EditHazard from 'pages/dashboard/hazards/EditHazard';
import HazardList from 'pages/dashboard/hazards/HazardList';
import ViewHazard from 'pages/dashboard/hazards/ViewHazard';
import CreateHazardType from 'pages/dashboard/hazardTypes/CreateHazardType';
import EditHazardType from 'pages/dashboard/hazardTypes/EditHazardType';
import HazardListType from 'pages/dashboard/hazardTypes/HazardTypeList';
import ViewHazardType from 'pages/dashboard/hazardTypes/ViewHazardType';
import CreateLocation from 'pages/dashboard/locations/CreateLocation';
import LocationList from 'pages/dashboard/locations/LocationList';
import ViewLocation from 'pages/dashboard/locations/ViewLocation';
import CreateWorkType from 'pages/dashboard/locations/WorkType/CreateWorkType';
import EditBulkWorkTypeStep from 'pages/dashboard/locations/WorkType/EditBulkWorkTypeStep';
import EditWorkType from 'pages/dashboard/locations/WorkType/EditWorkType';
import ViewWorkType from 'pages/dashboard/locations/WorkType/ViewWorkType';
import WorkTypeList from 'pages/dashboard/locations/WorkType/WorkTypeList';
import MapFeaturePage from 'pages/dashboard/mapFeatures/MapFeaturePage';
import CreatePPE from 'pages/dashboard/ppe/CreatePPE';
import EditPPE from 'pages/dashboard/ppe/EditPPE';
import PPEList from 'pages/dashboard/ppe/PPEList';
import ViewPPE from 'pages/dashboard/ppe/ViewPPE';
import { EditProfile, MyProfile } from 'pages/dashboard/Profile';
import CompanyListReport from 'pages/dashboard/reports/CompanyListReport';
import EmployeeListReport from 'pages/dashboard/reports/EmployeeListReport';
import JobSummariesListReport from 'pages/dashboard/reports/JobSummariesListReport';
import JobSummariesViewReport from 'pages/dashboard/reports/JobSummariesViewReport';
import JSAListReport from 'pages/dashboard/reports/JSAListReport';
import JSAViewReport from 'pages/dashboard/reports/JSAViewReport';
import UserListReport from 'pages/dashboard/reports/UserListReport';
import VendorListReport from 'pages/dashboard/reports/VendorListReport';
import EditRolePermissions from 'pages/dashboard/settings/rolepermissions/EditRolePermissions';
import RolePermissionList from 'pages/dashboard/settings/rolepermissions/RolePermissionsList';
import ViewRolePermissions from 'pages/dashboard/settings/rolepermissions/ViewRolePermissions';
import CreateSOPS from 'pages/dashboard/sops/CreateSOPS';
import EditSOPS from 'pages/dashboard/sops/EditSOPS';
import SOPSList from 'pages/dashboard/sops/SOPSList';
import ViewSOPS from 'pages/dashboard/sops/ViewSOPS';
import { SplashScreen } from 'pages/dashboard/splashScreen';
import CreateUserFieldReport from 'pages/dashboard/userFieldReport/CreateUserFieldReport';
import UserFieldReportList from 'pages/dashboard/userFieldReport/UserFieldReportList';
import ViewUserFieldReport from 'pages/dashboard/userFieldReport/ViewUserFieldReport';
import CreateUser from 'pages/dashboard/users/CreateUser';
import EditUser from 'pages/dashboard/users/EditUser';
import UserDeactivateRequestList from 'pages/dashboard/users/UserDeactivateList';
import UserList from 'pages/dashboard/users/UserList';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RoleBasedRouting from './RoleBasedRouting';

const PrivateRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.dashboard}
        component={() => <Redirect to={routes.splash} />}
      />
      <Route exact path={routes.splash} component={SplashScreen} />
      <Route exact path={routes.profile.myProfile} component={MyProfile} />
      <Route exact path={routes.profile.edit} component={EditProfile} />
      {/* User Routes */}
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.list}
        component={UserList}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.create}
        component={CreateUser}
        allowedPermissions={[
          PermissionRequest['read:user'],
          PermissionRequest['manage:user'],
        ]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.edit}
        component={EditUser}
        allowedPermissions={[
          PermissionRequest['read:user'],
          PermissionRequest['manage:user'],
        ]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.profile}
        component={UserProfile}
        allowedPermissions={[PermissionRequest['manage:user']]}
      />
      {/* Company Routes */}
      <RoleBasedRouting
        serviceName="company-service"
        exact
        path={routes.companies.list}
        component={CompanyList}
        allowedPermissions={[PermissionRequest['read:company']]}
      />
      <RoleBasedRouting
        serviceName="company-service"
        exact
        path={routes.companies.create}
        component={CreateCompany}
        allowedPermissions={[
          PermissionRequest['read:company'],
          PermissionRequest['manage:company'],
        ]}
      />
      <RoleBasedRouting
        serviceName="company-service"
        exact
        path={routes.companies.edit}
        component={EditCompany}
        allowedPermissions={[
          PermissionRequest['read:company'],
          PermissionRequest['manage:company'],
        ]}
      />
      <RoleBasedRouting
        serviceName="company-service"
        exact
        path={routes.companies.view}
        component={ViewCompany}
        allowedPermissions={[PermissionRequest['read:company']]}
      />
      {/* Location Routes */}
      <RoleBasedRouting
        serviceName="location-service"
        exact
        path={routes.locations.list}
        component={LocationList}
        allowedPermissions={[PermissionRequest['read:location']]}
      />
      <RoleBasedRouting
        serviceName="location-service"
        exact
        path={routes.locations.create}
        component={CreateLocation}
        allowedPermissions={[
          PermissionRequest['manage:location'],
          PermissionRequest['read:location'],
        ]}
      />
      <RoleBasedRouting
        serviceName="location-service"
        exact
        path={routes.locations.view}
        component={ViewLocation}
        allowedPermissions={[PermissionRequest['read:location']]}
      />

      {/* Work Type Routes */}
      <RoleBasedRouting
        serviceName="work-type-service"
        exact
        path={routes.workType.list}
        component={WorkTypeList}
        allowedPermissions={[PermissionRequest['read:work-type']]}
      />
      <RoleBasedRouting
        serviceName="work-type-service"
        exact
        path={routes.workType.create}
        component={CreateWorkType}
        allowedPermissions={[
          PermissionRequest['read:work-type'],
          PermissionRequest['manage:work-type'],
        ]}
      />
      <RoleBasedRouting
        serviceName="work-type-service"
        exact
        path={routes.workType.edit}
        component={EditWorkType}
        allowedPermissions={[
          PermissionRequest['read:work-type'],
          PermissionRequest['manage:work-type'],
        ]}
      />
      <RoleBasedRouting
        serviceName="work-type-service"
        exact
        path={routes.workType.editStep}
        component={EditBulkWorkTypeStep}
        allowedPermissions={[
          PermissionRequest['read:work-type'],
          PermissionRequest['manage:work-type'],
        ]}
      />
      <RoleBasedRouting
        serviceName="work-type-service"
        exact
        path={routes.workType.view}
        component={ViewWorkType}
        allowedPermissions={[PermissionRequest['read:work-type']]}
      />
      {/* PPE Routes */}
      <RoleBasedRouting
        serviceName="ppe-service"
        exact
        path={routes.ppe.list}
        component={PPEList}
        allowedPermissions={[PermissionRequest['read:ppe']]}
      />
      <RoleBasedRouting
        serviceName="ppe-service"
        exact
        path={routes.ppe.create}
        component={CreatePPE}
        allowedPermissions={[
          PermissionRequest['read:ppe'],
          PermissionRequest['manage:ppe'],
        ]}
      />
      <RoleBasedRouting
        serviceName="ppe-service"
        exact
        path={routes.ppe.edit}
        component={EditPPE}
        allowedPermissions={[
          PermissionRequest['read:ppe'],
          PermissionRequest['manage:ppe'],
        ]}
      />
      <RoleBasedRouting
        serviceName="ppe-service"
        exact
        path={routes.ppe.view}
        component={ViewPPE}
        allowedPermissions={[PermissionRequest['read:ppe']]}
      />
      {/* Chat System Routes */}
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chats.list}
        component={Chat}
        allowedPermissions={[PermissionRequest['manage:chat']]}
      />
      {/* SOPS Routes */}
      <RoleBasedRouting
        serviceName="sop-service"
        exact
        path={routes.sops.list}
        component={SOPSList}
        allowedPermissions={[PermissionRequest['read:sop']]}
      />
      <RoleBasedRouting
        serviceName="sop-service"
        exact
        path={routes.sops.create}
        component={CreateSOPS}
        allowedPermissions={[
          PermissionRequest['manage:sop'],
          PermissionRequest['read:sop'],
        ]}
      />
      <RoleBasedRouting
        serviceName="sop-service"
        exact
        path={routes.sops.edit}
        component={EditSOPS}
        allowedPermissions={[
          PermissionRequest['manage:sop'],
          PermissionRequest['read:sop'],
        ]}
      />
      <RoleBasedRouting
        serviceName="sop-service"
        exact
        path={routes.sops.view}
        component={ViewSOPS}
        allowedPermissions={[PermissionRequest['read:sop']]}
      />
      {/* Hazards Routes */}
      <RoleBasedRouting
        serviceName="hazard-service"
        exact
        path={routes.hazards.hazard.create}
        component={CreateHazard}
        allowedPermissions={[PermissionRequest['manage:hazard']]}
      />
      <RoleBasedRouting
        serviceName="hazard-service"
        exact
        path={routes.hazards.hazard.list}
        component={HazardList}
        allowedPermissions={[PermissionRequest['read:hazard']]}
      />
      <RoleBasedRouting
        serviceName="hazard-service"
        exact
        path={routes.hazards.hazard.edit}
        component={EditHazard}
        allowedPermissions={[
          PermissionRequest['read:hazard'],
          // ['manage:hazard'],
        ]}
      />
      <RoleBasedRouting
        serviceName="hazard-service"
        exact
        path={routes.hazards.hazard.view}
        component={ViewHazard}
        allowedPermissions={[PermissionRequest['read:hazard']]}
      />
      {/* Hazard Types Routes */}
      <RoleBasedRouting
        serviceName="hazard-type-service"
        exact
        path={routes.hazards.hazardType.create}
        component={CreateHazardType}
        // allowedPermissions={[PermissionRequest['manage:hazard']]}
      />
      <RoleBasedRouting
        serviceName="hazard-type-service"
        exact
        path={routes.hazards.hazardType.list}
        component={HazardListType}
        // allowedPermissions={[PermissionRequest['read:hazard']]}
      />
      <RoleBasedRouting
        serviceName="hazard-type-service"
        exact
        path={routes.hazards.hazardType.edit}
        component={EditHazardType}
        // allowedPermissions={[
        //   PermissionRequest['read:hazard'],
        //   // ['manage:hazard'],
        // ]}
      />
      <RoleBasedRouting
        serviceName="hazard-type-service"
        exact
        path={routes.hazards.hazardType.view}
        component={ViewHazardType}
        // allowedPermissions={[PermissionRequest['read:hazard']]}
      />
      {/* Announcement Routes */}
      <RoleBasedRouting
        serviceName="announcement-service"
        exact
        path={routes.announcements.list}
        component={AnnouncementList}
        allowedPermissions={[PermissionRequest['read:announcement']]}
      />
      <RoleBasedRouting
        serviceName="announcement-service"
        exact
        path={routes.announcements.create}
        component={CreateAnnouncement}
        allowedPermissions={[PermissionRequest['manage:announcement']]}
      />
      <RoleBasedRouting
        serviceName="announcement-service"
        exact
        path={routes.announcements.edit}
        component={EditAnnouncement}
        allowedPermissions={[
          PermissionRequest['manage:announcement'],
          PermissionRequest['read:announcement'],
        ]}
      />
      <RoleBasedRouting
        serviceName="announcement-service"
        exact
        path={routes.announcements.view}
        component={ViewAnnouncement}
        allowedPermissions={[PermissionRequest['read:announcement']]}
      />
      {/* Reports Routes */}
      <RoleBasedRouting
        serviceName="employee-report-service"
        exact
        path={routes.reports.employee}
        component={EmployeeListReport}
        allowedPermissions={[PermissionRequest['read:employee-report']]}
      />
      <Route exact path={routes.reports.user} component={UserListReport} />
      <Route exact path={routes.reports.vendor} component={VendorListReport} />
      <RoleBasedRouting
        serviceName="company-report-service"
        exact
        path={routes.reports.company}
        component={CompanyListReport}
        allowedPermissions={[PermissionRequest['read:company-report']]}
      />

      <RoleBasedRouting
        serviceName="jsa-report-service"
        exact
        path={routes.reports.jsa.list}
        component={JSAListReport}
        allowedPermissions={[PermissionRequest['read:jsa-report']]}
      />
      <RoleBasedRouting
        serviceName="jsa-report-service"
        exact
        path={routes.reports.jsa.listByCompany}
        component={JSAListReport}
        allowedPermissions={[PermissionRequest['read:jsa-report']]}
      />
      <RoleBasedRouting
        serviceName="jsa-report-service"
        exact
        path={routes.reports.jsa.view}
        component={JSAViewReport}
        allowedPermissions={[PermissionRequest['read:jsa-report']]}
      />

      <RoleBasedRouting
        serviceName="jsa-summaries-report-service"
        exact
        path={routes.reports.jobSummaries.list}
        component={JobSummariesListReport}
        allowedPermissions={[PermissionRequest['read:jsa-summary-report']]}
      />
      <RoleBasedRouting
        serviceName="jsa-summaries-report-service"
        exact
        path={routes.reports.jobSummaries.view}
        component={JobSummariesViewReport}
        allowedPermissions={[PermissionRequest['read:jsa-summary-report']]}
      />
      {/* Map Features Routes */}
      <RoleBasedRouting
        serviceName="map-feature-report-service"
        exact
        path={routes.mapFeatures.view}
        component={MapFeaturePage}
        allowedPermissions={[
          PermissionRequest['read:map-feature'],
          PermissionRequest['read:jsa-report'],
          PermissionRequest['read:jsa-summary-report'],
        ]}
      />
      {/* Deactivate User Request */}
      <RoleBasedRouting
        serviceName="deactivate-request-service"
        exact
        path={routes.users.deactivateRequest.list}
        component={UserDeactivateRequestList}
      />

      {/* Settings Routes */}
      <RoleBasedRouting
        serviceName="settings"
        exact
        path={routes.settings.rolePermissions.list}
        component={RolePermissionList}
      />
      <RoleBasedRouting
        serviceName="settings"
        exact
        path={routes.settings.rolePermissions.view}
        component={ViewRolePermissions}
      />
      <RoleBasedRouting
        serviceName="settings"
        exact
        path={routes.settings.rolePermissions.edit}
        component={EditRolePermissions}
      />
      {/* FAQs Routes */}
      <RoleBasedRouting
        serviceName="faqs"
        exact
        path={routes.faqs.list}
        allowedPermissions={[PermissionRequest['manage:data-report']]}
        component={FAQList}
      />
      {/* Field Report */}
      <RoleBasedRouting
        serviceName="field-reporting"
        exact
        path={routes.fieldReporting.environmentTracker.list}
        allowedPermissions={[PermissionRequest['manage:field-report']]}
        component={FieldReportingList}
      />
      <RoleBasedRouting
        serviceName="field-reporting"
        exact
        path={routes.fieldReporting.environmentTracker.view}
        allowedPermissions={[PermissionRequest['read:field-report']]}
        component={ViewFieldReporting}
      />
      <RoleBasedRouting
        serviceName="field-reporting"
        exact
        path={routes.fieldReporting.environmentTracker.create}
        allowedPermissions={[PermissionRequest['manage:field-report']]}
        component={CreateFieldReporting}
      />
      {/* Field Type Routes */}
      <RoleBasedRouting
        serviceName="field-type-service"
        exact
        path={routes.fieldReporting.fieldType.list}
        component={FieldTypeList}
        allowedPermissions={[PermissionRequest['read:field-type']]}
      />

      <Route
        exact
        path={routes.fieldReporting.environmentTracker.create_field_type}
        component={CreateUserFieldReport}
      />

      <RoleBasedRouting
        serviceName="field-type-service"
        exact
        path={routes.fieldReporting.fieldType.create}
        component={CreateFieldType}
        allowedPermissions={[PermissionRequest['manage:field-type']]}
      />
      <RoleBasedRouting
        serviceName="field-type-service"
        exact
        path={routes.fieldReporting.fieldType.edit}
        component={EditFieldType}
        allowedPermissions={[
          PermissionRequest['manage:field-type'],
          PermissionRequest['read:field-type'],
        ]}
      />
      <RoleBasedRouting
        serviceName="field-type-service"
        exact
        path={routes.fieldReporting.fieldType.view}
        component={ViewFieldType}
        allowedPermissions={[PermissionRequest['read:field-type']]}
      />
      {/* User Field Report Routes */}
      <RoleBasedRouting
        serviceName="user-field-report-service"
        exact
        path={routes.fieldReporting.userFieldReport.list}
        component={UserFieldReportList}
        allowedPermissions={[PermissionRequest['read:user-field-report']]}
      />

      {/* <RoleBasedRouting
        serviceName="user-field-report-service"
        exact
        path={routes.fieldReporting.userFieldReport.create}
        component={CreateUserFieldReport}
        allowedPermissions={[PermissionRequest['manage:user-field-report']]}
      /> */}

      {/* <RoleBasedRouting
        serviceName="user-field-report-service"
        exact
        path={routes.fieldReporting.userFieldReport.edit}
        component={EditUserFieldReport}
        allowedPermissions={[
          PermissionRequest['manage:user-field-report'],
          PermissionRequest['read:user-field-report'],
        ]}
      /> */}
      <RoleBasedRouting
        serviceName="user-field-report-service"
        exact
        path={routes.fieldReporting.userFieldReport.view}
        component={ViewUserFieldReport}
        allowedPermissions={[PermissionRequest['read:user-field-report']]}
      />
    </Switch>
  );
};

export default PrivateRouter;
