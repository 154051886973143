import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTagTitle from 'components/common/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { getFullName } from 'utils/esg';
import history from 'utils/history';

import ApproveUserResource from "api/approve_user";

const UserProfile: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const userAPI = new UserResource();
  const userApproveAPI = new ApproveUserResource();

  const userQuery = useQuery<any>([`user${id}`, id], () =>
    userAPI.get(id).then((res) => res?.data)
  );

  const listItemStyles = {
    d: 'flex',
    mb: '4',
  };

  const titleStyles = {
    minW: '40',
    color: 'gray.900',
    fontWeight: 'bold',
  };

  const descriptionStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  const approveUser = useMutation((data: any) => userApproveAPI.update(id, data));

  const handleApprove = () => {
    const data = userQuery?.data;
    // data.role = data.role.id;
    data.is_active = true;
    approveUser.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: strings.user_approved,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userList');
        history.push(routes.users.list);
      },
    });
  };

  if (userQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.users}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {getFullName(userQuery?.data)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.user_profile}
          </Heading>
          <Stack direction="row" spacing="6">
            {!userQuery?.data?.is_active && (
              <AccessControl
                allowedPermissions={[PermissionRequest['approve:user']]}
                renderNoAccess={(data: any) => data}>
                <Button
                  size="sm"
                  colorScheme="primary"
                  type="button"
                  onClick={() => handleApprove()}>
                  {strings.approve_user}
                </Button>
              </AccessControl>
            )}
            <Button
              size="sm"
              colorScheme="primary"
              type="button"
              onClick={() =>
                history.push(routes.users.edit.replace(':id', id))
              }>
              {strings.edit_user}
            </Button>
          </Stack>
        </Flex>
        <Stack direction={['column', 'row']} spacing="4">
          <Box bg="white" shadow="box" p={['3', '8']} rounded="sm" maxH="250px">
            <Avatar
              boxSize="180px"
              src={userQuery?.data?.profile_image}
              rounded="full"
              boxShadow="sm"
              bg="primary.100"
              p="2"
            />
          </Box>

          <Stack
            bg="white"
            shadow="box"
            p={['3', '6']}
            rounded="sm"
            direction="column"
            spacing="4"
            flex="1"
            overflow="auto">
            <Box>
              <List>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.name}</Text>
                  <CustomTagTitle
                    type="text"
                    status={userQuery?.data?.is_active}
                    name={getFullName(userQuery?.data) || '-'}
                  />
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.username}</Text>
                  <Text sx={descriptionStyles}>
                    <Text sx={descriptionStyles}>
                      {userQuery.data?.username || '-'}
                    </Text>
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.company}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.company_details?.name || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.email}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.email || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.work_email}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.work_email || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.contact}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.phone || '-'}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.role}</Text>
                  <Text sx={descriptionStyles}>
                    {strings.getString(
                      userQuery?.data?.role?.name
                        .toLowerCase()
                        .split(' ')
                        .join('_')
                    ) || '-'}
                  </Text>
                </ListItem>
                {/* {userQuery.data?.is_newuser && ( */}
                {/* <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>Verification</Text>
                  <Text sx={descriptionStyles}>
                    Resend Verification Link
                  </Text>
                  <Button
                      colorScheme="primary"
                      variant="link"
                      fontSize="sm"
                      ml={6}
                      type="button"
                      onClick={handleResendVerificationEmail}
                      disabled={resendVerificationEmailMutation.isSuccess}
                      isLoading={resendVerificationEmailMutation.isLoading}>
                      {resendVerificationEmailMutation.isSuccess
                        ? "Verification email sent"
                        : Resend verification email}
                    </Button>
                </ListItem> */}
                {/* )} */}
              </List>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default UserProfile;
