const PermissionRequest = {
  /**
   * Permissions for User
   */
  'read:user': 'view_users',
  'manage:user': 'create_users',
  'approve:user': 'create_users',
  /**
   * Permissions for Company
   */
  'read:company': 'view_company',
  'manage:company': 'create_company',
  'approve:company': 'approve_company',
  /**
   * Permissions for Location
   */
  'read:location': 'view_jobsite',
  'manage:location': 'create_jobsite',
  /**
   * Permissions for SOP
   */
  'read:sop': 'view_sop',
  'manage:sop': 'create_sop',
  /**
   * Permission for PPE
   */
  'read:ppe': 'view_ppe',
  'manage:ppe': 'create_ppe',
  /**
   * Permission for Work Type
   */
  'read:work-type': 'view_work_type',
  'manage:work-type': 'create_work_type',
  /**
   * Permission for Hazard
   */
  'read:hazard': 'view_hazards',
  'manage:hazard': 'create_hazards',
  'assign:hazard': 'assign_hazard',
  /**
   * Permission for Announcement
   */
  'read:announcement': 'view_posts',
  'manage:announcement': 'create_posts',
  /**
   * Permission for Reports
   */
  'read:company-report': 'company-report',
  'read:employee-report': 'employee_report',
  'read:jsa-report': 'jsa_report',
  'read:jsa-summary-report': 'jsa_summary_report',
  /**
   * Permission for Map Feature
   */
  'read:map-feature': 'map_report',
  /**
   * Permission for Chat Feature
   */
  'manage:chat': 'chat',
  /**
   * Permission for Data report
   */
  'manage:data-report': 'data-report',

  /**
   * Permission for User Deactivate Account
   */
  'manage:deactive-user-request': 'deactive_user_request',

  /**
   * Permission for Field Report
   */
  'read:field-report': 'view_field_report',
  'manage:field-report': 'create_field_report',
  'read:field-type': 'view_field_type',
  'manage:field-type': 'create_field_type',
  'read:user-field-report': 'view_user_field_report',
  'manage:user-field-report': 'create_user_field_report',
};

export default PermissionRequest;
