const en = {
  /*login Credential*/
  login: 'Login',
  log_in: 'Log In',
  auth_message:
    'You are not authorized to login. Please contact administrator.',
  invalid_login_message: 'Username or password did not match.',
  login_header: 'Enter your credentials to login into ESG OnSite',
  username: 'Username',
  username_required: 'Username is required',
  password: 'Password',
  password_required: 'Password is required',
  forgot_password: 'Forgot Password?',
  /**forgot password */
  forgot_password_success_message: 'We have emailed your password reset link!',
  forgot_password_header:
    'Please enter the email you use to sign in to your account.',
  email_address_not_found: 'Email address not found.',
  email: 'Email',
  your_email_address: 'Your Email Address',
  email_address_required: 'Email address is required',
  valid_email_msg: 'Please Provide valid Email address',
  request_password_reset: 'Request Password Reset',
  back_to_login: 'Back to Login',
  /**register */
  register: 'Register',
  enter_for_register_into: 'Enter for register into',
  something_is_wrong: 'Something is wrong. Please contact administrator.',
  /**reset password */
  reset_password_success_msg: 'Please login with new password',
  reset_password: 'Reset Password',
  reset_password_header:
    ' Please enter the fields you use to reset to your account.',
  new_password: 'New Password',
  valid_password_msg: 'Please provide valid password.',
  password_hint:
    'Minimum 8 chars, at least one uppercase, one lowercase, one number and one special char.',
  confirm_password: 'Confirm Password',
  password_not_match: 'The passwords do not match.',
  save_reset_password: 'Save Reset Password',
  /**set password */
  set_password_success_msg: 'Please login with new password',
  create_password: 'Create Password',
  set_password_header:
    'Please enter the fields to create a password for your account.',
  save_password: 'Save Password',
  /**announcement */
  delete_announcement: 'Delete Announcement',
  edit_announcement: 'Edit Announcement',
  view_announcement: 'View Announcement',
  announcement_delete_success_msg: 'Announcement has been deleted.',
  announcement: 'Announcement',
  all_announcement: 'All Announcement',
  announcement_list: 'Announcement List',
  add_new_announcement: 'Add New Announcement',
  search: 'Search',
  filter: 'Filter',
  reset_filter: 'Reset Filter',
  title: 'Title',
  link: 'Link',
  description: 'Description',
  written_by: 'Written By',
  action: 'Action',
  announcement_add_msg: 'Announcement has been created.',
  announcement_management: 'Announcement Management',
  announcement_creation_failed: 'Announcement creation failed.',
  add_announcement: 'Add Announcement',
  cancel: 'Cancel',
  announcement_update_msg: 'Announcement updated successfully.',
  announcement_not_found: 'Announcement not found',
  announcement_update_failed: 'Announcement update failed.',
  update: 'Update',
  announcement_details: 'Announcement Details',
  company_feed: 'Company Feed',
  jobsite_feed: 'Jobsite Feed',
  all_user: 'All User',
  image: 'Image',
  new_message_from: 'New Message From',
  /**chat */
  chat: 'Chat',
  /**company */
  submit: 'Submit',
  confirm_hide_company_msg: 'Are you sure you want to hide this company ?',
  confirm_show_company_msg: 'Are you sure you want to show this company ?',
  hide_company: 'Hide Company',
  show_company: 'Show Company',
  view_company: 'View Company',
  edit_company: 'Edit Company',
  delete_company: 'Delete Company',
  company_activated: 'Company has been activated.',
  company_deactivated: 'Company has been deactivated.',
  company_delete_success_msg: 'Company has been deleted.',
  admin_email: 'Admin Email',
  admin_phone: 'Admin Phone',
  company: 'Company',
  all_company: 'All Company',
  company_list: 'Company List',
  add_new_company: 'Add New Company',
  is_approved: 'Is Approved?',
  select: 'Select',
  pending: 'Pending',
  approved: 'Approved',
  name: 'Name',
  address: 'Address',
  contact: 'Contact',
  company_create_msg: 'Company has been created',
  company_create_err_msg: 'Company Creation Failed.',
  company_management: 'Company Management',
  add_company: 'Add Company',
  not_allow_to_edit_company: 'You are not allowed to edit this company.',
  commpany_updated_successfully: 'Company updated successfully.',
  company_creation_failed: 'Company Creation Failed.',
  company_not_found: 'Company not found',
  company_has_been_approved: 'Company has been approved.',
  company_details: 'Company Details',
  approve_company: 'Approve Company',
  division: 'Division',
  sub_division: 'Sub Division',
  phone: 'Phone',
  city: 'City',
  state: 'State',
  zip_code: 'Zip Code',
  status: 'Status',
  user_manager_contact_name: 'User Manager Contact Name',
  user_manager_phone_number: 'User Manager Phone Number',
  about: 'About',
  company_picture: 'Company Picture',
  /**faq */
  faq: 'FAQ',
  all_faq: 'All FAQ',
  faq_list: 'FAQ List',
  /**field reporting */
  field_report_delete_success_msg: 'Field Report has been deleted.',
  view_field_report: 'View Field Report',
  edit_field_report: 'Edit Field Report',
  delete_field_report: 'Delete Field Report',
  field: 'Field',
  select_field: 'Select Field',
  field_report_success_msg: 'Field Report has been created.',
  field_report_err_msg: 'Field Report Creation Failed.',
  field_reporting: 'Field Reporting',
  add_new_field_report: 'Add New Field Report',
  add_field_report: 'Add Field Report',
  all_field_report: 'All Field Report',
  field_report_list: 'Field Report List',
  fields: 'Fields',
  created_by: 'Created By',
  select_company: 'Select Company',
  field_report: 'Field Report',
  field_reporting_details: 'Field Reporting Details',
  add_new_user_field_report: 'Add New User Field Report',
  field_type: 'Field Type',
  measurement_units: 'Measurement Units',
  /** field type */
  view_field_type: 'View Field Type',
  edit_field_type: 'Edit Field Type',
  delete_field_type: 'Delete Field Type',
  field_type_delete_success_msg: 'Field Type has been deleted.',
  field_type_success_msg: 'Field Type has been created.',
  field_type_management: 'Field Type Management',
  add_new_field_type: 'Add New Field Type',
  field_type_creation_failed: 'Field Type creation failed.',
  add_field_type: 'Add Field Type',
  field_type_update_success_msg: 'Field Type updated successfully.',
  field_type_not_found: 'Field Type not found',
  field_type_update_err_msg: 'Field Type update failed.',
  all_field_type: 'All Field Type',
  field_type_list: 'Field Type List',
  field_type_details: 'Field Type Details',
  /**hazard */
  hazard_delete_success_msg: 'Hazard has been deleted.',
  select_hazard_type: 'Select Hazard Type',
  hazard_assign_success_msg: 'Hazard assign successfully',
  view_hazard: 'View Hazard',
  delete_hazard: 'Delete Hazard',
  view_hazard_type: 'View Hazard Type',
  delete_hazard_type: 'Delete Hazard Type',
  edit_hazard_type: 'Edit Hazard Type',
  assign_to: 'Assign To',
  hazard_success_msg: 'Hazard has been created',
  hazard_creation_failed: 'Hazard Creation Failed.',
  enable_your_location: 'Please enable your location.',
  hazard: 'Hazard',
  add_new_hazard: 'Add New Hazard',
  add_hazard: 'Add Hazard',
  hazard_updated_success_msg: 'Hazard updated successfully.',
  hazard_not_found: 'Hazard not found',
  edit_hazard: 'Edit Hazard',
  hazard_update_failed: 'Hazard update failed.',
  all_hazard: 'All Hazard',
  date: 'Date',
  is_resolved: 'Is Resolved?',
  yes: 'Yes',
  no: 'No',
  location: 'Location',
  reported_by: 'Reported By',
  reported_date: 'Reported Date',
  type: 'Type',
  hazard_list: 'Hazard List',
  hazard_details: 'Hazard Details',
  reporter_phone: 'Reporter Phone',
  resolved_by: 'Resolved By?',
  /**hazard type */
  choose_location: 'Choose Location',
  upload_hazard_image: 'Upload Hazard Image',
  hazard_image: 'Hazard Image',
  notes: 'Notes',
  reporter_name: 'Reporter Name',
  hazard_type_success_msg: 'Hazard Type has been created.',
  hazard_type_err_msg: 'Hazard Type Creation Failed.',
  hazard_type: 'Hazard Type',
  add_new_hazard_type: 'Add New Hazard Type',
  add_hazard_type: 'Add Hazard Type',
  hazard_type_update_msg: 'Hazard Type updated successfully.',
  hazard_type_detail_not_found: 'Hazard Type detail not found',
  hazard_type_updated_failed: 'Hazard Type update failed.',
  all_hazard_types: 'All Hazard Types',
  hazard_type_list: 'Hazard Type List',
  hazard_type_delete_success_msg: 'Hazard Type has been delete.',
  /**worktype */
  work_type_delete_success_msg: 'Work Type has been deleted.',
  view_work_type: 'View Work Type',
  delete_work_type: 'Delete Work Type',
  work_type_success_msg: 'Work Type has been created.',
  work_type: 'Work Type',
  add_new_work_type: 'Add New Work Type',
  work_type_creation_failed: 'Work Type creation failed.',
  add_work_type: 'Add Work Type',
  work_type_update_msg: 'Work Type updated successfully.',
  work_type_not_found: 'Work Type not found',
  edit_work_type: 'Edit Work Type',
  work_type_update_failed: 'Work Type update failed.',
  step: 'Step',
  operating_procedure: 'Operating Procedure',
  hazard_identification: 'Hazard Identification',
  hazard_relief: 'Hazard Relief',
  required: 'Required',
  optional: 'Optional',
  add_step: 'Add Step',
  work_type_details: 'Work Type Details',
  work_type_list: 'Work Type List',
  /**location */
  view_location: 'View Location',
  edit_location: 'Edit Location',
  delete_location: 'Delete Location',
  location_success_msg: 'Location has been created.',
  location_management: 'Location Management',
  add_new_location: 'Add New Location',
  location_creation_failed: 'Location creation failed.',
  add_location: 'Add Location',
  location_update_msg: 'Location updated successfully.',
  location_not_found: 'Location not found',
  location_update_failed: 'Location update failed.',
  all_location: 'All Location',
  coordinate: 'Coordinate',
  location_list: 'Location List',
  location_details: 'Location Details',
  operator: 'Operator',
  location_contact_name: 'Location Contact Name',
  location_contact: 'Location Contact',
  customer_contact_name: 'Customer Contact Name',
  customer_contact: 'Customer Contact',
  /** map feature */
  map_features: 'Map Features',
  map_reports: 'Map Reports',
  /** ppe */
  confirm_ppe_hide_msg: 'Are you sure you want to hide this PPE?',
  confirm_ppe_show_msg: 'Are you sure you want to show this PPE?',
  this_is_mandatory_ppe: 'This is mandatory PPE.',
  hide_ppe: 'Hide PPE',
  show_ppe: 'Show PPE',
  ppe_activated: 'PPE has been activated.',
  ppe_deactivated: 'PPE has been deactivated.',
  ppe_delete_success_msg: 'PPE has been deleted.',
  view_ppe: 'View PPE',
  edit_ppe: 'Edit PPE',
  delete_ppe: 'Delete PPE',
  ppe_success_msg: 'PPE has been created.',
  ppe_management: 'PPE Management',
  add_new_ppe: 'Add New PPE',
  ppe_creation_failed: 'PPE creation failed.',
  add_ppe: 'Add PPE',
  ppe_updated_success_msg: 'PPE updated successfully.',
  ppe_not_found: 'PPE not found',
  ppe_update_failed: 'PPE update failed.',
  ppe: 'PPE',
  all_ppe: 'All PPE',
  is_mandatory: 'Is Mandatory?',
  ppe_list: 'PPE List',
  ppe_details: 'PPE Details',
  /**profile */
  profile_updated_successfully: 'Profile updated successfully',
  login_with_new_password: 'Please login with new password',
  current_password_is_incorrect: 'Current password is incorrect.',
  my_profile: 'My Profile',
  edit_profile: 'Edit Profile',
  profile_update_failed: 'Profile Update Failed.',
  save_now: 'Save Now',
  change_password: 'Change Password',
  change_now: 'Change Now',
  invalid_profile_upload: 'Invalid Profile Upload',
  file_must_be_an_image_type: 'File must be an image type.',
  invalid_file_size: 'Invalid file size',
  file_size_less_5MB: 'File size must be less than 5MB',
  user_not_found: 'User not found',
  clear_profile_image: 'Clear Profile Image',
  undo_action_afterwards:
    'Are you sure? You can not undo this action afterwards.',
  uploading_profile: 'Uploading Profile',
  cell_phone: 'Cellphone',
  work_email: 'Work Email',
  work_phone: 'Work Phone',
  allergies: 'Allergies',
  blood_type: 'Blood Type',
  certification_notes: 'Certification Notes',
  emergency_contact_name: 'Emergency Contact Name',
  emergency_contact_phone: 'Emergency Contact Phone',
  badges: 'Badges',
  upload_profile: 'Upload Profile',
  invalid_file_type: 'Invalid File Type',
  /**company report */
  sn: 'S.N.',
  company_name: 'Company Name',
  date_added: 'Date Added',
  date_removed: 'Date Removed',
  number_of_jobs_worked: 'Number of Jobs Worked',
  total_hours_worked: 'Total Hours Worked',
  total_users: 'Total Users',
  report: 'Report',
  company_report: 'Company Report',
  select_location: 'Select Location',
  /**employee report */
  last_name: 'Last Name',
  first_name: 'First Name',
  employee: 'Employee',
  employee_report: 'Employee Report',
  /**job summaries */
  job_summaries: 'Job Summaries',
  job_summaries_report: 'Job Summaries Report',
  view_job_summaries_report: 'View Job Summaries Report',
  work_description: 'Work Description',
  job_summaries_info: 'Job Summaries Info',
  print: 'Print',
  signed_jsa_info: 'Signed JSA Info',
  job_title: 'Job Title',
  completed_by: 'Completed By',
  customer: 'Customer',
  crew_member: 'Crew Member',
  /**jsa report */
  view_jsa_report: 'View JSA Report',
  latitude: 'Latitude',
  longitude: 'Longitude',
  jsa: 'JSA',
  jsa_report: 'JSA Report',
  jsa_details: 'JSA Details',
  jsa_info: 'JSA Info',
  jsa_completed_by: 'JSA Completed By',
  location_of_work: 'Location of Work',
  jsa_creator: 'JSA Creator',
  location_name: 'Location Name',
  customer_name: 'Customer Name',
  operator_name: 'Operator Name',
  date_start: 'Date Start',
  date_end: 'Date End',
  location_coordinates: 'Location Coordinates',
  user_coordinates: 'User Coordinates',
  foreman: 'Foreman',
  time_start: 'Time Start',
  time_end: 'Time End',
  /**settings */
  permission_updated_successfully: 'Permission updated successfully.',
  permission_not_found: 'Permission not found',
  settings: 'Settings',
  role_and_permissions: 'Role and Permissions',
  edit_permissions: 'Edit Permissions',
  permission_update_failed: 'Permissison update failed.',
  role_and_permission_list: 'Role and Permission List',
  permission: 'Permission',
  permissions: 'Permissions',
  role: 'Role',
  /**sop */
  sop_delete_success_msg: 'SOPs has been deleted',
  view_sop: 'View SOPs',
  edit_sop: 'Edit SOPs',
  delete_sop: 'Delete SOPs',
  sop_success_msg: 'SOPs has been created',
  sops: 'SOPs',
  add_sops: 'Add SOPs',
  add_new_sops: 'Add New SOPs',
  sops_creation_failed: 'SOPs creation failed.',
  sops_updated_msg: 'SOPs updated successfully.',
  sops_not_found: 'SOPs not found.',
  edit_sops: 'Edit SOPs',
  sops_update_failed: 'SOPs update failed.',
  all_sops: 'All SOPs',
  sops_list: 'SOPs List',
  sops_details: 'SOPs Details',
  /**splash screen */
  tasks: 'Tasks',
  user_management: 'User Management',
  faqs: 'FAQs',
  /**user field report */
  user_field_report_delete_success_msg: 'User Field Report has been deleted.',
  view_user_field_report: 'View User Field Report',
  edit_user_field_report: 'Edit User Field Report',
  delete_user_field_report: 'Delete User Field Report',
  user_field_report_success_msg: 'User Field Report has been created.',
  user_field_report_management: 'User Field Report Management',
  user_field_report_creation_failed: 'User Field Report creation failed.',
  add_user_field_report: 'Add User Field Report',
  user_field_report_update_msg: 'User Field Report updated successfully.',
  user_field_report_not_found: 'User Field Report not found',
  user_field_report: 'User Field Report',
  user_field_report_udpate_failed: 'User Field Report udpate failed.',
  all_user_field_report: 'All User Field Report',
  user_field_report_list: 'User Field Report List',
  select_field_report: 'Select Field Report',
  select_field_type: 'Select Field Type',
  user_field_report_details: 'User Field Report Details',
  measurement_unit: 'Measurement Unit',
  /**users */
  approve_user: 'Approve User',
  users: 'Users',
  user_profile: 'User Profile',
  user_approved: 'User has been approved.',
  confirm_hide_user: 'Are you sure you want to hide this user ?',
  confirm_show_user: 'Are you sure you want to show this user ?',
  hide_user: 'Hide User',
  show_user: 'Show User',
  user_activated: 'User has been activated.',
  user_deactivated: 'User has been deactivated.',
  user_delete_success_msg: 'User has been deleted.',
  view_user: 'View User',
  delete_user: 'delete User',
  user_create_success_msg: 'User has been created.',
  user_create_err_msg: 'User Creation Failed.',
  add_new_user: 'Add New User',
  add_user: 'Add User',
  user_update_success_msg: 'User has been updated.',
  user_update_err_msg: 'User Update Failed.',
  edit_user: 'Edit User',
  request_to_deactivate: 'Request To Deactivate',
  user: 'User',
  all_users: 'All Users',
  bulk_upload_template: 'Bulk Upload Template',
  work_type_template: 'Work Type Template',
  /**pagination */
  show_rows: 'Show Rows',
  showing: 'Showing',
  out_of: 'out of',
  no_result_found: 'No Result Found',
  loading: 'Loading',

  dont_have_permission:
    'You do not have permission to access it. Please contact administrator.',
  upload_csv: 'Upload CSV',
  file_upload_success_msg: 'File uploaded successfullly.',
  maximum_upload_attachments_size_upto_15MB:
    'Maximum upload attachments size upto 15 MB.',
  upload_valid_data: 'Please upload valid data.',
  upload_files: 'Upload Files',
  uploading: 'Uploading',
  processing: 'Processing',
  internal_server_error: 'Internal Server Error',
  delete: 'Delete',
  valid_url_address_msg: 'Please provide a valid URL address.',
  write_here: 'Write Here...',
  upload_image: 'Upload Image',
  view: 'View',
  edit: 'Edit',
  confirm_delete: 'Are you sure you want to delete this ?',
  active: 'Active',
  only_1_file_allowed: 'Only 1 file is allowed.',
  drag_drop_title_5MB:
    'Drag & Drop some files here, or click to select files. Upload files upto 5MB.',
  maximum_upload_attachments_size_upto_5MB:
    'Maximum upload attachments size upto 5 MB.',
  file_size_too_large: 'File size is too large.',
  delete_files: 'Delete Files',
  too_many_characters: 'Too Many Characters',
  valid_contact_number_msg: 'Please provide a valid contact number.',
  upload_company_logo: 'Upload Company Logo',
  something_went_wrong: 'Sorry, something went wrong.',
  team_notified:
    'Our team has been notified and we will get it fixed as soon as we can.',
  reload_page: 'Reload page',
  take_me_home: 'Take me home',
  invalid_credentials: 'Invalid Credentials',
  collapse_sidebar: 'Collapse Sidebar',
  expand_sidebar: 'Expand Sidebar',
  logout: 'Logout',
  is_active: 'Is Active?',
  company_active_crew: 'Company Active Crew',
  current_password: 'Current Password',
  hide: 'Hide',
  show: 'Show',
  old_new_match: 'The old and new password is same.',
  middle_name: 'Middle Name',
  emergency_name: 'Emergency Name',
  safety_equipment_ppe: 'Safety Equipment (PPE)',
  pre_job_hazard_assessment: 'Pre-Job Hazard Assessment',
  time_line: 'Time Line',
  date_time: 'Date Time',
  view_permission_and_role: 'View Permission and Role',
  edit_permission_and_role: 'Edit Permission and Role',
  chats: 'Chats',
  reports: 'Reports',
  dashboard: 'Dashboard',
  deactivate_user_request: 'Deactivate User Request',
  environment_tracker: 'Environment Tracker',
  select_work_type: 'Select Work Type',
  sop_link: 'SOP Link',
  personal_email: 'Personal Email',
  select_role: 'Select Role',
  field_type_name: 'Field Type Name',
  measurement: 'Measurement',
  units: 'Units',
  value: 'Value',
  field_data: 'Field Data',
  select_field_report_data: 'Select Field Report Data',
  zip: 'Zip',
  superadmin: 'Superadmin',
  field_manager: 'Field Manager',
  field_operator: 'Field Operator',
  service_company_admin: 'Service Company Admin',
  operator_company_admin: 'Operator Company Admin',
  company_safety_manager: 'Company Safety Manager',
  field_safety_manager: 'Field Safety Manager',
  work_type_step_success_msg: 'Work Type Step has been created.',
  work_type_step_delete_msg: 'Work Type Step has been deleted.',
  work_type_step_update_msg: 'Work Type Step has been updated.',
  edit_work_type_step: 'Edit Work Type Step',
  delete_work_type_step: 'Delete Work Type Step',
  edit_step: 'Edit Step',
  jsa_end_summary: 'JSA End Summary',
  create_work_type_step: 'Create Work Type Step',
  add: 'Add',
  qr_code: 'Qr Code'
};

export default en;
