import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { WorkTypeSchema } from 'constants/schema';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, {useEffect, useState} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  workTypeData?: WorkTypeSchema;
  editStep?: boolean;
}

const WorkTypeForm: React.FC<Props> = (props) => {
  const { workTypeData, editStep = false } = props;
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '', // for company List
  });

  useEffect(()=>{
    if (!workTypeData) return;
    if (workTypeData?.company_details?.name)
    {
      const company = {
        label: workTypeData?.company_details.name,
        value: workTypeData?.company_details.id,
      };
      setValue('company', company);
    }
    
  }, [workTypeData, setValue]);

  const companyAPI = new CompanyResource();

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [
      `company-list`,
      { search: filterParams.searchCompany, is_active: true },
    ],
    () =>
      companyAPI
        .allList({ search: filterParams.searchCompany, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));
  
  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      search: value,
    }));
  };

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl
              isInvalid={!!errors?.name}
              isRequired={editStep === true ? false : true}>
              <FormLabel>{strings.name}</FormLabel>
              <Input
                {...register('name', {
                  required: strings.name,
                })}
                type="text"
                defaultValue={workTypeData?.name}
                placeholder={strings.name}
                disabled={editStep}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          {/* <GridItem>
            <FormControl isInvalid={!!errors?.sop_link}>
              <FormLabel>SOP Link</FormLabel>
              <Input
                {...register('sop_link', {
                  validate: (value) => {
                    if (value)
                      return (
                        validURL(value) || 'Please provide a valid URL address.'
                      );
                  },
                })}
                type="text"
                defaultValue={workTypeData?.sop_link}
                placeholder="SOP Link"
              />
              <FormErrorMessage>
                {errors?.sop_link && errors?.sop_link?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem> */}
          <GridItem>
            <FormControl isInvalid={!!errors?.company} isRequired>
              <FormLabel>{strings.company}</FormLabel>
              <Controller
                control={control}
                name="company"
                rules={{
                  required: strings.select_company,
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="company"
                    value={field.value ? field.value : null}
                    placeholder={strings.select_company}
                    isLoading={isCompanyLoading}
                    options={companyOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                    onInputChange={handleCompanyChange}
                    isDisabled = {editStep}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.company && errors?.company?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.description}>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description')}
                defaultValue={workTypeData?.description}
                placeholder={strings.write_here}
                disabled={editStep}
              />
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          {/* <GridItem>
            <FormControl isInvalid={!!errors?.file}>
              <FormLabel>Upload SOP</FormLabel>
              <Uploader
                acceptFileType={WORK_TYPE_ACCEPT_FILE_TYPE}
                maxFiles={1}
                description="Drag & Drop file here, or click to select file. Upload file upto 5MB."
              />
              <FormErrorMessage>
                {errors?.file && errors?.file?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem> */}
        </Grid>
      </Stack>
    </form>
  );
};

export default WorkTypeForm;
