import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';

interface Props {
  ppeData: any;
}

const JSAEquipment: React.FC<Props> = (props) => {
  const { ppeData } = props;

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.safety_equipment_ppe}
        </Heading>
      </Flex>
      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm" marginTop={3}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          {ppeData.map((ppe: any) => (
            <GridItem key={ppe?.id}>
              <FormControl>
                <FormLabel sx={labelStyles}>{ppe?.name}</FormLabel>
              </FormControl>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default JSAEquipment;
