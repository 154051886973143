import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import JSAResource from 'api/location';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTagTitle from 'components/common/CustomTagTitle';
import JSAEquipment from 'components/reports/JSAEquipment';
import JSAPreJobHazard from 'components/reports/JSAPreJobHazard';
import JSATimeLine from 'components/reports/JSATimeLine';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getFullName } from 'utils/esg';
import history from 'utils/history';

const JobSummariesViewReport: React.FC = () => {
  const { id }: any = useParams();
  const jsaApi = new JSAResource();
  const printRef: any = useRef();

  const jsaQuery = useQuery<any>(
    [`jobsummaries${id}`, id],
    () => jsaApi.jobSummariesListReportById(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const pageStyle = `
    @page {
      margin: 2cm;
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
    documentTitle: `${jsaQuery?.data?.title}_${jsaQuery?.data}`,
  });

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  if (jsaQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (jsaQuery.isError) {
    history.push(routes.reports.jsa.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.reports.jobSummaries.list}>
              {strings.job_summaries}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.job_summaries_info}
          </Heading>
          <Stack direction="row" spacing="6">
            <Button size="sm" colorScheme="primary" onClick={handlePrint}>
              {strings.print}
            </Button>
          </Stack>
        </Flex>

        <Box ref={printRef}>
          <Heading size="md" textTransform="capitalize">
            {strings.signed_jsa_info}
          </Heading>
          <Stack
            bg="white"
            p={['3', '6']}
            shadow="box"
            rounded="sm"
            marginTop={3}
            marginBottom={3}>
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.job_title}</FormLabel>
                  <Box sx={infoStyles}>
                    <CustomTagTitle
                      type="text"
                      status={true}
                      name={jsaQuery?.data?.signed_jsa?.job_title || '-'}
                    />
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
                  <Box sx={infoStyles}>
                    <CustomTagTitle
                      type="text"
                      status={true}
                      name={jsaQuery?.data?.signed_jsa?.company_name || '-'}
                    />
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.completed_by}</FormLabel>
                  <Box sx={infoStyles}>
                    {jsaQuery?.data?.signed_jsa?.completed_by || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.customer}</FormLabel>
                  <Box sx={infoStyles}>
                    {jsaQuery?.data?.signed_jsa?.customer_name || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.crew_member}</FormLabel>
                  <Box sx={infoStyles}>
                    <UnorderedList>
                      {jsaQuery?.data?.signed_jsa?.crews?.map((res: any) => (
                        <>
                          <ListItem>{getFullName(res)}</ListItem>
                        </>
                      ))}
                    </UnorderedList>
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>

          <JSAEquipment ppeData={jsaQuery?.data?.signed_jsa?.ppe} />
          <JSAPreJobHazard
            hazardData={jsaQuery?.data?.signed_jsa?.pre_hazards}
            stepData={jsaQuery?.data?.signed_jsa?.steps}
          />

          <JSATimeLine timeLineData={jsaQuery?.data?.signed_jsa?.time_line} />
        </Box>
      </Stack>
    </>
  );
};

export default JobSummariesViewReport;
