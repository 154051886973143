import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PPEResource from 'api/ppe';
import { CenterSpinner } from 'components/common/CenterSpinner';
import PPEForm from 'components/ppe/PPEForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { PPESchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditPPE: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<PPESchema>();

  const ppeAPI = new PPEResource();

  const ppeQuery = useQuery(
    [`ppe${id}-edit`, id],
    () => ppeAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updatePPE = useMutation((data: any) => ppeAPI.update(id, data));

  const onSubmit = (data: PPESchema) => {
    // data.is_active = ppeQuery.data?.is_active;
    // data.company = data.company.value;
  const formData ={
    name: data.name,
    
    is_active:  ppeQuery.data?.is_active,
    company:data.company.value,
    is_mandatory: data.is_mandatory
  }
    updatePPE.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.ppe_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('ppeList');
        history.push(routes.ppe.list);
      },
    });
  };

  if (ppeQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ppeQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.ppe_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.ppe.list}>
            {strings.ppe_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.ppe.edit}>
            {strings.edit_ppe}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_ppe}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updatePPE.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.ppe_update_failed}
                </Alert>
              )}
              <PPEForm ppeData={ppeQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updatePPE.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditPPE;
