import {
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';

interface Props {
  hazardData: any;
  stepData: any;
}

const JSAPreJobHazard: React.FC<Props> = (props) => {
  const { hazardData } = props;
  return (
    <>
      <Flex justify="space-between" marginTop={3}>
        <Heading size="md" textTransform="capitalize">
          {strings.pre_job_hazard_assessment}
        </Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm" marginTop={3}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.operating_procedure} 1</Th>
                <Th>{strings.hazard_identification}</Th>
                <Th>{strings.hazard_relief}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {hazardData?.map((res: any, index: any) => (
                <>
                  <Tr key={index}>
                    <Td>{res.description}</Td>
                    <Td>{res.potential_hazards}</Td>
                    <Td>{res.hazard_plan}</Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default JSAPreJobHazard;
