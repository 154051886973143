import {
  Avatar,
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { withAuthState } from 'components/hoc/auth';
import MenuLink from 'components/sidebar/MenuLink';
import SidebarSubMenu from 'components/sidebar/SidebarSubMenu';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ThemeContext } from 'context/ThemeContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  BiChevronsLeft,
  BiChevronsRight,
  BiEdit,
  BiLogOut,
  BiUser,
} from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import HideControl from 'services/HideControl';
import history from 'utils/history';

interface Props {
  logout: () => void;
}

const Sidebar: React.FC<Props> = (props) => {
  const { logout } = props;
  const { setTheme } = useContext(ThemeContext);
  const location = useLocation();
  const [isLargerThan1140] = useMediaQuery('(min-width: 1140px)');
  const isInitialMount = useRef(true);

  // Sidebar is collapsed for splash screen and my profile
  const isSidebarNotExpandable =
    location.pathname === routes.splash ||
    location.pathname === routes.profile.myProfile ||
    location.pathname === routes.profile.edit;

  const [isExpanded, setIsExpanded] = useState(isLargerThan1140 ? true : false);

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const onLogoutClick = async () => {
    try {
      logout();
    } catch (err) {}
  };

  /**
   * Callback function for the menu click
   *
   * @param route route to switch next
   * @param theme theme-variable
   * @returns
   */
  const handleMenuClick = (route: string, theme: string) => {
    const isSameSubsystem =
      location.pathname.split('/')[1] === route.split('/')[1];
    if (isSameSubsystem && !isLargerThan1140) {
      setIsExpanded((prevState) => !prevState);
      return;
    }
    history.push(route);
    setTheme(theme);
  };

  useEffect(() => {
    // Collapse the sidebar only on smaller screen
    if (!isLargerThan1140) {
      setIsExpanded(false);
    }
  }, [location, isLargerThan1140]);

  // Expand the sidebar when the user clicks on other menu in smaller screen
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!isLargerThan1140) {
      setIsExpanded(true);
    }
  }, [location.pathname, isLargerThan1140]);

  // Show overlay backdrop for iPad when the sidebar is expanded
  const shouldShowOverlayBackdrop = isExpanded && !isLargerThan1140;
  // Sidebar pushes the content to the right in larger screen
  const isSidebarOfOverlayType = !isLargerThan1140;
  // Show submenus on hover (on popover) only if the sidebar is collapsed in larger screen
  const shouldShowHoverSubMenus =
    isSidebarNotExpandable || (!isExpanded && isLargerThan1140);
  // Enable tooltip on menus only for larger screen when the sidebar is expanded
  const shouldDisableToolTipOnMenus =
    isSidebarNotExpandable || (!isExpanded && isLargerThan1140);

  // styles
  const navBoxStyles = {
    h: '40px',
    cursor: 'pointer',
  };

  return (
    <>
      {shouldShowOverlayBackdrop && (
        <Box onClick={() => setIsExpanded(false)} sx={overlayCoverStyles} />
      )}
      {isSidebarOfOverlayType && <Box w="40px" />}
      <Stack
        direction="row"
        spacing="0"
        h="100vh"
        position={isSidebarOfOverlayType ? 'absolute' : 'relative'}>
        <Stack
          direction="row"
          spacing="0"
          bg="primary.500"
          w="40px"
          zIndex={20}
          h="100%">
          <Flex
            direction="column"
            w="40px"
            minW="40px"
            bg="rgba(255,255,255,0.15)"
            justifyContent="space-between">
            <Flex direction="column" color="white">
              <Box position="relative" backgroundColor={'#fff'}>
                <Link as={NavLink} to={routes.splash}>
                  <Center sx={navBoxStyles} p="2" className="sideBarLogo">
                    <Image src={Logo} alt="ESG onSite" />
                  </Center>
                </Link>
              </Box>

              <HideControl
                hideFor="company-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="company"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="location-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="location"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="task"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="task"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="ppe-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="ppe"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="user-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="user"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="hazard-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="hazard"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="announcement-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="announcement"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="reports"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="reports"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="chats"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="chat"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <MenuLink
                menu="faqs"
                shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                handleClickCallback={handleMenuClick}
              />
              <HideControl
                hideFor="map-feature"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="mapFeature"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="field-report"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="fieldReport"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
              <HideControl
                hideFor="settings"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <MenuLink
                  menu="settings"
                  shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                  shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                  handleClickCallback={handleMenuClick}
                />
              </HideControl>
            </Flex>
            <Stack direction="column" spacing="2">
              <Box>
                <Tooltip
                  hasArrow
                  label={isExpanded ? 'Collapse Sidebar' : 'Expand Sidebar'}>
                  <IconButton
                    icon={isExpanded ? <BiChevronsLeft /> : <BiChevronsRight />}
                    variant="link"
                    aria-label={
                      isExpanded
                        ? strings.collapse_sidebar
                        : strings.expand_sidebar
                    }
                    color="white"
                    size="lg"
                    fontSize="x-large"
                    onClick={() => setIsExpanded((prevState) => !prevState)}
                    isDisabled={isSidebarNotExpandable}
                  />
                </Tooltip>
              </Box>

              <Center py="2">
                <Menu>
                  <MenuButton>
                    <Avatar
                      size="sm"
                      showBorder
                      src={
                        loggedInUser?.user_information?.image ||
                        loggedInUser?.user_information?.profile_image
                      }
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      icon={<Icon sx={dropdownIconStyles} as={BiUser} />}
                      onClick={() => history.push(routes.profile.myProfile)}>
                      <Link as={NavLink} to={routes.profile.myProfile}>
                        {strings.my_profile}
                      </Link>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      icon={<Icon sx={dropdownIconStyles} as={BiEdit} />}
                      onClick={() => history.push(routes.profile.edit)}>
                      {strings.edit_profile}
                    </MenuItem>

                    {/* <MenuDivider />
                    <MenuItem pl="0">
                      <Menu>
                        <MenuButton transition="all 0.2s">
                          <MenuItem
                            icon={
                              <Icon sx={dropdownIconStyles} as={BiWorld} />
                            }>
                            <Flex>
                              <Text color="#282a38" pr="2">
                                Change Language
                              </Text>
                              <Box pt="1">
                                <BiChevronRight />
                              </Box>
                            </Flex>
                          </MenuItem>
                        </MenuButton>
                        <MenuList
                          aria-orientation="vertical"
                          // direction="top-right"
                        >
                          <MenuItem
                            icon={<Image src={ESP} w="6" h="6" />}
                            onClick={() => handleLanguage('esp')}>
                            <Flex justifyContent="space-between">
                              <Text>ESP</Text>
                              {currentLanguage === 'esp' && (
                                <Box bg="gray.100">
                                  <Icon
                                    as={BiCheck}
                                    color="green.400"
                                    w="4"
                                    h="4"
                                  />
                                </Box>
                              )}
                            </Flex>
                          </MenuItem>
                          <MenuDivider />
                          <MenuItem
                            icon={<Image src={EN} w="6" h="6" />}
                            onClick={() => handleLanguage('en')}>
                            <Flex justifyContent="space-between">
                              <Text>EN</Text>
                              {currentLanguage === 'en' && (
                                <Box>
                                  <Icon
                                    as={BiCheck}
                                    color="green.400"
                                    w="4"
                                    h="4"
                                  />
                                </Box>
                              )}
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </MenuItem> */}
                    <MenuDivider />
                    <MenuItem
                      icon={<Icon sx={dropdownIconStyles} as={BiLogOut} />}
                      onClick={onLogoutClick}>
                      {strings.logout}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Center>
            </Stack>
          </Flex>
        </Stack>

        {!isSidebarNotExpandable && (
          <Box
            transform={`translateX(${isExpanded ? '0' : '-100%'})`}
            width={isExpanded ? '180px' : '0px'}
            bg="primary.400"
            h="100vh"
            transition="all 0.15s ease-in-out"
            zIndex={15}
            overflow="hidden">
            <SidebarSubMenu />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default withAuthState(Sidebar);

const dropdownIconStyles = {
  w: '4',
  h: '4',
};

const overlayCoverStyles = {
  backgroundColor: 'rgba(0,0,0,0.3)',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100vh',
  zIndex: 10,
};
