import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import FieldTypeResource from 'api/field_type';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { truncateString } from 'utils';
import history from 'utils/history';

const ViewFieldType: React.FC = () => {
  const { id }: any = useParams();
  const fieldTypeApi = new FieldTypeResource();

  const fieldTypeQuery = useQuery<any>(
    [`field-type${id}`, id],
    () => fieldTypeApi.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (fieldTypeQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (fieldTypeQuery.isError) {
    history.push(routes.fieldReporting.fieldType.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.fieldReporting.fieldType.list}>
              {strings.field_type}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {truncateString(fieldTypeQuery?.data?.title || '')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.field_type_details}
          </Heading>
          <Stack direction="row" spacing="6">
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() =>
                history.push(
                  routes.fieldReporting.fieldType.edit.replace(':id', id)
                )
              }>
              {strings.edit_field_type}
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.name}</FormLabel>
                <Box sx={infoStyles}>{fieldTypeQuery?.data?.title || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.field_report}</FormLabel>
                <Box sx={infoStyles}>
                  {fieldTypeQuery?.data?.field_report_detail?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewFieldType;
