import { Input } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

// Auth
export const ACCESS_TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expires';

// Pagination

export const DEFAULT_PAGE_SIZE = 15;

export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [5, 15, 30, 50, 100];

// Themes
export const THEME_USER = 'theme-blue3';
export const THEME_COMPANY = 'theme-brown';
export const THEME_LOCATION = 'theme-purple';
export const THEME_PPE = 'theme-red';
export const THEME_HAZARD = 'theme-cyan';
export const THEME_SOPS = 'theme-orange';
export const THEME_REPORTS = 'theme-malibu';
export const THEME_DASHBOARD = 'theme-pink';
export const THEME_ANNOUNCEMENT = 'theme-burlywood';
export const THEME_CHAT = 'theme-darkbrown';
export const THEME_TASK = 'theme-teal';
export const THEME_SETTING = 'theme-green2';
export const THEME_FAQ = 'theme-yellow';
export const THEME_MAP_FEATURE = 'theme-purple2';
export const THEME_FIELD_REPORT = 'theme-purple3';

export const REACT_DATE_PICKER_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const CSV_EXTENSION =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';

export const CERTIFICATION_ACCEPT_FILE_TYPE = 'image/*';
export const WORK_TYPE_ACCEPT_FILE_TYPE = 'application/pdf';

export const USER_HEADER = [
  'First Name',
  'Middle Name (optional)',
  'Last Name',
  'Email',
  'Username (optional)',
  'Work Email',
  'Phone',
  'Company Name',
  'Role (Admin Y/N)',
];
export const USER_LIST = [
  'S Test', 
  '',
  'S Test',
  's@esgtest.com',
  's@esgtest.com',
  's@esgtest.com',
  '9874561230',
  'S Test',
  'Y'
]
export const SOPS_HEADER = ['Id', 'Name', 'Description'];
export const WORK_TYPE_HEADER = ['S.No', 'Name', 'Description', 'Company Name'];

export const imageCompressionOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const CreatedAtInput = forwardRef((props: any, ref) => {
  return <Input {...props} />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

export default CreatedAtInput;

export enum TimeLineStatus {
  'Work Started' = 0,
  'Work Paused' = 1,
  'Work Ended' = 2,
  'Emergency Work Stopped' = 3,
  'Hazard Reported' = 4,
  'Worked Resumed' = 5,
  'Shift Started' = 6,
  'Shift Ended' = 7,
}
