import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import HazardTypeResource from 'api/hazard_type';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { HazardTypeSchema } from 'constants/schema';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';

interface Props {
  hazardTypeData: HazardTypeSchema;
  index: number;
}
const HazardTypeListItem: React.FC<Props> = (props) => {
  const { hazardTypeData, index } = props;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const hazardTypeAPI = new HazardTypeResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteHazardType = useMutation(
    () => hazardTypeAPI.destroy(hazardTypeData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('hazardTypeList');

        if (res.data[0].success) {
          setDeleteModalOpen(false);
          toast({
            title: strings.hazard_type_delete_success_msg,
            status: 'success',
            isClosable: true,
          });
        }
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteHazardType.mutate();
  };

  return (
    <Tr key={hazardTypeData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.hazards.hazardType.view.replace(
            ':id',
            hazardTypeData?.id.toString()
          )}>
          {hazardTypeData?.name || '-'}
        </RouterLink>
      </Td>
      <Td>{truncateString(hazardTypeData?.description) || '-'}</Td>
      <Td>
        <RouterLink
          to={routes.hazards.hazardType.view.replace(
            ':id',
            hazardTypeData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_hazard_type}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_hazard_type}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.hazards.hazardType.edit.replace(
            ':id',
            hazardTypeData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit_hazard_type}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_hazard_type}
              color="blue.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
        {/* <AccessControl
          allowedPermissions={[
            PermissionRequest['read:hazard'],
            PermissionRequest['manage:hazard'],
          ]} 
          renderNoAccess={(data: any) => data}> */}
        <Tooltip hasArrow label={strings.delete_hazard_type}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_hazard_type}
            color="red.300"
            size="lg"
            onClick={onDeletePress}
          />
        </Tooltip>
        {/* </AccessControl> */}
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_hazard_type}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteHazardType.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default HazardTypeListItem;
