import Resource from 'api/resource';
import http from 'utils/http';
class WorkTypeResource extends Resource {
  constructor() {
    super('work-types/');
  }

  itemList(query?: any) {
    return http({
      url: 'work-types/',
      method: 'get',
      params: query,
    });
  }

  workTypeStepStore(resource: any) {
    return http({
      url: '/work-type-company-steps/',
      method: 'post',
      data: resource,
    });
  }

  getWorkTypeStep(query?: any) {
    return http({
      url: '/work-type-company-steps/',
      method: 'get',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }

  updateWorkTypeStep(id: any, resource: any) {
    return http({
      url: '/edit-work-type-step/' + id + '/',
      method: 'put',
      data: resource,
    });
  }

  destroyWorkTypeStep(id: any) {
    return http({
      url: '/work-type-company-steps/' + id + '/',
      method: 'delete',
    });
  }
}

export { WorkTypeResource as default };
