import { Link, ListItem } from '@chakra-ui/react';
import { strings } from 'config/localization';
import {
  THEME_ANNOUNCEMENT,
  THEME_CHAT,
  THEME_COMPANY,
  THEME_FAQ,
  THEME_FIELD_REPORT,
  THEME_HAZARD,
  THEME_LOCATION,
  THEME_MAP_FEATURE,
  THEME_PPE,
  THEME_REPORTS,
  THEME_SETTING,
  THEME_TASK,
  THEME_USER,
} from 'constants/common';
import routes from 'constants/routes';
import { ThemeContext } from 'context/ThemeContext';
import React, { useContext } from 'react';
import { matchPath } from 'react-router-dom';
import HideControl from 'services/HideControl';
import history from 'utils/history';

const linkStyles = {
  px: 2,
  py: 2,
  rounded: 'md',
};

const activeLinkStyles = {
  ...linkStyles,
  bg: 'rgba(255,255,255, 0.2)',
};

function getStyle(path: string) {
  let match = matchPath(location.pathname, {
    path: path,
  });
  return match?.isExact ? activeLinkStyles : linkStyles;
}

export const UserSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.users.list)}
          onClick={() => {
            setTheme(THEME_USER);
            history.push(routes.users.list);
          }}>
          {strings.user_management}
        </Link>
      </ListItem>
      <HideControl
        hideFor="deactivate-request-service"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.users.deactivateRequest.base)}
            onClick={() => {
              setTheme(THEME_USER);
              history.push(routes.users.deactivateRequest.list);
            }}>
            {strings.deactivate_user_request} +1
          </Link>
        </ListItem>
      </HideControl>
    </>
  );
};

export const CompaniesSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.companies.base)}
          onClick={() => {
            setTheme(THEME_COMPANY);
            history.push(routes.companies.list);
          }}>
          {strings.company_list}
        </Link>
      </ListItem>
    </>
  );
};

export const LocationSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.locations.base)}
          onClick={() => {
            setTheme(THEME_LOCATION);
            history.push(routes.locations.list);
          }}>
          {strings.location_list}
        </Link>
      </ListItem>
    </>
  );
};

export const TaskSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <HideControl
        hideFor="work-type-list"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.workType.base)}
            onClick={() => {
              setTheme(THEME_TASK);
              history.push(routes.workType.list);
            }}>
            {strings.work_type_list}
          </Link>
        </ListItem>
      </HideControl>
      <HideControl
        hideFor="sop-list"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.sops.base)}
            onClick={() => {
              setTheme(THEME_TASK);
              history.push(routes.sops.list);
            }}>
            {strings.sops_list}
          </Link>
        </ListItem>
      </HideControl>
    </>
  );
};

export const PPESubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.ppe.base)}
          onClick={() => {
            setTheme(THEME_PPE);
            history.push(routes.ppe.list);
          }}>
          {strings.ppe_list}
        </Link>
      </ListItem>
    </>
  );
};

export const HazardSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.hazards.hazard.base)}
          onClick={() => {
            setTheme(THEME_HAZARD);
            history.push(routes.hazards.hazard.list);
          }}>
          {strings.hazard_list}
        </Link>
      </ListItem>
      <ListItem>
        <Link
          sx={getStyle(routes.hazards.hazardType.base)}
          onClick={() => {
            setTheme(THEME_HAZARD);
            history.push(routes.hazards.hazardType.list);
          }}>
          {strings.hazard_type}
        </Link>
      </ListItem>
    </>
  );
};

export const AnnouncementSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.announcements.base)}
          onClick={() => {
            setTheme(THEME_ANNOUNCEMENT);
            history.push(routes.announcements.list);
          }}>
          {strings.announcement_list}
        </Link>
      </ListItem>
    </>
  );
};

export const ChatSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.chats.base)}
          onClick={() => {
            setTheme(THEME_CHAT);
            history.push(routes.chats.list);
          }}>
          {strings.chats}
        </Link>
      </ListItem>
    </>
  );
};

export const FAQSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.faqs.base)}
          onClick={() => {
            setTheme(THEME_FAQ);
            history.push(routes.faqs.list);
          }}>
          {strings.faqs}
        </Link>
      </ListItem>
    </>
  );
};

export const MAPFeatureSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.mapFeatures.view)}
          onClick={() => {
            setTheme(THEME_MAP_FEATURE);
            history.push(routes.mapFeatures.view);
          }}>
          {strings.map_features}
        </Link>
      </ListItem>
    </>
  );
};

export const ReportSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <HideControl
        hideFor="company-reports"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.reports.company)}
            onClick={() => {
              setTheme(THEME_REPORTS);
              history.push(routes.reports.company);
            }}>
            {strings.company_report}
          </Link>
        </ListItem>
      </HideControl>
      <HideControl
        hideFor="employee-reports"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.reports.employee)}
            onClick={() => {
              setTheme(THEME_REPORTS);
              history.push(routes.reports.employee);
            }}>
            {strings.employee_report}
          </Link>
        </ListItem>
      </HideControl>
      {/* <ListItem>
        <Link
          sx={getStyle(routes.reports.vendor)}
          onClick={() => {
            setTheme(THEME_REPORTS);
            history.push(routes.reports.vendor);
          }}>
          Vendor Report
        </Link>
      </ListItem> */}
      {/* <ListItem>
        <Link
          sx={getStyle(routes.reports.user)}
          onClick={() => {
            setTheme(THEME_REPORTS);
            history.push(routes.reports.user);
          }}>
          User Report
        </Link>
      </ListItem> */}
      <HideControl
        hideFor="jsa-reports"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.reports.jsa.base)}
            onClick={() => {
              setTheme(THEME_REPORTS);
              history.push(routes.reports.jsa.list);
            }}>
            {strings.jsa}
          </Link>
        </ListItem>
      </HideControl>
      {/* <HideControl
        hideFor="job-summaries-reports"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.reports.jobSummaries.base)}
            onClick={() => {
              setTheme(THEME_REPORTS);
              history.push(routes.reports.jobSummaries.list);
            }}>
            Job Summaries
          </Link>
        </ListItem>
      </HideControl> */}
    </>
  );
};

export const SettingSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <ListItem>
        <Link
          sx={getStyle(routes.settings.base)}
          onClick={() => {
            setTheme(THEME_SETTING);
            history.push(routes.settings.rolePermissions.list);
          }}>
          {strings.role_and_permissions}
        </Link>
      </ListItem>
    </>
  );
};

export const FieldReportingSubMenuList: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <HideControl
        hideFor="environment-tracker"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.fieldReporting.environmentTracker.base)}
            onClick={() => {
              setTheme(THEME_FIELD_REPORT);
              history.push(routes.fieldReporting.environmentTracker.list);
            }}>
            {strings.environment_tracker}
          </Link>
        </ListItem>
      </HideControl>
      <HideControl
        hideFor="field-type"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.fieldReporting.fieldType.base)}
            onClick={() => {
              setTheme(THEME_FIELD_REPORT);
              history.push(routes.fieldReporting.fieldType.list);
            }}>
            {strings.field_type}
          </Link>
        </ListItem>
      </HideControl>
      <HideControl
        hideFor="user-field-report"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <ListItem>
          <Link
            sx={getStyle(routes.fieldReporting.userFieldReport.base)}
            onClick={() => {
              setTheme(THEME_FIELD_REPORT);
              history.push(routes.fieldReporting.userFieldReport.list);
            }}>
            {strings.user_field_report}
          </Link>
        </ListItem>
      </HideControl>
    </>
  );
};
