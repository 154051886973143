import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import { getFullName } from 'utils/esg';

interface Props {
  crewMembersData: any;
}

const JSACrewMembers: React.FC<Props> = (props) => {
  const { crewMembersData } = props;

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.crew_member}
        </Heading>
      </Flex>
      <Box
        bg="white"
        p={['3', '6']}
        shadow="box"
        rounded="sm"
        marginTop={3}
        marginBottom={3}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          {crewMembersData.map((crewData: any) => (
            <GridItem key={crewData?.id}>
              <FormControl>
                <FormLabel sx={labelStyles}>{getFullName(crewData)}</FormLabel>
              </FormControl>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default JSACrewMembers;
