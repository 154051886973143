import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import PermissionResource from 'api/permissions';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { truncateString } from 'utils';
import history from 'utils/history';

const ViewRolePermissions: React.FC = () => {
  const { id }: any = useParams();
  const permissionAPI = new PermissionResource();

  const permissionQuery = useQuery<any>(
    [`role-permission-${id}`, id],
    () =>
      permissionAPI.get(id).then((res) => {
        return res?.data?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (permissionQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (permissionQuery.isError) {
    history.push(routes.settings.rolePermissions.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.settings}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.settings.rolePermissions.list}>
              {strings.role_and_permissions}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {truncateString(permissionQuery?.data?.name || '')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.permissions}
          </Heading>
          <Stack direction="row" spacing="6">
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() =>
                history.push(
                  routes.settings.rolePermissions.edit.replace(':id', id)
                )
              }>
              {strings.edit_permissions}
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.role}</FormLabel>
                <Box sx={infoStyles}>
                  {strings.getString(
                    permissionQuery?.data?.name
                      .toLowerCase()
                      .split(' ')
                      .join('_')
                  )}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
          {permissionQuery?.data?.permission && (
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(1, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.permissions}</FormLabel>
                  <Box sx={infoStyles}>
                    <Grid
                      gap="4"
                      templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
                      flex="1">
                      {permissionQuery?.data?.permission.map(
                        (permission: any) => (
                          <GridItem key={permission}>
                            <Text style={{ textTransform: 'capitalize' }}>
                              {permission.split('_').join(' ')}
                            </Text>
                          </GridItem>
                        )
                      )}
                    </Grid>
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default ViewRolePermissions;
