import Resource from 'api/resource';
import http from 'utils/http';
class FieldReportingResource extends Resource {
  constructor() {
    super('field-report/');
  }

  itemList(query?: any) {
    return http({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    });
  }

  create(resource: any, headers?: any) {
    return http({
      url: '/field-report/',
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  fieldType(id:number, query?:any) {
    return http({
      url: `/field-report/${id}/field-type/`,
      method: 'get',
      params: query,
    });
  }
}

export { FieldReportingResource as default };
