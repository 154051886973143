import { useToast } from '@chakra-ui/react';
import { setChatItemList } from 'actions/data/chat';
import ChatResource from 'api/chat';
import CompanyResource from 'api/company';
import { MapLogo } from 'assets/images';
import { strings } from 'config/localization';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as authService from 'services/auth';
import { onMessageListener } from 'services/firebase';
import { socket } from 'services/socket';
import { groupArrayOfObjects, truncateString } from 'utils';
import { getFullName } from 'utils/esg';

async function displayNotification(title: string, options: any) {
  if (Notification.permission === 'granted') {
    navigator.serviceWorker.ready.then(function (registration) {
      registration.showNotification(title, options);
    });
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification(title, options);
        });
      }
    });
  }
}

const ChatRegister = () => {
  const companyAPI = new CompanyResource();
  const chatAPI = new ChatResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const location = useLocation();
  const { chatItemList, loggedInUser } = useSelector(
    (state: any) => ({
      chatItemList: state?.data?.chat.chatItemList,
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  onMessageListener()
    .then((payload: any) => {
      toast({
        title: payload.notification.title,
        description: payload.notification.body,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    })
    .catch((err) => window.console.log('failed: ', err));

  /**
   * Update Chat
   */
  useEffect(() => {
    socket.on('message_recieved', (data: any) => {
      if (loggedInUser.id !== data?.data?.user?.id) {
        queryClient.invalidateQueries('fetchSearchChatUser');
        let title = `${strings.new_message_from} ${getFullName(
          data.data?.user
        )}`;

        let options = {
          body: data?.data?.text,
          icon: MapLogo,
        };
        toast({
          title: title,
          description: truncateString(data?.data?.text),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        displayNotification(title, options);
      }
    });

    return () => {
      socket.off('message_recieved');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, loggedInUser.id]);

  useEffect(() => {
    socket.emit(
      'authenticate',
      {
        token: authService.getAccessToken(),
      },
      (cb: any) => {}
    );
  });

  /**
   * Get Chat Users
   */
  const fetchSearchChatUser = async () => {
    const chatLists = await chatAPI.currentConversation();
    // const chatLists = await companyAPI.companyMember();
    chatLists?.data?.map((res: any) => {
      socket.emit(
        'join_chat',
        {
          conversation_id: res.conversation_id,
        },
        (cb: any) => {}
      );
    });
    const grpByUnreadMessage = groupArrayOfObjects(chatLists?.data, 'is_seen');
    let finalChatData: any = [];
    const unSeenChatData = grpByUnreadMessage['false'];
    if (unSeenChatData) {
      finalChatData = [...finalChatData, ...unSeenChatData];
    }
    const readChatData = grpByUnreadMessage['true'];
    // for safety purpose
    const readAlreadyChatData = grpByUnreadMessage['null'];
    if (readChatData) finalChatData = [...finalChatData, ...readChatData];
    if (readAlreadyChatData)
      finalChatData = [...finalChatData, ...readAlreadyChatData];
    dispatch(setChatItemList(finalChatData));
  };

  const { isLoading } = useQuery('fetchSearchChatUser', () =>
    fetchSearchChatUser()
  );
  return null;
};
export default ChatRegister;
