import routes from 'constants/routes';
import { Login } from 'pages/auth';
import ForgotPassword from 'pages/auth/ForgotPassword';
import Register from 'pages/auth/Register';
import ResetPassword from 'pages/auth/ResetPassword';
import SetPassword from 'pages/auth/SetPassword';
import { DownloadPage } from 'pages/Common';
import { LocationDetailsExternal } from 'pages/LocationExternal';
import Dashboard from 'pages/dashboard';
import React from 'react';
import { Route, Router as BrowserRouter, Switch } from 'react-router-dom';
import history from 'utils/history';
import AuthenticateRoute from './AuthenticateRoute';

// Top level application router.
const Router = () => (
  <BrowserRouter history={history}>
    <Switch>
      <Route path={routes.appDownloads} component={DownloadPage} />
      <Route path={routes.locationExternal} component={LocationDetailsExternal} />
      <Route path={routes.auth.login} component={Login} />
      <Route path={routes.auth.forgotPassword} component={ForgotPassword} />
      <Route path={routes.auth.resetPassword} component={ResetPassword} />
      <Route path={routes.auth.setPassword} component={SetPassword} />
      <Route path={routes.auth.register} component={Register} />
      <AuthenticateRoute path={routes.dashboard} component={Dashboard} />
    </Switch>
  </BrowserRouter>
);

export default Router;
