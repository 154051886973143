import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FieldReportingResource from 'api/field_reporting';
import FieldReportingForm from 'components/fieldReportings/fieldReportingForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const CreateFieldReporting: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<any>();
  const fieldReportAPI = new FieldReportingResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addFieldReport = useMutation(
    (data: any) => fieldReportAPI.create(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.field_report_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('fieldReportList');
        history.push(routes.fieldReporting.environmentTracker.list);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrorMessage(error?.response?.data[objKey][0]);
        else setErrorMessage(strings.field_report_err_msg);
      },
    }
  );

  const onSubmit = async (data: any) => {
    // TO DO: Fix field type right now is sent as whole object of unit. Need to change that to accepted type. if we send as is it will save null in field (destroys the list page and so on)
    const fieldsData = await data?.field.map((res: any) => res.value);
    const jsonData = {
      name: data?.name,
      company: data?.company?.value,
      description: data?.description,
      fields: fieldsData,
    };
    addFieldReport.mutate(jsonData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.fieldReporting.environmentTracker.list}>
            {strings.field_reporting}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.fieldReporting.environmentTracker.create}>
            {strings.add_new_field_report}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.add_new_field_report}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addFieldReport.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <FieldReportingForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addFieldReport.isLoading}>
                  {strings.add_field_report}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateFieldReporting;
