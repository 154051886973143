import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import FieldReportResource from 'api/field_reporting';
import FieldTypeResource from 'api/field_type';
import UserFieldReportResource from 'api/user_field_report';
import {
  activeStyles,
  normalStyles,
  reactSelectStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import UserFieldReportListItem from 'components/userFieldReport/UserFieldReportListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import ReactSelect from 'react-select';
import { getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  search: string;
  company: string;
  searchCompany: string;
  fieldReport: string;
  searchFieldReport: string;
  fieldType: string;
  searchFieldType: string;
}

const UserFieldReportList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const userFieldReportAPI = new UserFieldReportResource();

  const [fieldReportFieldType, setFieldReportFieldType] = useState(0);
  const [totalUserFieldReport, setTotalUserFieldReport] = useState<
    number | undefined
  >(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '',
    company: '',
    searchCompany: '',
    fieldReport: '',
    searchFieldReport: '',
    fieldType: '',
    searchFieldType: '',
  });

  const companyAPI = new CompanyResource();
  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [`company-list`, { search: filterParams.searchCompany }],
    () =>
      companyAPI
        .allList({ search: filterParams.searchCompany, limit: 50 })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalUserFieldReport,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: userFieldReportList, isLoading: isUserFieldReportListLoading } =
    useQuery(
      [
        'userFieldReportList',
        {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
          search: filterParams.search,
          company: filterParams.company,
          field_report: filterParams.fieldReport,
          field_type: filterParams.fieldType,
        },
      ],
      async () => {
        const queryParams: any = {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
          search: filterParams.search,
          company: filterParams.company,
          field_report: filterParams.fieldReport,
          field_type: filterParams.fieldType,
        };
        const response = await userFieldReportAPI.list(queryParams);
        setTotalUserFieldReport(response?.data?.data?.meta?.total);
        response && setIsLoading(null);
        return response?.data?.data;
      }
    );

  const methods = useForm<any>();

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      searchCompany: value,
    }));
  };

  // Field Report List
  const fieldReport = new FieldReportResource();
  const { data: fieldReportQuery, isLoading: isFieldReportLoading } = useQuery(
    [`field-report-list`, { search: filterParams.searchFieldReport }],
    () =>
      fieldReport
        .itemList({ search: filterParams.searchFieldReport, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const fieldReportList = fieldReportQuery?.results;
  const fieldReportOptions = fieldReportList?.map((fieldReport: any) => ({
    label: fieldReport.name,
    value: fieldReport.id,
  }));

  // Field Type List
  const fieldType = new FieldTypeResource();
  const { data: fieldTypeQuery, isLoading: isFieldTypeLoading } = useQuery(
    [`field-type-list`, { field_report: fieldReportFieldType }],
    () =>
      fieldType
        .itemList({ field_report: fieldReportFieldType, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const fieldTypeList = fieldTypeQuery?.results;
  const fieldTypeOptions = fieldTypeList?.map((fieldType: any) => ({
    label: fieldType.title,
    value: fieldType.id,
  }));

  const handleFieldReportChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      searchFieldReport: value,
    }));
  };

  const handleFieldTypeChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      searchFieldType: value,
    }));
  };

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: data.search,
      company: data.company?.value,
      fieldReport: data.field_report?.value,
      fieldType: data.field_type?.value,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    methods.setValue('company', '');
    methods.setValue('field_report', '');
    methods.setValue('field_type', '');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: '',
      company: '',
      searchCompany: '',
      fieldReport: '',
      searchFieldReport: '',
      fieldType: '',
      searchFieldType: '',
    }));
    setFieldReportFieldType(0);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleFilterFieldType = (fieldReport: any) => {
    if (fieldReport.value) {
      methods.setValue('field_type', '');
      setFieldReportFieldType(fieldReport.value);
    }
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.user_field_report}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.fieldReporting.userFieldReport.list}>
              {strings.all_user_field_report}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.user_field_report_list}</Heading>
        </Flex>
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <Input
                  type="text"
                  placeholder={strings.search}
                  {...methods.register('search')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.company}</FormLabel>
                <Controller
                  control={methods.control}
                  name="company"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="company"
                      isLoading={isCompanyLoading}
                      placeholder={strings.select}
                      options={companyOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onInputChange={handleCompanyChange}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.field_report}</FormLabel>
                <Controller
                  control={methods.control}
                  name="field_report"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="field_report"
                      isLoading={isFieldReportLoading}
                      placeholder={strings.select}
                      options={fieldReportOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      // onInputChange={handleFieldReportChange}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        handleFilterFieldType(selectedOption);
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.field_type}</FormLabel>
                <Controller
                  control={methods.control}
                  name="field_type"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="field_type"
                      isLoading={isFieldTypeLoading}
                      placeholder={strings.select}
                      options={fieldTypeOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onInputChange={handleFieldTypeChange}
                    />
                  )}
                />
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.company}</Th>
                  <Th>{strings.field_report}</Th>
                  <Th>{strings.field_type}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isUserFieldReportListLoading &&
                  userFieldReportList?.results?.map(
                    (userFieldReportData: any, index: number) => (
                      <UserFieldReportListItem
                        userFieldReportData={userFieldReportData}
                        key={userFieldReportData.id}
                        index={startingSN + index}
                        page="user-field-report"
                      />
                    )
                  )}
                {isUserFieldReportListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isUserFieldReportListLoading}
              totalCount={totalUserFieldReport}
              resultCount={userFieldReportList?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {userFieldReportList?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isUserFieldReportListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default UserFieldReportList;
