import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  workTypeStepData?: any;
}

const WorkTypeStepForm: React.FC<Props> = (props) => {
  const { workTypeStepData } = props;
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<any>();
  const companyAPI = new CompanyResource();

  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '', // for company List
  });

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [
      `company-list`,
      { search: filterParams.search, is_active: true, is_approved: true },
    ],
    () =>
      companyAPI
        .itemList({ search: filterParams.search, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.results;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      search: value,
    }));
  };

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
          {!workTypeStepData?.id && (
            <GridItem>
              <FormControl isInvalid={!!errors?.company} isRequired>
                <FormLabel>{strings.company}</FormLabel>
                <Controller
                  control={control}
                  name="company"
                  rules={{
                    required: strings.select_company,
                  }}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="company"
                      value={field.value ? field.value : null}
                      placeholder={strings.select_company}
                      isLoading={isCompanyLoading}
                      options={companyOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onInputChange={handleCompanyChange}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors?.company && errors?.company?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          )}
          <GridItem>
            <FormControl isInvalid={!!errors?.operating_procedure} isRequired>
              <FormLabel>{strings.operating_procedure}</FormLabel>
              <Input
                {...register('operating_procedure', {
                  required: strings.operating_procedure,
                })}
                type="text"
                defaultValue={workTypeStepData?.operating_procedure}
                placeholder={strings.operating_procedure}
              />
              <FormErrorMessage>
                {errors?.operating_procedure &&
                  errors?.operating_procedure?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.hazard_identification}>
              <FormLabel>{strings.hazard_identification}</FormLabel>
              <Input
                {...register('hazard_identification')}
                type="text"
                defaultValue={workTypeStepData?.hazard_identification}
                placeholder={strings.hazard_identification}
              />
              <FormErrorMessage>
                {errors?.hazard_identification &&
                  errors?.hazard_identification?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.hazard_relief}>
              <FormLabel>{strings.hazard_relief}</FormLabel>
              <Input
                {...register('hazard_relief')}
                type="text"
                defaultValue={workTypeStepData?.hazard_relief}
                placeholder={strings.hazard_relief}
              />
              <FormErrorMessage>
                {errors?.hazard_relief && errors?.hazard_relief?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default WorkTypeStepForm;
