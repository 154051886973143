import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import { AnnouncementSchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import HideControl from 'services/HideControl';
import { validURL } from 'utils/validate';

interface Props {
  announcementData?: AnnouncementSchema;
}

const AnnouncementForm: React.FC<Props> = (props) => {
  const { announcementData } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<AnnouncementSchema>();

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.title} isRequired>
              <FormLabel>{strings.title}</FormLabel>
              <Input
                {...register('title', {
                  required: strings.required,
                })}
                type="text"
                defaultValue={announcementData?.title}
                placeholder={strings.title}
              />
              <FormErrorMessage>
                {errors?.title && errors?.title?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.link}>
              <FormLabel>{strings.link}</FormLabel>
              <Input
                {...register('link', {
                  validate: (value) => {
                    if (value)
                      return validURL(value) || strings.valid_url_address_msg;
                  },
                })}
                type="text"
                defaultValue={announcementData?.link}
                placeholder={strings.link}
              />
              <FormErrorMessage>
                {errors?.link && errors?.link?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.description} isRequired>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description', {
                  required: strings.required,
                })}
                defaultValue={announcementData?.description}
                placeholder={strings.write_here}
              />
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.upload_image}</FormLabel>
              <ImageUploader
                description={strings.upload_image}
                uploadedFiles={announcementData?.image}
                accept="image/*"
                fileKey="image"
              />
            </FormControl>
          </GridItem>
          <HideControl
            hideFor="announcement-check-list"
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            <GridItem>
              <Stack spacing={[1, 5]} direction={['column', 'row']}>
                <Checkbox
                  {...register('company_feed')}
                  size="lg"
                  defaultChecked={announcementData?.company_feed}
                  colorScheme="primary">
                  {strings.company_feed}
                </Checkbox>
                <Checkbox
                  {...register('jobsite_feed')}
                  size="lg"
                  defaultChecked={announcementData?.jobsite_feed}
                  colorScheme="primary">
                  {strings.jobsite_feed}
                </Checkbox>
                <Checkbox
                  {...register('all_users')}
                  size="lg"
                  defaultChecked={announcementData?.all_users}
                  colorScheme="primary">
                  {strings.all_user}
                </Checkbox>
              </Stack>
            </GridItem>
          </HideControl>
        </Grid>
      </Stack>
    </form>
  );
};

export default AnnouncementForm;
