import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import ChatBot from 'components/chat/ChatBot';
import ChatSearch from 'components/chat/ChatSearch';
import ChatUserDetails from 'components/chat/ChatUserDetails';
import { strings } from 'config/localization';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { socket } from 'services/socket';

const Chat: React.FC = () => {
  const { currentChatUserDetails } = useSelector(
    (state: any) => ({
      currentChatUserDetails: state?.data?.chat.currentChatUser,
    }),
    shallowEqual
  );

  /**
   * Check Room
   */
  useEffect(() => {
    if (currentChatUserDetails?.conversation) {
      socket.emit(
        'join_chat',
        {
          conversation_id: currentChatUserDetails?.conversation,
        },
        (cb: any) => {}
      );
    }
  }, [currentChatUserDetails]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.chat}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Heading size="md" textTransform="capitalize">
          {strings.chat}
        </Heading>
      </Flex>
      <Stack direction="row" spacing="4">
        <ChatSearch />
        <>
          {currentChatUserDetails.conversation && (
            <Stack direction="column" flex="1" spacing="2" pos="relative">
              <ChatUserDetails
                name={currentChatUserDetails.name}
                attachment={currentChatUserDetails?.attachment}
              />
              <ChatBot conversation={currentChatUserDetails.conversation} />
            </Stack>
          )}
        </>
      </Stack>
    </Stack>
  );
};

export default Chat;
