import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import HazardResource from 'api/hazard';
import { CenterSpinner } from 'components/common/CenterSpinner';
import HazardForm from 'components/hazard/HazardForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { GoogleGeoCode } from 'services/GoogleGeoCode';
import { buildFormData } from 'utils';

const EditHazard: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<any>();

  const hazardAPI = new HazardResource();

  const hazardQuery = useQuery(
    [`hazard${id}-edit`, id],
    () => hazardAPI.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateHazard = useMutation((data: any) => hazardAPI.update(id, data));

  const onSubmit = async (data: any) => {
    const address = await GoogleGeoCode(data?.latitude, data?.longitude);
    const jsonData = {
      hazard_type_id: data?.hazard_type?.value,
      // is_ammonium_present: data?.is_ammonium_present,
      additional_notes: data?.additional_notes,
      location: JSON.stringify({
        lat: data?.latitude,
        lng: data?.longitude,
        city: address?.city || '',
        state: address?.state || '',
        address: address?.address || '',
        zip: '',
      }),
    };
    const formData = buildFormData(jsonData);
    updateHazard.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.hazard_updated_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('hazardList');
        history.push(routes.hazards.hazard.list);
      },
    });
  };

  if (hazardQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (hazardQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.hazard_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.hazards.hazard.list}>
            {strings.hazard}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.hazards.hazard.edit}>
            {strings.edit_hazard}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_hazard}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateHazard.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.hazard_update_failed}
                </Alert>
              )}
              <HazardForm hazardData={hazardQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateHazard.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditHazard;
