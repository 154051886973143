import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import {DateFormatMDY, truncateString } from 'utils';

interface Props {
  faqData: any;
  index: number;
}
const FAQListItem: React.FC<Props> = (props) => {
  const { faqData, index } = props;
  const [isModalOpen, setModalOpen] = useState(false);

  const onModalPress = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Tr key={faqData.id}>
      <Td>{index}</Td>
      <Td>
        <Text fontWeight="semibold" onClick={onModalPress}>
          {truncateString(faqData?.description)}
        </Text>
        <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.faq}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{faqData?.description}</ModalBody>
          </ModalContent>
        </Modal>
      </Td>
      <Td>{DateFormatMDY(faqData?.date)}</Td>
    </Tr>
  );
};

export default FAQListItem;
