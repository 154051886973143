import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import LocationResource from 'api/location';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { truncateString } from 'utils';
import history from 'utils/history';

const ViewLocation: React.FC = () => {
  const { id }: any = useParams();
  const locationApi = new LocationResource();

  const locationQuery = useQuery<any>(
    [`location${id}`, id],
    () => locationApi.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (locationQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (locationQuery.isError) {
    history.push(routes.locations.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.locations.list}>
              {strings.location}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {truncateString(locationQuery?.data?.name || '')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.location_details}
          </Heading>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.name}</FormLabel>
                <Box sx={infoStyles}>{locationQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.coordinate}</FormLabel>
                <Box sx={infoStyles}>
                  {locationQuery?.data?.geo_location?.lat},{' '}
                  {locationQuery?.data?.geo_location?.lng}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
                <Box sx={infoStyles}>
                  {locationQuery?.data?.company?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.operator}</FormLabel>
                <Box sx={infoStyles}>
                  {locationQuery?.data?.company?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.location_contact_name}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationQuery?.data?.location_contact_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.location_contact}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationQuery?.data?.location_contact || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.customer_contact_name}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationQuery?.data?.customer_contact_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.customer_contact}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationQuery?.data?.customer_contact || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.qr_code}
                </FormLabel>
                <Box sx={infoStyles}>
                  <img src={locationQuery?.data?.qr_code} />
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewLocation;
