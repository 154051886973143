import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import AnnouncementResource from 'api/announcement';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { AnnouncementSchema } from 'constants/schema';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { truncateString } from 'utils';

interface Props {
  announcementData: AnnouncementSchema;
  index: number;
}
const AnnouncementListItem: React.FC<Props> = (props) => {
  const { announcementData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const announcementAPI = new AnnouncementResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteAnnouncement = useMutation(
    () => announcementAPI.destroy(announcementData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('announcementList');

        if (res.data[0].success) {
          setDeleteModalOpen(false);
          toast({
            title: strings.announcement_delete_success_msg,
            status: 'success',
            isClosable: true,
          });
        }
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteAnnouncement.mutate();
  };

  return (
    <Tr key={announcementData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.announcements.view.replace(
            ':id',
            announcementData?.id.toString()
          )}>
          {announcementData?.title || '-'}
        </RouterLink>
      </Td>
      <Td>
        <Link href={announcementData?.link} isExternal>
          {truncateString(announcementData?.link) || '-'}
        </Link>
      </Td>
      <Td>{truncateString(announcementData?.description)}</Td>
      <Td>{announcementData?.written_by || '-'}</Td>
      <Td>
        <RouterLink
          to={routes.announcements.view.replace(
            ':id',
            announcementData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_announcement}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_announcement}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.announcements.edit.replace(
            ':id',
            announcementData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit_announcement}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_announcement}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <AccessControl
          allowedPermissions={[
            PermissionRequest['read:announcement'],
            PermissionRequest['manage:announcement'],
          ]}
          renderNoAccess={(data: any) => data}>
          <Tooltip hasArrow label={strings.delete_announcement}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_announcement}
              color="red.300"
              size="lg"
              onClick={onDeletePress}
            />
          </Tooltip>
        </AccessControl>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_announcement}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteAnnouncement.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default AnnouncementListItem;
