import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MapFeatureResource from 'api/map_feature';
import { CenterSpinner } from 'components/common/CenterSpinner';
import MapContainer from 'components/common/MapContainer';
import JSAForm from 'components/mapFeatures/JSAForm';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getIcon } from 'utils/mapIcon';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  lat: any;
  lng: any;
  company_id: any;
}

const MapFeaturePage: React.FC = () => {
  const toast = useToast();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [centers, setCenters] = useState({
    lat: 0,
    lng: 0,
  });
  const [scrollEl, setScrollEl] = useState<any>();
  const JSADiv = useRef<HTMLDivElement>(null);
  const [mapDatas, setMapDatas] = useState([]);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    lat: 0,
    lng: 0,
    company_id: 0,
  });
  const [jsaData, setJSAData] = useState<any>();
  const [hazardData, setHazardData] = useState<any>();
  const [hasHazard, setHasHazard] = useState(false);
  const [hasCrew, setHasCrew] = useState(false);
  const [crewData, setCrewData] = useState<any>();
  const [hasCompanyUser, setHasCompanyUser] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const mapFeatureAPI = new MapFeatureResource();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setFilterParams((prevState) => ({
          ...prevState,
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        }));
        setLatitude(location.coords.latitude);
        setLongitude(location.coords.longitude);
      },
      (err) => {
        toast({
          title: strings.enable_your_location,
          status: 'info',
          isClosable: true,
        });
        setLatitude(31.3444822);
        setLongitude(-100.7327799);
      }
    );
    // eslint-disable-next-line
  }, []);

  const { data: mapList, isLoading: isMapLoading } = useQuery(
    ['pinLocationList'],
    async () => {
      const response = await mapFeatureAPI.pinLocation({
        lat: latitude,
        lng: longitude,
        page: 1,
        limit: 10000,
      });
      if (response?.data?.results.length > 0) {
        return response?.data?.results?.flatMap((res: any) => {
          return {
            lat: res?.location?.lat,
            lng: res?.location?.lng,
            name: res?.jobsite_name,
            signedJSA: res?.id,
            jobsite: res?.jobsite,
            geoLocation: res?.location,
            isClosed: res?.is_closed,
            companyId: res?.company,
            is_company_user: res?.is_company_user,
            is_crew_member: res?.is_crew_member,
            is_hazard: res?.is_hazard,
            url: `data:image/svg+xml;utf8,${getIcon(res)}`,
          };
        });
      }
      return response?.data?.data?.results;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchInterval: 60000, // 1m
    }
  );

  const handleJSA = async (data: any) => {
    setIsLoading(false);
    const jsaQuery = {
      lat: data.lat,
      lng: data.lng,
      jobsite: data.jobsite,
    };
    setJSAData(jsaQuery);

    setHasCrew(data.is_crew_member);
    setCrewData({ id: data.jobsite });

    setHazardData(jsaQuery);
    setHasHazard(data.is_hazard);

    setHasCompanyUser(data.is_company_user);
    setCompanyId(data.companyId);

    if (jsaData) JSADiv.current?.scrollIntoView({ behavior: 'smooth' });
    setIsLoading(true);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mapFeatures.view}>
              {strings.map_features}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.map_reports}
          </Heading>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <SimpleGrid position={'relative'}>
            <Box height={'80vh'}>
              {isMapLoading && <CenterSpinner />}
              {latitude !== 0 && longitude !== 0 && !isMapLoading && (
                <MapContainer
                  mapStyle={{
                    height: '75vh',
                    width: '100%',
                    position: 'relative',
                  }}
                  mapData={mapList}
                  center={{ lat: latitude, lng: longitude }}
                  draggable={false}
                  onMapDragEnd={true}
                  setLongitude={setLongitude}
                  setLatitude={setLatitude}
                  handleJSA={handleJSA}
                />
              )}
            </Box>
          </SimpleGrid>
          {jsaData?.jobsite && isLoading && (
            <SimpleGrid ref={JSADiv}>
              <JSAForm
                jsaData={jsaData}
                hasHazard={hasHazard}
                hazardData={hazardData}
                hasCrew={hasCrew}
                crewData={crewData}
                hasCompanyUser={hasCompanyUser}
                companyId={companyId}
              />
            </SimpleGrid>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default MapFeaturePage;
