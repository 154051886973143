import {
  Flex,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useMemo, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { DateFormat, DateFormatMDY, getStartingSerialNumber } from 'utils';
import { getFullName } from 'utils/esg';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

interface Props {
  query: any;
}

const CrewList: React.FC<Props> = (props) => {
  const { query } = props;
  const [isLoading, setIsLoading] = useState<string | null>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const userAPI = new UserResource();

  const [totalCrewList, setTotalCrewList] = useState<number | undefined>(
    undefined
  );

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalCrewList,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: crewListData, isLoading: isCrewListLoading } = useQuery(
    [
      'crewListData',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
      query,
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      const response = await userAPI.crewList(query.id, queryParams);
      setTotalCrewList(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data;
    }
  );

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Company</Th>
                  <Th>{strings.name}</Th>
                  <Th>Time JSA Started</Th>
                  <Th>JSA Active Status (Yes or No)</Th>
                  {/* <Th> Crew Active Status</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {!isCrewListLoading &&
                  crewListData?.results[0]?.members?.map((res: any, index: number) => {
                    return (
                      <Tr key={res?.id}>
                        <Td>{startingSN + index}</Td>
                        <Td>{res?.company}</Td>
                        <Td>{getFullName(res)}</Td>
                        <Td>{DateFormatMDY(res?.created_at)}</Td>
                        <Td>{res?.is_active ? strings.yes : strings.no}</Td>
                        {/* <Td>{res?.status ? strings.yes : strings.no}</Td> */}
                      </Tr>
                    );
                  })}
                {isCrewListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isCrewListLoading}
              totalCount={totalCrewList}
              resultCount={crewListData?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {crewListData?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isCrewListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default CrewList;
