import { Avatar, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
interface Props {
  name: string;
  attachment: string;
}

const ChatUserDetails: React.FC<Props> = (props) => {
  const { name, attachment } = props;
  return (
    <Stack p="4" bg="white" shadow="box" rounded="md">
      <Stack direction="row" spacing="6">
        <Avatar size="lg" showBorder shadow="lg" src={attachment} name={name} />
        <Stack direction="column" spacing="3">
          <Heading size="sm" fontWeight="medium" pt="4">
            {name}
          </Heading>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ChatUserDetails;
