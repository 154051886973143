import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import FieldReportResource from 'api/field_reporting';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  fieldTypeData?: any;
}

interface FilterParams {
  currentPage: number;
  pageSize: number;
  search: string;
}

const FieldTypeForm: React.FC<Props> = (props) => {
  const { fieldTypeData } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '', // for reporting List
  });

  useEffect(() => {
    if (!fieldTypeData) return;
    const field_report: any = {
      label: fieldTypeData?.field_report_detail?.name,
      value: fieldTypeData?.field_report_detail?.id,
    };
    setValue('field_report', field_report);
  }, [fieldTypeData, setValue]);

  const fieldReport = new FieldReportResource();

  const { data: fieldReportQuery, isLoading: isFieldReportLoading } = useQuery(
    [`company-list`, { search: filterParams.search }],
    () =>
      fieldReport
        .itemList({ search: filterParams.search, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const fieldReportList = fieldReportQuery?.results;
  const fieldReportOptions = fieldReportList?.map((fieldReport: any) => ({
    label: fieldReport.name,
    value: fieldReport.field_report_uuid,
  }));

  const handleFieldReportChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.title} isRequired>
            <FormLabel>{strings.title}</FormLabel>
            <Input
              {...register('title', {
                required: strings.required,
              })}
              type="text"
              defaultValue={fieldTypeData?.title}
              placeholder={strings.title}
            />
            <FormErrorMessage>
              {errors?.title && errors?.title?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.link}>
            <FormLabel>{strings.field_report}</FormLabel>

            <Controller
              control={control}
              name="field_report"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="field_report"
                  isLoading={isFieldReportLoading}
                  placeholder={strings.select_field_report}
                  options={fieldReportOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  onInputChange={handleFieldReportChange}
                />
              )}
            />
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default FieldTypeForm;
