import {
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import { DateFormatTime } from 'utils';

interface Props {
  timeLineData: any;
}

const JSATimeLine: React.FC<Props> = (props) => {
  const { timeLineData } = props;

  return (
    <>
      <Flex justify="space-between" marginTop={3}>
        <Heading size="md" textTransform="capitalize">
          {strings.time_line}
        </Heading>
      </Flex>
      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm" marginTop={3}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.date_time}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {timeLineData?.map((res: any, index: any) => (
                <>
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{res.user_name}</Td>
                    <Td>{res.timeline_status}</Td>
                    <Td>{DateFormatTime(res.start_time)}</Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default JSATimeLine;
