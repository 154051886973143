import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';

interface Props {
  fieldMeasurement: any;
  hasfieldMeasurementValue?: boolean;
}

interface FilterParams {
  currentPage: number;
  pageSize: number;
  search_company: string;
  search_field_report: string;
  search_field_type: string;
}

const FieldMeasurementList: React.FC<Props> = (props) => {
  const { fieldMeasurement, hasfieldMeasurementValue } = props;

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{strings.measurement}</Th>
            <Th>{strings.units}</Th>
            {!!hasfieldMeasurementValue && <Th>{strings.value}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {fieldMeasurement.map((data: any, index: number) => {
            return (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{data?.measurement || '-'}</Td>
                <Td>{data?.unit || '-'}</Td>
                {!!hasfieldMeasurementValue && <Td>{data?.value || '-'}</Td>}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default FieldMeasurementList;
