import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import WorkTypeResource from 'api/work_type';
import {
  activeStyles,
  normalStyles,
  reactSelectStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import WorkTypeStepForm from 'components/WorkType/WorkTypeStepForm';
import WorkTypeStepListItem from 'components/WorkType/WorkTypeStepListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { WorkTypeSchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { getStartingSerialNumber } from 'utils';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  search: string;
  searchCompany: string;
  work_type: string;
}

interface Props {
  id: number;
  company_detail: any;
}

const WorkTypeStepList: React.FC<Props> = (props) => {
  const { id, company_detail } = props;
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<string | null>();
  const [companyId, setCompanyId] = useState<any>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [refetch, setRefetch] = useState<number>(1);
  const workTypeAPI = new WorkTypeResource();
  const companyAPI = new CompanyResource();
  const queryClient = useQueryClient();

  const workTypeApi = new WorkTypeResource();

  const workTypeQuery = useQuery<any>(
    [`work-type${id}`, id],
    () => workTypeApi.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );


  const methodStep = useForm<any>();

  const [totalWorkType, setTotalWorkType] = useState<number | undefined>(
    undefined
  );
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '',
    searchCompany: '',
    work_type: id + '',
  });

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [`company-list`, { search: filterParams.searchCompany }],
    () =>
      companyAPI
        .allList({ search: filterParams.searchCompany, limit: 50 })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalWorkType,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: workTypeList, isLoading: isWorkTypeListLoading } = useQuery(
    [
      'workTypeStepList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.search,
        work_type: filterParams.work_type,
        company: companyId,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        work_type: filterParams.work_type,
        company: companyId,
      };
      if (filterParams.search) queryParams.search = filterParams.search;
      const response = await workTypeAPI.getWorkTypeStep(queryParams);
      setTotalWorkType(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: data.search,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset({
      company: '',
    });
    setCompanyId('');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: '',
      searchCompany: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchCompany: value,
    }));
  };

  const onModalClose = () => {
    setModalOpen(false);
    methodStep.setValue('operating_procedure', '');
    methodStep.setValue('hazard_identification', '');
    methodStep.setValue('hazard_relief', '');
  };

  const addWorkStep = useMutation(
    (data: any) => workTypeAPI.workTypeStepStore(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.work_type_step_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('workTypeStepList');
        onModalClose();
      },
    }
  );

  const onSubmitWorkStep = (data: any) => {
    const formData = {
      work_type: id,
      company: data.company.value,
      work_steps: {
        operating_procedure: data.operating_procedure,
        hazard_identification: data.hazard_identification,
        hazard_relief: data.hazard_relief,
      },
    };
    addWorkStep.mutate(formData);
  };

  const handleOnChangeCompany = (company: any) => {
    if (company) {
      setCompanyId(company);
    } else {
    }
  };

  const handleAddStep = () => {
    const company_id = company_detail.id
    if (company_id) {
      history.push(
        routes.workType.editStep
          .replace(':id', id + '')
          .replace(':companyId', company_id)
      );
    } else {
      toast({
        title: strings.select_company,
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.company}</FormLabel>
                <Text>{company_detail?.name || " "}</Text>
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                {/* <Button
                  isLoading={isLoading === 'filter'}
                  colorScheme="primary"
                  type="submit">
                  {strings.filter}
                </Button> */}
                <Button
                  colorScheme="primary"
                  isLoading={isLoading === 'reset'}
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleAddStep}
                  // onClick={() => setModalOpen(true)}
                >
                  {strings.add_step}
                </Button>
              </Stack>
            </Stack>
          </form>

          <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{strings.add_step}</ModalHeader>
              <ModalCloseButton />
              <FormProvider {...methodStep}>
                <form onSubmit={methodStep.handleSubmit(onSubmitWorkStep)}>
                  <ModalBody>
                    <WorkTypeStepForm />
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button colorScheme="primary" type="submit">
                        {strings.add_step}
                      </Button>
                      <Button variant="outline" onClick={onModalClose}>
                        {strings.cancel}
                      </Button>
                    </ButtonGroup>
                  </ModalFooter>
                </form>
              </FormProvider>
            </ModalContent>
          </Modal>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.step}</Th>
                  <Th>{strings.company}</Th>
                  <Th>{strings.operating_procedure}</Th>
                  <Th>{strings.hazard_identification}</Th>
                  <Th>{strings.hazard_relief}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isWorkTypeListLoading &&
                  workTypeList?.results?.map(
                    (workTypeData: WorkTypeSchema, index: number) => (
                      <WorkTypeStepListItem
                        workTypeStepData={workTypeData}
                        key={workTypeData.id}
                        index={startingSN + index}
                      />
                    )
                  )}
                {isWorkTypeListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isWorkTypeListLoading}
              totalCount={totalWorkType}
              resultCount={workTypeList?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {workTypeList?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isWorkTypeListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default WorkTypeStepList;
