import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import CompanyResourceResource from 'api/company';
import CustomTagTitle from 'components/common/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { CompanySchema } from 'constants/schema';
import React, { useState } from 'react';
import {
  BiEdit,
  BiListMinus,
  BiListPlus,
  BiShow,
  BiTrash,
} from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { truncateString } from 'utils';

interface Props {
  companyData: CompanySchema;
  index: number;
  user: any;
}
const CompanyListItem: React.FC<Props> = (props) => {
  const { companyData, index, user } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const companyAPI = new CompanyResourceResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteCompany = useMutation(() => companyAPI.destroy(companyData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('companyList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.company_delete_success_msg,
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteCompany.mutate();
  };

  const changeStatusCompany = useMutation(
    () =>
      companyAPI.changeStatus({
        id: companyData.id,
      }),
    {
      onSuccess: (res) => {
        const statusText = companyData.is_active ? 'deactivated' : 'activated';
        queryClient.invalidateQueries('companyList');

        setStatusModalOpen(false);
        toast({
          title: companyData.is_active
            ? strings.company_deactivated
            : strings.company_activated,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const onStatusPress = () => {
    setStatusModalOpen(true);
  };

  const onStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const onStatusConfirm = () => {
    changeStatusCompany.mutate();
  };

  const getAddress = (location: any) => {
    if (location.address)
      return `${location?.address} ${location?.address1}, ${location?.city}, ${location?.state}, ${location?.zip}`;
    return '-';
  };

  return (
    <Tr key={companyData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.companies.view.replace(':id', companyData?.id.toString())}>
          <HStack>
            <Avatar
              size="sm"
              rounded="full"
              src={companyData?.image}
              name={companyData?.name}
            />
            <CustomTagTitle
              type="text"
              status={companyData?.is_active}
              name={truncateString(companyData?.name) || '-'}
            />
          </HStack>
        </RouterLink>
      </Td>
      <Td>{truncateString(companyData?.company_email)}</Td>
      <Td>{truncateString(getAddress(companyData?.location))}</Td>
      <Td>{companyData?.company_phone}</Td>
      <Td>{companyData?.is_approved ? strings.approved : strings.pending}</Td>
      <Td>
        <RouterLink
          to={routes.companies.view.replace(':id', companyData.id.toString())}>
          <Tooltip hasArrow label={strings.view_company}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_company}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        {((companyData.created_by &&
          companyData.created_by === user?.user_id) ||
          user?.user_information?.role.toLowerCase() === 'superadmin') && (
          <>
            <RouterLink
              to={routes.companies.edit.replace(
                ':id',
                companyData.id.toString()
              )}>
              <Tooltip hasArrow label={strings.edit_company}>
                <IconButton
                  icon={<BiEdit />}
                  variant="link"
                  aria-label={strings.edit_company}
                  color="blue.300"
                  size="lg"
                />
              </Tooltip>
            </RouterLink>
            <AccessControl
              allowedPermissions={[
                PermissionRequest['read:company'],
                PermissionRequest['manage:company'],
              ]}
              renderNoAccess={(data: any) => data}>
              <>
                <Tooltip
                  hasArrow
                  label={
                    companyData.is_active
                      ? strings.hide_company
                      : strings.show_company
                  }>
                  <IconButton
                    icon={
                      companyData.is_active ? <BiListMinus /> : <BiListPlus />
                    }
                    variant="link"
                    aria-label={
                      companyData.is_active
                        ? strings.hide_company
                        : strings.show_company
                    }
                    color="red.300"
                    size={'lg'}
                    onClick={onStatusPress}
                  />
                </Tooltip>
                <Tooltip hasArrow label={strings.delete_company}>
                  <IconButton
                    icon={<BiTrash />}
                    variant="link"
                    aria-label={strings.delete_company}
                    color="red.300"
                    size="lg"
                    onClick={onDeletePress}
                  />
                </Tooltip>
              </>
            </AccessControl>
          </>
        )}
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_company}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteCompany.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isStatusModalOpen}
          isCentered
          onClose={onStatusModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {companyData.is_active ? 'Hide Company' : 'Show Company'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {companyData.is_active
                ? strings.confirm_hide_company_msg
                : strings.confirm_show_company_msg}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onStatusConfirm}
                  isLoading={changeStatusCompany.isLoading}>
                  {strings.submit}
                </Button>

                <Button variant="outline" onClick={onStatusModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default CompanyListItem;
