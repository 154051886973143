import Resource from 'api/resource';
import http from 'utils/http';
class PPEResource extends Resource {
  constructor() {
    super('ppe/');
  }

  changeStatus(data?: any) {
    return http({
      url: 'ppe/toggle-is-active/',
      method: 'post',
      data: data,
    });
  }
}

export { PPEResource as default };
