import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import UnitMeasureResource from 'api/unit_measure';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  fieldReportData?: any;
}

const FieldReportingForm: React.FC<Props> = (props) => {
  const { fieldReportData } = props;

  const [operatorName, setOperatorName] = useState('');
  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '', // for company List
  });

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<any>();

  const UnitMeasureAPI = new UnitMeasureResource();
  const companyAPI = new CompanyResource();

  const { data: unitMeasureQuery, isLoading: isUnitMeasureLoading } = useQuery(
    [`unit-measure-list`],
    () => UnitMeasureAPI.list().then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  console.log("unitMeasureQuery", unitMeasureQuery)

  const unitMeasureList = unitMeasureQuery?.results;
  const unitMeasureOptions = unitMeasureList?.map((unitMeasure: any) => ({
    label: unitMeasure.title,
    value: unitMeasure.id,
  }));

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    ['company-list'],
    () => companyAPI.itemList().then((res) => res?.data?.data)
  );

  const companyList = companyQuery?.results;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const handleFilterOperator = (operator: any) => {
    if (operator.value) {
      setOperatorName(operator.label);
      // setValue('operator_name', '');
      // setLocationCompany(operator.value);
    }
  };

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      search: value,
    }));
  };

  useEffect(() => {
    if (!fieldReportData) return;
    const company: any = {
      label: fieldReportData?.company?.name,
      value: fieldReportData?.company?.id,
    };
    setValue('company', company);
  }, [fieldReportData, setValue]);

  const handleSelectUnitMeasure = (selectedList: any) => {
    setValue('field', selectedList);
  };

  return (
    <form>
      <SimpleGrid columns={2} spacing={4}>
        <Box>
          <FormControl isInvalid={!!errors?.name}>
            <FormLabel>{strings.name}</FormLabel>
            <Input
              {...register('name', {
                maxLength: {
                  value: 100,
                  message: strings.too_many_characters,
                },
              })}
              type="text"
              defaultValue={fieldReportData?.name}
              placeholder={strings.name}
            />
            <FormErrorMessage>
              {errors?.name && errors?.name?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.company} isRequired>
            <FormLabel>{strings.company}</FormLabel>
            <Controller
              control={control}
              name="company"
              rules={{
                required: strings.select_company,
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="company"
                  placeholder={strings.select_company}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleFilterOperator(selectedOption);
                  }}
                  isLoading={isCompanyLoading}
                  options={companyOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  onInputChange={handleCompanyChange}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.company && errors?.company?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.field} isRequired>
            <FormLabel>{strings.field}</FormLabel>
            <Controller
              control={control}
              name="field"
              rules={{
                required: strings.select_field,
              }}
              render={({ field }) => (
                <Multiselect
                  {...field}
                  id="field"
                  isObject={true}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={handleSelectUnitMeasure}
                  options={unitMeasureOptions}
                  displayValue="label"
                  showCheckbox
                />
              )}
            />
            <FormErrorMessage>
              {errors?.field && errors?.field?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.description} isRequired>
            <FormLabel>{strings.description}</FormLabel>
            <Textarea
              {...register('description')}
              defaultValue={fieldReportData?.description}
              placeholder={strings.write_here}
            />
            <FormErrorMessage>
              {errors?.description && errors?.description?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
      </SimpleGrid>
    </form>
  );
};

export default FieldReportingForm;
