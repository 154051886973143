import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CompanyListItem from 'components/company/CompanyListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { CompanySchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  is_approved: number;
  search: string;
}

const CompanyList: React.FC = () => {
  // get logged in user
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const [isLoading, setIsLoading] = useState<string | null>();
  const companyAPI = new CompanyResource();

  const [totalCompanies, setTotalCompanies] = useState<number | undefined>(
    undefined
  );
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    is_approved: 0,
    search: '',
  });

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalCompanies,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: companyList, isLoading: isCompanyListLoading } = useQuery(
    [
      'companyList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        is_approved: filterParams.is_approved,
        search: filterParams.search,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.search,
      };
      if (filterParams.is_approved !== 0) {
        queryParams.is_approved =
          Number(filterParams.is_approved) === 1 ? false : true;
      }
      if (filterParams.search) queryParams.search = filterParams.search;
      const response = await companyAPI.list(queryParams);
      setTotalCompanies(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      is_approved: data.is_approved || 0,
      search: data.name,
    }));
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: '',
      is_approved: 0,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.company}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.companies.list}>
              {strings.all_company}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.company_list}</Heading>
          <Button
            size="sm"
            colorScheme="primary"
            type="button"
            onClick={() => history.push(routes.companies.create)}>
            {strings.add_new_company}
          </Button>
        </Flex>
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <Input
                  type="text"
                  placeholder={strings.search}
                  {...methods.register('name')}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.is_approved}</FormLabel>
                <Select
                  placeholder={strings.select}
                  id="is_approved"
                  rounded="sm"
                  {...methods.register('is_approved')}>
                  <option value="1">{strings.pending}</option>
                  <option value="2">{strings.approved}</option>
                </Select>
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.email}</Th>
                  <Th>{strings.address}</Th>
                  <Th>{strings.contact}</Th>
                  <Th>{strings.is_approved}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isCompanyListLoading &&
                  companyList?.results?.map(
                    (companyData: CompanySchema, index: number) => (
                      <CompanyListItem
                        companyData={companyData}
                        key={companyData.id}
                        index={startingSN + index}
                        user={loggedInUser}
                      />
                    )
                  )}
                {isCompanyListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isCompanyListLoading}
              totalCount={totalCompanies}
              resultCount={companyList?.results?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {companyList?.results?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isCompanyListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default CompanyList;
