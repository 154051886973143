import { Center, Icon, Image, Text, useToast, VStack } from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';

interface Props {
  title?: string;
  description?: string;
  uploadedFiles?: any;
  fileKey?: any;
  required?: boolean;
  multiple?: boolean;
  accept?: string | string[];
}
const ImageUploader: React.FC<Props> = (props) => {
  const {
    title = strings.upload_files,
    description = strings.upload_image,
    uploadedFiles,
    fileKey = 'files',
    multiple = false,
    accept = 'image/*',
  } = props;
  const toast = useToast();
  const [currentImage, setCurrentImage] = useState(uploadedFiles);
  const [myFiles, setMyFiles] = useState<any>([]);
  const [previewFile, setPreviewFile] = useState<any>();
  const [imageName, setImageName] = useState<any>();
  const { setValue, clearErrors } = useFormContext();
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (multiple) {
        setMyFiles([...myFiles, ...acceptedFiles]);
      } else {
        setMyFiles(acceptedFiles);
      }
      if (fileRejections.length > 0) {
        let title = strings.maximum_upload_attachments_size_upto_15MB;
        if (fileRejections[0]?.errors[0]?.code === 'too-many-files') {
          title = strings.only_1_file_allowed;
        }
        toast({
          title: title,
          status: 'error',
          isClosable: true,
        });
      }
    },
    [multiple, myFiles, toast]
  );

  React.useMemo(async () => {
    if (multiple) {
      setValue(fileKey, myFiles);
    } else {
      setValue(fileKey, myFiles[0]);
      setPreviewFile(URL.createObjectURL(myFiles[0]));
    }
  }, [fileKey, multiple, myFiles, setValue]);

  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 15) {
      //Limit 15 MB
      return {
        code: 'file-size-too-large',
        message: strings.maximum_upload_attachments_size_upto_15MB,
      };
    }

    return null;
  };
  const getPreview = () => {
    if (previewFile) {
      return <Image src={previewFile} alt={imageName} title={imageName} />;
    }

    if (currentImage) {
      return (
        <Image
          src={
            fileKey !== 'files'
              ? currentImage
              : ` ${process.env.REACT_APP_PUBLIC_FILE_PATH}/lbs-mms/${currentImage}`
          }
          alt={currentImage}
          title={currentImage}
        />
      );
    }
    return (
      <VStack>
        <Icon as={BiPlus} h="16" w="16" color="gray.300" />
        {isDragActive ? (
          <Text textAlign="center" color="gray.400">
            {title}
          </Text>
        ) : (
          <Text textAlign="center" color="gray.400">
            {description}
          </Text>
        )}
      </VStack>
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    onDrop,
    maxFiles: 1,
    validator: fileSizeValidator,
    multiple: multiple,
  });

  return (
    <>
      <Center
        {...getRootProps()}
        cursor="pointer"
        w="100%"
        h="160px"
        bg="gray.50"
        overflow="hidden"
        objectFit="cover"
        shadow="box"
        rounded="sm"
        border="2px"
        borderStyle="dashed"
        borderColor="gray.100">
        <input {...getInputProps()} />
        {getPreview()}
      </Center>
    </>
  );
};

export default ImageUploader;
