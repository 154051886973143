import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import WorkTypeResource from 'api/work_type';
import { CenterSpinner } from 'components/common/CenterSpinner';
import WorkTypeForm from 'components/WorkType/WorkTypeForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { WorkTypeSchema } from 'constants/schema';
import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditBulkWorkTypeStep: React.FC = () => {
  const { id, companyId }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);
  const [workSteps, setWorkSteps] = useState<any>([]);

  const methods = useForm<WorkTypeSchema>();
  const methodStep = useForm<any>();

  const workTypeAPI = new WorkTypeResource();
  const companyApi = new CompanyResource();

  const companyQuery = useQuery<any>(
    [`company${id}`, companyId],
    () => companyApi.get(companyId).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const workQuery = useQuery(
    [`work${id}-edit`, id],
    () =>
      workTypeAPI.get(id).then((res) => {
        window.console.log("Wokr Tyoe", res?.data)
        setWorkSteps(res?.data?.work_steps);
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const workTypeQuery = useQuery(
    [`work-type${id}-edit`, id], 
    () =>
    workTypeAPI
      .getWorkTypeStep({
        work_type: id,
        company: companyId,
        page: 1,
        limit: 10000,
      })
      .then((res) => {
        setWorkSteps(res?.data?.data?.results);
        return res?.data;
      }),{
        cacheTime: 0,
        refetchOnWindowFocus: false
      }
  );

  const updateWorkType = useMutation((data: any) =>
    workTypeAPI.workTypeStepStore(data)
  );

  const onSubmit = () => {
    const data = {
      work_type: id,
      company: companyId,
      work_steps: workSteps,
    };
    updateWorkType.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: strings.work_type_update_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('workTypeList');
        history.push(routes.workType.view.replace(':id', id + ''));
      },
    });
  };

  const onSubmitWorkStep = (data: any) => {
    setWorkSteps([...workSteps, data]);
    console.log("wordSteps:", workSteps, data)
    onModalClose();
  };

  const onModalClose = () => {
    setModalOpen(false);
    methodStep.setValue('operating_procedure', '');
    methodStep.setValue('hazard_identification', '');
    methodStep.setValue('hazard_relief', '');
  };
  
  useEffect(() =>{

  }, [workSteps])
  if (workTypeQuery.isLoading || companyQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (workTypeQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.work_type_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.workType.list}>
            {strings.work_type}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.create_work_type_step}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.create_work_type_step}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form>
            <Stack direction="column" spacing="4">
              {updateWorkType.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.work_type_update_failed}
                </Alert>
              )}
              <WorkTypeForm workTypeData={workQuery.data} editStep={true} />
              <Grid
                gap="4"
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                flex="1">
                {/* <GridItem>
                  <FormControl>
                    <FormLabel>{strings.company}</FormLabel>
                    <Input
                      type="text"
                      defaultValue={companyQuery?.data?.name}
                      disabled={true}
                    />
                  </FormControl>
                </GridItem> */}
              </Grid>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{strings.step}</Th>
                      <Th>{strings.operating_procedure}</Th>
                      <Th>{strings.hazard_identification}</Th>
                      <Th>{strings.hazard_relief}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {workSteps?.length > 0 &&
                      workSteps?.map((step: any, index: number) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>{step.operating_procedure}</Td>
                          <Td>{step.hazard_identification}</Td>
                          <Td>{step.hazard_relief}</Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Stack direction="row" spacing={4}>
                <Button
                  colorScheme="primary"
                  onClick={() => setModalOpen(true)}>
                  + {strings.add_step}
                </Button>
              </Stack>
              <ButtonGroup mt={10}>
                <Button
                  colorScheme="primary"
                  onClick={() => onSubmit()}
                  isLoading={updateWorkType.isLoading}>
                  {strings.add}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>

      <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.add_step}</ModalHeader>
          <ModalCloseButton />
          <FormProvider {...methodStep}>
            <form onSubmit={methodStep.handleSubmit(onSubmitWorkStep)}>
              <ModalBody>
                <Stack direction="row" align="start" spacing="4">
                  <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                    <GridItem>
                      <FormControl
                        isInvalid={
                          !!methodStep.formState.errors?.operating_procedure
                        }
                        isRequired>
                        <FormLabel>{strings.operating_procedure}</FormLabel>
                        <Input
                          {...methodStep.register('operating_procedure', {
                            required: strings.operating_procedure,
                          })}
                          type="text"
                          placeholder={strings.operating_procedure}
                        />
                        <FormErrorMessage>
                          {methodStep.formState.errors?.operating_procedure &&
                            methodStep.formState.errors?.operating_procedure
                              ?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={
                          !!methodStep.formState.errors?.hazard_identification
                        }>
                        <FormLabel>{strings.hazard_identification}</FormLabel>
                        <Input
                          {...methodStep.register('hazard_identification')}
                          type="text"
                          placeholder={strings.hazard_identification}
                        />
                        <FormErrorMessage>
                          {methodStep.formState.errors?.hazard_identification &&
                            methodStep.formState.errors?.hazard_identification
                              ?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={
                          !!methodStep.formState.errors?.hazard_relief
                        }>
                        <FormLabel>{strings.hazard_relief}</FormLabel>
                        <Input
                          {...methodStep.register('hazard_relief')}
                          type="text"
                          placeholder={strings.hazard_relief}
                        />
                        <FormErrorMessage>
                          {methodStep.formState.errors?.hazard_relief &&
                            methodStep.formState.errors?.hazard_relief?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button colorScheme="primary" type="submit">
                    {strings.add_step}
                  </Button>
                  <Button variant="outline" onClick={onModalClose}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default EditBulkWorkTypeStep;
