import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import { reactSelectStyles } from 'assets/css/commonStyles';
import MapContainer from 'components/common/MapContainer';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import { LocationSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  locationData?: any;
  position?: any;
  center?: any;
}

const LocationForm: React.FC<Props> = (props) => {
  const { locationData, position, center } = props;

  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    search: '', // for company List
  });
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [operatorName, setOperatorName] = useState('');
  const [locationCompany, setLocationCompany] = useState(0);

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useFormContext<LocationSchema>();

  const companyAPI = new CompanyResource();

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [
      `company-list`,
      { search: filterParams.search, is_active: true, is_approved: true },
    ],
    () =>
      companyAPI
        .allList({ search: filterParams.search, limit: 50 })
        .then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const handleFilterOperator = (operator: any) => {
    if (operator.value) {
      setOperatorName(operator.label);
    }
  };

  useEffect(() => {
    if (!locationData) return;
    const company: any = {
      label: locationData?.company?.name,
      value: locationData?.company?.id,
    };
    setValue('company', company);
  }, [locationData, setValue]);

  useEffect(() => {
    if (latitude) setValue('latitude', latitude);
    if (longitude) setValue('longitude', longitude);
  }, [latitude, longitude, setValue]);

  useEffect(() => {
    if (position.lat) {
      setValue('latitude', position.lat);
      setLatitude(position.lat);
    }
    if (position.lng) {
      setValue('longitude', position.lng);
      setLongitude(position.lng);
    }
  }, [position, setValue]);

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      search: value,
    }));
  };

  return (
    <form>
      <SimpleGrid columns={2} spacing={4}>
        <Box>
          <FormControl isInvalid={!!errors?.name} isRequired>
            <FormLabel>{strings.name}</FormLabel>
            <Input
              {...register('name', {
                required: strings.location_name,
              })}
              type="text"
              defaultValue={locationData?.name}
              placeholder={strings.location_name}
            />
            <FormErrorMessage>
              {errors?.name && errors?.name?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={4} marginTop={'15px'}>
        <Box>
          <FormControl isInvalid={!!errors?.latitude} isRequired>
            <FormLabel>{strings.latitude}</FormLabel>
            <Input
              {...register('latitude', {
                required: strings.latitude,
              })}
              onChange={(e: any) => setLatitude(e.target.value)}
              type="number"
              defaultValue={locationData?.latitude}
              placeholder={strings.latitude}
            />
            <FormErrorMessage>
              {errors?.latitude && errors?.latitude?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.longitude} isRequired>
            <FormLabel>{strings.longitude}</FormLabel>
            <Input
              {...register('longitude', {
                required: strings.longitude,
              })}
              type="number"
              onChange={(e: any) => setLongitude(e.target.value)}
              placeholder={strings.longitude}
            />
            <FormErrorMessage>
              {errors?.longitude && errors?.longitude?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.company} isRequired>
            <FormLabel>{strings.company}</FormLabel>
            <Controller
              control={control}
              name="company"
              rules={{
                required: strings.select_company,
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="company"
                  placeholder={'Select Company'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleFilterOperator(selectedOption);
                  }}
                  isLoading={isCompanyLoading}
                  options={companyOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  onInputChange={handleCompanyChange}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.company && errors?.company?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel>{strings.operator}</FormLabel>
            <Input defaultValue={operatorName} disabled={true} />
          </FormControl>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={4} marginTop={'15px'}>
        <Box>
          <FormControl isInvalid={!!errors?.location_contact_name}>
            <FormLabel>{strings.location_contact_name}</FormLabel>
            <Input
              {...register('location_contact_name')}
              type="text"
              defaultValue={locationData?.location_contact_name}
              placeholder={strings.location_contact_name}
            />
            <FormErrorMessage>
              {errors?.location_contact_name &&
                errors?.location_contact_name?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.location_contact}>
            <FormLabel>{strings.location_contact}</FormLabel>
            <Input
              {...register('location_contact')}
              type="text"
              defaultValue={locationData?.location_contact}
              placeholder={strings.location_contact}
            />
            <FormErrorMessage>
              {errors?.location_contact && errors?.location_contact?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.customer_contact_name}>
            <FormLabel>{strings.customer_contact_name}</FormLabel>
            <Input
              {...register('customer_contact_name')}
              type="text"
              defaultValue={locationData?.customer_contact_name}
              placeholder={strings.customer_contact_name}
            />
            <FormErrorMessage>
              {errors?.customer_contact_name &&
                errors?.customer_contact_name?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors?.customer_contact}>
            <FormLabel>{strings.customer_contact}</FormLabel>
            <Input
              {...register('customer_contact')}
              type="text"
              defaultValue={locationData?.customer_contact}
              placeholder={strings.customer_contact}
            />
            <FormErrorMessage>
              {errors?.customer_contact && errors?.customer_contact?.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
      </SimpleGrid>

      <SimpleGrid marginTop={'15px'}>
        <Box height={'60vh'}>
          <FormControl>
            <FormLabel>{strings.choose_location}</FormLabel>
            <Text>
              {strings.location}: {latitude} , {longitude}
            </Text>
            <MapContainer
              mapStyle={{
                height: '50vh',
                width: '100%',
                position: 'relative',
              }}
              mapData={{
                lat: latitude,
                lng: longitude,
              }}
              center={{
                lat: latitude,
                lng: longitude,
              }}
              draggable={true}
              setLongitude={setLongitude}
              setLatitude={setLatitude}
            />
          </FormControl>
        </Box>
      </SimpleGrid>
    </form>
  );
};

export default LocationForm;
