import Resource from 'api/resource';
import http from 'utils/http';
class MapFeatureResource extends Resource {
  constructor() {
    super('map-feature/');
  }

  pinLocation(query?: any) {
    return http({
      // url: 'jobsite/',
      url: 'map-feature-jsa/?',
      method: 'get',
      params: query,
    });
  }
}

export { MapFeatureResource as default };
