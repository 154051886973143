import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import { CompanySchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { validEmail, validPhone } from 'utils/validate';

interface Props {
  companyData?: CompanySchema;
}

const CompanyForm: React.FC<Props> = (props) => {
  const { companyData } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<CompanySchema>();

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.name}</FormLabel>
              <Input
                {...register('name', {
                  required: 'Name',
                  maxLength: {
                    value: 100,
                    message: strings.too_many_characters,
                  },
                })}
                type="text"
                defaultValue={companyData?.name}
                placeholder={strings.company_name}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.division}>
              <FormLabel>{strings.division}</FormLabel>
              <Input
                {...register('division', {
                  maxLength: {
                    value: 100,
                    message: strings.too_many_characters,
                  },
                })}
                type="text"
                defaultValue={companyData?.division}
                placeholder={strings.division}
              />
              <FormErrorMessage>
                {errors?.division && errors?.division?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.subdivision}>
              <FormLabel>{strings.sub_division}</FormLabel>
              <Input
                {...register('subdivision', {
                  maxLength: {
                    value: 100,
                    message: strings.too_many_characters,
                  },
                })}
                type="text"
                defaultValue={companyData?.subdivision}
                placeholder={strings.sub_division}
              />
              <FormErrorMessage>
                {errors?.subdivision && errors?.subdivision?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.location?.address} isRequired>
              <FormLabel>{strings.address}</FormLabel>
              <Input
                {...register('location.address', {
                  required: 'Address',
                  maxLength: {
                    value: 100,
                    message: strings.too_many_characters,
                  },
                })}
                type="text"
                defaultValue={companyData?.location?.address}
                placeholder={strings.address}
              />
              <FormErrorMessage>
                {errors?.location?.address &&
                  errors?.location?.address?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.location?.address1}>
              <FormLabel>{strings.address} 1</FormLabel>
              <Input
                {...register('location.address1', {
                  maxLength: {
                    value: 100,
                    message: strings.too_many_characters,
                  },
                })}
                type="text"
                defaultValue={companyData?.location?.address1}
                placeholder={strings.address}
              />
              <FormErrorMessage>
                {errors?.location?.address1 &&
                  errors?.location?.address1?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.location?.city} isRequired>
              <FormLabel>{strings.city}</FormLabel>
              <Input
                type="text"
                defaultValue={companyData?.location?.city}
                placeholder={strings.city}
                {...register('location.city', { required: 'City' })}
              />
              <FormErrorMessage>
                {errors?.location?.city && errors?.location?.city?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.location?.state} isRequired>
              <FormLabel>{strings.state}</FormLabel>
              <Input
                type="text"
                defaultValue={companyData?.location?.state}
                placeholder={strings.state}
                {...register('location.state', { required: 'State' })}
              />
              <FormErrorMessage>
                {errors?.location?.state && errors?.location?.state?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.location?.zip} isRequired>
              <FormLabel>{strings.zip}</FormLabel>
              <Input
                type="number"
                min="0"
                defaultValue={companyData?.location?.zip}
                placeholder={strings.zip}
                {...register('location.zip', { required: 'Zip' })}
              />
              <FormErrorMessage>
                {errors?.location?.zip && errors?.location?.zip?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.company_email} isRequired>
              <FormLabel>{strings.admin_email}</FormLabel>
              {companyData?.company_email && (
                <Input
                  type="email"
                  defaultValue={companyData?.company_email}
                  placeholder={strings.admin_email}
                  disabled
                />
              )}
              {!companyData?.company_email && (
                <Input
                  type="email"
                  defaultValue={companyData?.company_email}
                  placeholder={strings.admin_email}
                  {...register('company_email', {
                    required: 'Admin Email',
                    validate: (value) =>
                      validEmail(value) || strings.valid_email_msg,
                  })}
                />
              )}
              <FormErrorMessage>
                {errors?.company_email && errors?.company_email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.company_phone} isRequired>
              <FormLabel>{strings.admin_phone}</FormLabel>
              {companyData?.company_phone && (
                <Input
                  type="number"
                  defaultValue={companyData?.company_phone}
                  placeholder={strings.admin_phone}
                  disabled
                />
              )}
              {!companyData?.company_phone && (
                <Input
                  type="number"
                  defaultValue={companyData?.company_phone}
                  placeholder={strings.admin_phone}
                  {...register('company_phone', {
                    required: 'Admin Phone',
                    validate: (value) =>
                      validPhone(value) || strings.valid_contact_number_msg,
                  })}
                />
              )}
              <FormErrorMessage>
                {errors?.company_phone && errors?.company_phone?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.user_manager_contact_name}>
              <FormLabel>{strings.user_manager_contact_name}</FormLabel>
              <Input
                type="text"
                defaultValue={companyData?.user_manager_contact_name}
                placeholder={strings.user_manager_contact_name}
                {...register('user_manager_contact_name')}
              />
              <FormErrorMessage>
                {errors?.user_manager_contact_name &&
                  errors?.user_manager_contact_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.user_manager_contact}>
              <FormLabel>{strings.user_manager_phone_number}</FormLabel>
              <Input
                type="number"
                defaultValue={companyData?.user_manager_contact}
                placeholder={strings.user_manager_phone_number}
                {...register('user_manager_contact', {
                  validate: (value) => {
                    if (value)
                      return (
                        validPhone(value) || strings.valid_contact_number_msg
                      );
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.user_manager_contact &&
                  errors?.user_manager_contact?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.about}>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('about')}
                defaultValue={companyData?.about}
                placeholder={strings.write_here}
              />
              <FormErrorMessage>
                {errors?.about && errors?.about?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.upload_company_logo}</FormLabel>
              <ImageUploader
                description={strings.upload_company_logo}
                uploadedFiles={companyData?.image}
                accept="image/*"
                fileKey="image"
              />
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default CompanyForm;
