import {
  THEME_ANNOUNCEMENT,
  THEME_CHAT,
  THEME_COMPANY,
  THEME_FAQ,
  THEME_FIELD_REPORT,
  THEME_HAZARD,
  THEME_LOCATION,
  THEME_MAP_FEATURE,
  THEME_PPE,
  THEME_REPORTS,
  THEME_SETTING,
  THEME_TASK,
  THEME_USER,
} from 'constants/common';

export const CustomTheme = (pathName: string): string => {
  const path = pathName.split('/');

  switch (path[1]) {
    case 'users':
      return THEME_USER;
    case 'locations':
      return THEME_LOCATION;
    case 'work-types':
    case 'sops':
      return THEME_TASK;
    case 'hazards':
      return THEME_HAZARD;
    case 'announcements':
      return THEME_ANNOUNCEMENT;
    case 'ppe':
      return THEME_PPE;
    case 'companies':
      return THEME_COMPANY;
    case 'chats':
      return THEME_CHAT;
    case 'reports':
      return THEME_REPORTS;
    case 'faqs':
      return THEME_FAQ;
    case 'map-features':
      return THEME_MAP_FEATURE;
    case 'settings':
      return THEME_SETTING;
    case 'field-report':
      return THEME_FIELD_REPORT;
    default:
      return '';
  }
};
