import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import UserForm from 'components/user/UserForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { convertToFormData } from 'utils';

const EditUser: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<UserSchema>();

  const userAPI = new UserResource();
  const [errorMessage, setErrorMessage] = useState<any>();

  const userQuery = useQuery(
    [`user${id}-edit`, id],
    () => userAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateUser = useMutation((data: any) => userAPI.update(id, data));

  const onSubmit = (data: any) => {
    console.log(data)
    if (data.role.label.toLowerCase() !='superadmin'){
      console.log('jelo');
      data.company = data.company.value;
    } else {
      delete data.company}
    data.role = data.role.value;
    const formData: FormData = convertToFormData(data);
    console.log(formData)
    updateUser.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.user_update_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userList');
        history.push(routes.users.list);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrorMessage(error?.response?.data[objKey][0]);
        else setErrorMessage(strings.user_update_err_msg);
      },
    });
  };

  if (userQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.users.edit}>
            {strings.edit_user}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_user}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <UserForm userData={userQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateUser.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditUser;
