import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import UserForm from 'components/user/UserForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { convertToFormData } from 'utils';

const CreateUser: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<UserSchema>();
  const userAPI = new UserResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addUser = useMutation((data: any) => userAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.user_create_success_msg,
        description: res.data.email,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('userList');
      history.push(routes.users.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey][0]);
      else setErrorMessage(strings.user_create_err_msg);
    },
  });

  /*
   *set error message if Email is already taken.
   */
  const { setError } = methods;

  useEffect(() => {
    if (errorMessage?.email) {
      setError(
        'email',
        {
          type: 'manual',
          message: errorMessage?.email?.toString(),
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [errorMessage, setError]);

  const onSubmit = (data: any) => {
    console.log(data)
    if (data.role.label.toLowerCase() !='superadmin') data.company = data.company.value;
    data.role = data.role.value;
    const formData: FormData = convertToFormData(data);
    addUser.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.users.create}>
            {strings.add_new_user}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.add_new_user}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <UserForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addUser.isLoading}>
                  {strings.add_user}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateUser;
