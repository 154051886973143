import Resource from 'api/resource';
import http from 'utils/http';
class ApproveUserResource extends Resource {
  constructor() {
    super('approve-user/');
  }
  approve(id: string, query?: any) {
    return http({
      url: `${id}`,
      method: 'put',
      params: query,
      headers: {
        'Device-Type': 'web',
      },
    });
  }

}

export { ApproveUserResource as default };