import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import JSAResource from 'api/location';
import {
  activeStyles,
  normalStyles,
  reactSelectStyles,
  separatorStyles,
} from 'assets/css/commonStyles';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import JSAListItemReport from 'components/reports/JSAListItemReport';
import { strings } from 'config/localization';
import CreatedAtInput, {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import {
  BiCalendar,
  BiChevronLeft,
  BiChevronRight,
  BiExport,
} from 'react-icons/bi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import ReactSelect from 'react-select';
import { DateFormat, DateFormatMDY, getStartingSerialNumber } from 'utils';
import { csvExport } from 'utils/fileExport';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  createdAt: string;
  company: string;
  location: string;
  search: string;
}

const JSAListReport: React.FC = () => {
  const { company_name }: any = useParams();

  const methods = useForm<any>();
  const [isLoading, setIsLoading] = useState<string | null>();
  const jsaAPI = new JSAResource();
  const [createdAt, setCreatedAt] = useState<any>();
  const [locationCompany, setLocationCompany] = useState(0);

  const [totalJSA, setTotalJSA] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    createdAt: '',
    company: company_name || '',
    location: '',
    search: '', // for company List
  });

  const companyAPI = new CompanyResource();

  const { data: companyQuery, isLoading: isCompanyLoading } = useQuery(
    [`company-list`, { search: filterParams.search }],
    () =>
      companyAPI
        .allList({ search: filterParams.search, limit: 50 })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  const { data: locationQuery, isLoading: isLocationListLoading } = useQuery(
    [`company-location-list`, { locationCompany: locationCompany }],
    async () => {
      return await companyAPI
        .relatedLocations(locationCompany)
        .then((res) => res?.data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const locationList = locationQuery?.results;
  const locationOptions = locationList?.map((location: any) => ({
    label: location.name,
    value: location.id,
  }));

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalJSA,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: jsaList, isLoading: isJSAListLoading } = useQuery(
    [
      'jsaList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        createdAt: filterParams.createdAt,
        company: filterParams.company,
        location: filterParams.location,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.createdAt)
        queryParams.date = DateFormatMDY(filterParams.createdAt);
      if (filterParams.company) queryParams.company = filterParams.company;
      if (filterParams.location) queryParams.location = filterParams.location;
      const response = await jsaAPI.jsaCompletedListReport(queryParams);
      setTotalJSA(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data?.data?.results;
    }
  );

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      createdAt: data.createdAt,
      company: data.company?.label,
      location: data.location?.label,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setCreatedAt('');
    methods.setValue('company', '');
    methods.setValue('location', '');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      createdAt: '',
      company: '',
      location: '',
      search: '',
    }));
    setLocationCompany(0);
  };

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleDateChange = (date: Date) => {
    setCreatedAt(date);
    methods.setValue('createdAt', date);
  };

  const handleExport = async () => {
    const response = await jsaAPI.jsaCompletedListReportExport();
    let dataArray = [];
    dataArray = await response?.data?.data?.map((res: any, index: number) => {
      return {
        [strings.sn]: index + 1,
        [strings.customer]: res.customer_name || '-',
        [strings.latitude]: res?.location?.lat,
        [strings.longitude]: res?.location?.lng,
        [strings.company]: res?.company_name || '-',
        [strings.location]: res?.job_title,
        [strings.completed_by]: res.completed_by || '-',
      };
    });
    csvExport(dataArray, `JSAReport-${DateFormat(new Date())}` + ' .xlsx');
  };

  const handleCompanyChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  const handleFilterLocation = (company: any) => {
    if (company.value) {
      methods.setValue('location', '');
      setLocationCompany(company.value);
    }
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.report}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.reports.jsa.list}>
              {strings.jsa}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.jsa_report}</Heading>
          <IconButton
            size="sm"
            fontSize="md"
            onClick={handleExport}
            variant="outline"
            rounded="sm"
            colorScheme="purple"
            aria-label="Export"
            icon={<BiExport />}
          />
        </Flex>

        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.date}</FormLabel>
                <InputGroup>
                  <InputRightElement
                    children={<BiCalendar color="green.500" />}
                  />
                  <ReactDatePicker
                    dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                    customInput={<CreatedAtInput value={createdAt} />}
                    onChange={handleDateChange}
                    selected={createdAt}
                    value={DateFormatMDY(createdAt)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.company}</FormLabel>
                <Controller
                  control={methods.control}
                  name="company"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="company"
                      isLoading={isCompanyLoading}
                      placeholder={strings.select_company}
                      options={companyOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onInputChange={handleCompanyChange}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        handleFilterLocation(selectedOption);
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.location}</FormLabel>
                <Controller
                  control={methods.control}
                  name="location"
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="location"
                      isLoading={isLocationListLoading}
                      placeholder={strings.select_location}
                      options={locationOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      // onInputChange={handleLocationChange}
                    />
                  )}
                />
              </FormControl>
              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button colorScheme="primary" type="submit">
                  {strings.filter}
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.date}</Th>
                  <Th>{strings.company}</Th>
                  <Th>{strings.location}</Th>
                  <Th>{strings.completed_by}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isJSAListLoading &&
                  jsaList?.map((jsaData: any, index: number) => (
                    <JSAListItemReport
                      jsaData={jsaData}
                      key={jsaData.id}
                      index={startingSN + index}
                    />
                  ))}
                {isJSAListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isJSAListLoading}
              totalCount={totalJSA}
              resultCount={jsaList?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {jsaList?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value: any) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isJSAListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default JSAListReport;
