import { Box, List } from '@chakra-ui/react';
import {
  THEME_ANNOUNCEMENT,
  THEME_CHAT,
  THEME_COMPANY,
  THEME_FAQ,
  THEME_FIELD_REPORT,
  THEME_HAZARD,
  THEME_LOCATION,
  THEME_MAP_FEATURE,
  THEME_PPE,
  THEME_REPORTS,
  THEME_SETTING,
  THEME_TASK,
  THEME_USER,
} from 'constants/common';
import { ThemeContext } from 'context/ThemeContext';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomTheme } from 'utils/CustomTheme';
import {
  AnnouncementSubMenuList,
  ChatSubMenuList,
  CompaniesSubMenuList,
  FAQSubMenuList,
  FieldReportingSubMenuList,
  HazardSubMenuList,
  LocationSubMenuList,
  MAPFeatureSubMenuList,
  PPESubMenuList,
  ReportSubMenuList,
  SettingSubMenuList,
  TaskSubMenuList,
  UserSubMenuList,
} from './SubMenus';
const SidebarSubMenu: React.FC = (props) => {
  const { setTheme } = useContext(ThemeContext);
  const location = useLocation();
  const theme = CustomTheme(location.pathname);

  useEffect(() => {
    setTheme(theme);
  }, [setTheme, theme]);

  return (
    <Box position="relative" width="100%">
      {theme && (
        <List
          p={['3', '6']}
          pt="12"
          spacing="8"
          fontSize="xs"
          color="white"
          height="100vh">
          {theme === THEME_USER && <UserSubMenuList />}
          {theme === THEME_COMPANY && <CompaniesSubMenuList />}
          {theme === THEME_LOCATION && <LocationSubMenuList />}
          {theme === THEME_PPE && <PPESubMenuList />}
          {theme === THEME_HAZARD && <HazardSubMenuList />}
          {theme === THEME_REPORTS && <ReportSubMenuList />}
          {theme === THEME_ANNOUNCEMENT && <AnnouncementSubMenuList />}
          {theme === THEME_CHAT && <ChatSubMenuList />}
          {theme === THEME_TASK && <TaskSubMenuList />}
          {theme === THEME_FAQ && <FAQSubMenuList />}
          {theme === THEME_MAP_FEATURE && <MAPFeatureSubMenuList />}
          {theme === THEME_FIELD_REPORT && <FieldReportingSubMenuList />}
          {theme === THEME_SETTING && <SettingSubMenuList />}
        </List>
      )}
    </Box>
  );
};

export default SidebarSubMenu;
