import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { CSV_EXTENSION } from 'constants/common';
import useFileUpload from 'hooks/useFileUpload';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BiImport } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import checkHeaders from 'utils/checkHeaders';
import XLSX from 'xlsx';

interface Props {
  url: string;
  setRefetch?: Function;
  headerSchema: string[];
  label?: string;
  icon?: boolean;
}

const CsvUpload: React.FC<Props> = (props) => {
  let {
    url,
    setRefetch,
    headerSchema,
    label = strings.upload_csv,
    icon = true,
  } = props;

  const { id: userID } = useSelector((state: any) => state.data.auth.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [file, setFile] = useState<any>(null);
  const uploadRef = useRef<any>(null);
  const { loading, result, uploadPercent, error, setFormdata, setUploadFile } =
    useFileUpload(url);

  useEffect(() => {
    if (!isOpen && uploadRef) {
      if (uploadRef.current) uploadRef.current.value = null;
    }
  }, [isOpen]);

  useEffect(() => {
    if (!result) return;
    if (result.status === 200) {
      onClose();
      if (setRefetch) setRefetch((prev: number) => prev + 1);
      toast({
        title: strings.file_upload_success_msg,
        status: 'success',
        isClosable: true,
      });
    }
  }, [onClose, result, setRefetch, toast]);

  const handleChange = (e: any) => {
    let fs = e.target.files[0];
    if (!fs) return;
    const fileSize = fs.size / 1024 / 1024;
    if (fileSize > 15) {
      toast({
        title: strings.maximum_upload_attachments_size_upto_15MB,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async function () {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, {
        type: 'binary',
        codepage: Number(process.env.REACT_APP_CODEPAGE),
        cellDates: true,
      });
      const sheetNameList = workbook.SheetNames;

      // check if the uploaded filess contains required headers (columns)
      const isValid = checkHeaders(
        workbook.Sheets[sheetNameList[0]],
        headerSchema
      );
      if (!isValid) {
        toast({
          title: strings.upload_valid_data,
          status: 'error',
          isClosable: true,
        });
      } else {
        let fileExtension = fs.name.split('.')[1];
        let fileName = fs.name.split('.')[0];
        let file = new File(
          [fs],
          fileName + '_' + Date.now().toString() + '.' + fileExtension,
          {
            type: fs.type,
          }
        );
        setFile(file);
        setUploadFile(file);
        uploadRef.current.value = null;
        let formdata = new FormData();
        if (file) formdata.append('csv_file', file);
        setFormdata(formdata);
        onOpen();
      }
    };
    reader.readAsBinaryString(fs);
  };
  const openUploadDialogue = () => {
    if (uploadRef) {
      uploadRef.current.click();
    }
  };
  const handleModalClose = () => {
    onClose();
  };

  return (
    <Fragment>
      <div>
        {icon && (
          <Button
            colorScheme="primary"
            leftIcon={<Icon as={BiImport} w="6" h="6" />}
            onClick={openUploadDialogue}
            size="sm">
            {label}
          </Button>
        )}
        {!icon && (
          <Button colorScheme="primary" onClick={openUploadDialogue} size="sm">
            {label}
          </Button>
        )}
        <form>
          <input
            type="file"
            name="uploadCSV"
            hidden
            ref={uploadRef}
            accept={CSV_EXTENSION}
            onChange={handleChange}
            onClick={() => (uploadRef.current.value = null)}
          />
        </form>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.upload_files}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify="space-between">
              <Text>{file && file.name}</Text>
              {/* {loading && <a>Cancel</a>} */}
            </Flex>
            <Progress hasStripe value={uploadPercent} />
          </ModalBody>

          <ModalFooter justifyContent="center">
            {loading && uploadPercent !== 100 && (
              <Text>{strings.uploading} ...</Text>
            )}
            {loading && uploadPercent === 100 && (
              <Stack direction="row" align="center">
                <Spinner />
                <Text>{strings.processing} ...</Text>
              </Stack>
            )}
            {!loading && result?.status === 200 && <Text>Done</Text>}
            {!loading && error && (
              <Text color="red">
                !Error:
                {error.status >= 500
                  ? strings.internal_server_error
                  : error?.data?.message}
              </Text>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default CsvUpload;
