import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { changePassword, getAuthUser, updateAuthUser } from 'api/auth';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ChangePassword from 'components/profile/ChangePassword';
import ProfileForm from 'components/profile/ProfileForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as authService from 'services/auth';

const EditProfile: React.FC = () => {
  const toast = useToast();
  const history = useHistory();
  const methods = useForm<any>();
  const passwordMethods = useForm<any>();
  const [errMsg, setErrMsg] = useState<string>('');
  const [errProfileMsg, setErrProfileMsg] = useState<string>('');

  const { token } = useSelector(
    (state: any) => ({
      token: state?.data?.auth?.user?.token,
    }),
    shallowEqual
  );

  const headers = {
    Authorization: 'token ' + token,
  };

  const userQuery = useQuery<any>(
    `user-auth`,
    () => getAuthUser(headers).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateUser = useMutation(
    (data: any) => {
      return updateAuthUser(data, headers);
    },
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.profile_updated_successfully,
          description: res.data.email,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.dashboard);
      },
      onError: (error: any, status: any) => {
        setErrProfileMsg(error?.response?.data?.error)
        console.log('User Profile',error?.response?.data?.error)
        
      },
    }
  );

  const updatePassword = useMutation(
    (data: any) => {
      return changePassword(data, headers);
    },
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.login_with_new_password,
          description: res.data.email,
          status: 'success',
          isClosable: true,
        });
        authService.logout();
        history.push(routes.auth.login);
      },
      onError: (error: any, status: any) => {
        if (error?.response?.data?.errors?.new_password)
          setErrMsg(error?.response?.data?.errors?.new_password[0]);
        if (error?.response?.data?.errors?.old_password)
          setErrMsg(strings.current_password_is_incorrect);
        else setErrMsg(error?.response?.data[0]);
      },
    }
  );

  const onUpdateUser = (data: any) => {
    updateUser.mutate(data);
  };

  const onUpdatePassword = (data: any) => {
    updatePassword.mutate(data);
  };

  if (userQuery.isLoading) return <CenterSpinner />;

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.profile.myProfile}>
            {strings.my_profile}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.profile.edit}>
            {userQuery?.data?.username}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_profile}</Heading>
      </Flex>

      <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onUpdateUser)}>
            <Stack direction="column" spacing="6">
              {updateUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errProfileMsg}
                </Alert>
              )}
              {userQuery?.data && <ProfileForm userData={userQuery?.data} />}
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateUser.isLoading}>
                  {strings.save_now}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
      <Flex justify="space-between">
        <Heading size="md">{strings.change_password}</Heading>
      </Flex>
      <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...passwordMethods}>
          <form onSubmit={passwordMethods.handleSubmit(onUpdatePassword)}>
            <Stack direction="column" spacing="6">
              {updatePassword.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              {userQuery?.data && <ChangePassword />}
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updatePassword.isLoading}>
                  {strings.change_now}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditProfile;
