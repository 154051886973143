import { useToast } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { checkPermissions } from 'utils/esg';

interface Props {
  serviceName?: string;
  component: React.FC<RouteComponentProps>;
  userPermissions?: any;
  users?: any;
  allowedPermissions?: any;
  path: string | string[];
  exact?: boolean;
}

const RoleBasedRouting: React.FC<Props> = ({
  serviceName,
  userPermissions,
  users,
  allowedPermissions,
  component: Component,
  path,
  exact = false,
}) => {
  const history = useHistory();
  const toast = useToast();

  switch (serviceName) {
    case 'settings':
      if (users.user_role.toLowerCase() !== 'superadmin') {
        return <Redirect to={routes.dashboard} />;
      }
      break;
    case 'deactivate-request-service':
      if (users.user_role.toLowerCase() !== 'superadmin') {
        return <Redirect to={routes.dashboard} />;
      }
      break;
  }

  const permitted: any = allowedPermissions
    ? checkPermissions(userPermissions, allowedPermissions)
    : true;

  const handlingNoAccess = () => {
    toast({
      title:
        'You do not have permission to access it. Please contact administrator.',
      status: 'error',
      isClosable: true,
    });
    history.goBack();
    return null;
  };

  return (
    <>
      <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) =>
          permitted ? <Component {...props} /> : handlingNoAccess()
        }
      />
    </>
  );
};

export default connect((state: any) => ({
  userPermissions: state?.data?.auth?.permissions,
  users: state?.data?.auth?.user?.user_information,
}))(RoleBasedRouting);
