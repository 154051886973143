import { FormControl, FormLabel } from '@chakra-ui/form-control';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormErrorMessage,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import { EN, ESP, Logo } from 'assets/images';
import { withAuthState } from 'components/hoc/auth';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { LanguageTypesSchema } from 'constants/schema';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronDown, BiHide, BiLock, BiShow, BiUser } from 'react-icons/bi';
import { Link } from 'react-router-dom';
interface Props {
  login: (username: string, pw: string) => void;
}

interface LoginFormInputs {
  username: string;
  password: string;
}

const Login: React.FC<Props> = (props) => {
  const { login } = props;
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const currentLanguage = strings.getLanguage();
  const [currentFlag, setCurrentFlag] = useState(() =>
    currentLanguage === 'en' ? EN : ESP
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<LoginFormInputs>();
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      await login(data.username, data.password);
    } catch (e: any) {
      if (
        e?.message ===
        'You are not authorized to login. Please contact administrator'
      ) {
        setErrMsg(strings.auth_message);
      } else {
        setErrMsg(strings.invalid_login_message);
      }
      setIsError(true);
    }
  };

  const handleLanguage = (Language: LanguageTypesSchema) => {
    setCurrentFlag(Language === 'en' ? EN : ESP);

    strings.setLanguage(Language);
    localStorage.setItem('language', Language);
  };

  return (
    <Box bg="gray.50">
      <Stack pos="absolute" right="10%">
        <Menu>
          <MenuButton
            p={2}
            transition="all 0.2s"
            _hover={{ borderBottom: 'gray.300' }}
            _expanded={{ borderBottom: 'gray.400' }}>
            <Flex>
              <MenuItem icon={<Image src={currentFlag} w="6" h="6" />}>
                {currentLanguage?.toLocaleUpperCase()}
              </MenuItem>
              <Box pt="2">
                <BiChevronDown />
              </Box>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => handleLanguage('esp')}
              icon={<Image src={ESP} w="6" h="6" />}>
              ESP
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('en')}
              icon={<Image src={EN} w="6" h="6" />}>
              EN
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>

      <Center h="100vh">
        <Stack direction="column" spacing="8">
          <Stack spacing="4">
            <Center className="logoDiv" mt={'30px'} mx="auto">
              <Image w="16" src={Logo} className="logoImage" />
            </Center>
            <Stack>
              <Text
                align="center"
                fontSize="lg"
                fontWeight="medium"
                color={'green.700'}>
                ESG OnSite
              </Text>
            </Stack>
          </Stack>
          <Box bg="white" p="14" shadow="box" rounded="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="8">
                <Stack direction="column" spacing="4">
                  <Heading as="h1" size="xl">
                    {strings.log_in}
                  </Heading>
                  <Text color="gray.500">{strings.login_header}</Text>
                  {isError && (
                    <ScaleFade in={isError}>
                      <Alert status="error">
                        <AlertIcon />
                        {errMsg}
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>
                <Stack direction="column" spacing="5">
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!errors.username}>
                    <FormLabel>{strings.username}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <Icon color="gray.200" w="4" h="4" as={BiUser} />
                        }
                      />
                      <Input
                        id="username"
                        type="text"
                        placeholder={strings.username}
                        {...register('username', {
                          required: strings.username_required,
                        })}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.username && errors.username?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!errors.password}>
                    <FormLabel>{strings.password}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <Icon color="gray.200" w="4" h="4" as={BiLock} />
                        }
                      />
                      <Input
                        id="password"
                        type={show ? 'text' : 'password'}
                        placeholder={strings.password}
                        {...register('password', {
                          required: strings.password_required,
                        })}
                      />
                      <InputRightElement>
                        <IconButton
                          icon={show ? <BiHide /> : <BiShow />}
                          onClick={handleClick}
                          aria-label={show ? 'Hide' : 'Show'}
                          size="sm"
                        />
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>
                      {errors.password && errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <Flex justify="end">
                      <Button variant="link" colorScheme="primary">
                        <Link to={routes.auth.forgotPassword}>
                          {strings.forgot_password}
                        </Link>
                      </Button>
                    </Flex>
                  </FormControl>
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={formState.isSubmitting}>
                    {strings.login}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default withAuthState(Login);
