import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import UserFieldReportResource from 'api/user_field_report';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiShow } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';

interface Props {
  userFieldReportData: any;
  index: number;
  companyName?: string;
  fieldReport?: number;
  page?: string;
}
const UserFieldReportListItem: React.FC<Props> = (props) => {
  const { userFieldReportData, index, companyName, fieldReport, page } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const userFieldReportAPI = new UserFieldReportResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteUserFieldReport = useMutation(
    () => userFieldReportAPI.destroy(userFieldReportData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('userFieldReportList');

        if (res.data[0].success) {
          setDeleteModalOpen(false);
          toast({
            title: strings.user_field_report_delete_success_msg,
            status: 'success',
            isClosable: true,
          });
        }
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteUserFieldReport.mutate();
  };

  return (
    <Tr key={userFieldReportData.id}>
      <Td>{index}</Td>
      {page === 'environment-tracker' && (
        <>
          <Td>
            {userFieldReportData?.company_detail?.name || companyName || '-'}
          </Td>
          <Td>{userFieldReportData?.fieldType?.title || '-'}</Td>
          <Td>{truncateString(userFieldReportData?.description) || '-'}</Td>
        </>
      )}
      {page === 'user-field-report' && (
        <>
          <Td>{userFieldReportData?.company_detail?.name || '-'}</Td>
          <Td>{userFieldReportData?.fieldReport?.name || '-'}</Td>
          <Td>{userFieldReportData?.fieldType?.title || '-'}</Td>
          <Td>{truncateString(userFieldReportData?.description) || '-'}</Td>
        </>
      )}
      <Td>
        <RouterLink
          to={routes.fieldReporting.userFieldReport.view.replace(
            ':id',
            userFieldReportData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_user_field_report}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_user_field_report}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        {/* <RouterLink
          to={routes.fieldReporting.userFieldReport.edit.replace(
            ':id',
            userFieldReportData.id.toString()
          )}>
          <Tooltip hasArrow label="Edit User Field Report">
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label="Edit User Field Report"
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink> */}
        {/* <AccessControl
          allowedPermissions={[
            PermissionRequest['read:user-field-report'],
            PermissionRequest['manage:user-field-report'],
          ]}
          renderNoAccess={(data: any) => data}>
          <Tooltip hasArrow label="Delete User Field Report">
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label="Delete User Field Report"
              color="red.300"
              size="lg"
              onClick={onDeletePress}
            />
          </Tooltip>
        </AccessControl> */}
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_user_field_report}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteUserFieldReport.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default UserFieldReportListItem;
