import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Link
} from '@chakra-ui/react';

import LocationNoAuthResource from 'api/location_no_auth';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { truncateString } from 'utils';
import history from 'utils/history';

import { Center, Image, Text } from '@chakra-ui/react';
import { AppStore, PlayStore } from 'assets/icons';
import { MapLogo } from 'assets/images';

const LocationDetailsExternal: React.FC = () => {
   const { id }: any = useParams();
  const locationNoAuthApi = new LocationNoAuthResource();

  const locationNoAuthQuery = useQuery<any>(
    [`location-no-auth${id}`, id],
    () => locationNoAuthApi.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (locationNoAuthQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (locationNoAuthQuery.isError) {
    history.push(routes.auth.login);
  }

  const linkStyles: React.CSSProperties = {
    color: 'white',
    backgroundColor: 'black',
    height: '50px',
    width: '250px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: '10px',
    flexWrap: 'wrap',
    textAlign: 'center'
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };
  return (
    <>
    <Center>
     <div>
     <Stack width={'100vw'} direction="column" spacing="2">
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Center>
            <Image margin='10px' w="16" src={MapLogo} />
          </Center>
          <Stack>
            <Text
              align="center"
              fontSize="lg"
              fontWeight="medium"
              color={'green.600'}>
              ESG ONSITE
            </Text>
            <Text align="center" fontSize="3xl" fontWeight="bold">
              Download
            </Text>
            <Text align="center" fontSize="lg" fontWeight="bold">
              our new app
            </Text>
            <Text
              align="center"
              fontSize="lg"
              fontWeight="medium"
              padding={'10px'}>
              ESG Onsite is a system for maintaining safety at Oil & Gas job
              sites. ESG lets you quickly complete JSA forms, review on-site
              hazards, and notify the whole site of emergencies.
            </Text>
            <Stack
              direction="row"
              height="150px"
              spacing="4"
              display="flex"
              alignItems="center"
              justifyContent="space-around">
              <a
              style={linkStyles}
              href="https://play.google.com/store/apps/details?id=com.esgonsite.esgonsite&pcampaignid=web_share">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
              <path d="M1.4857 7.77344C1.0723 7.77344 0.720998 7.91764 0.432598 8.20624C0.144298 8.49474 0 8.84084 0 9.24464V15.4469C0 15.8601 0.144198 16.211 0.432598 16.4995C0.720998 16.7881 1.0721 16.9321 1.4855 16.9321C1.899 16.9321 2.2476 16.7881 2.5312 16.4995C2.815 16.211 2.9568 15.8601 2.9568 15.4469V9.24464C2.9568 8.84064 2.8125 8.49454 2.5241 8.20624C2.2357 7.91774 1.8894 7.77344 1.4857 7.77344Z" fill="white"/>
              <path d="M3.53418 17.6529C3.53418 18.0952 3.68808 18.4702 3.99568 18.7779C4.30338 19.0854 4.67828 19.2392 5.12078 19.2392H6.18808L6.20268 22.5133C6.20268 22.9268 6.34688 23.2776 6.63528 23.5662C6.92368 23.8547 7.26988 23.9988 7.67368 23.9988C8.08738 23.9988 8.43818 23.8547 8.72678 23.5662C9.01528 23.2776 9.15938 22.9268 9.15938 22.5133V19.2392H11.1497V22.5133C11.1497 22.9268 11.2939 23.2776 11.5822 23.5662C11.8709 23.8547 12.2219 23.9988 12.6354 23.9988C13.0486 23.9988 13.3996 23.8547 13.6879 23.5662C13.9763 23.2776 14.1203 22.9268 14.1203 22.5133V19.2392H15.2022C15.6347 19.2392 16.005 19.0857 16.3126 18.7779C16.6204 18.4702 16.7743 18.0952 16.7743 17.6529V8.04688H3.53418V17.6529Z" fill="white"/>
              <path d="M13.4282 2.2068L14.4519 0.317203C14.5194 0.192303 14.4951 0.0961036 14.38 0.0289036C14.2548 -0.0288964 14.1587 3.92646e-06 14.0916 0.115504L13.053 2.0193C12.1395 1.6154 11.1734 1.4135 10.154 1.4135C9.13477 1.4135 8.16838 1.6154 7.25488 2.0193L6.21637 0.115504C6.14927 3.92646e-06 6.05297 -0.0287964 5.92807 0.0289036C5.81257 0.0962036 5.78867 0.192303 5.85597 0.317303L6.88007 2.2068C5.84147 2.7356 5.01477 3.4736 4.39917 4.4207C3.78377 5.3679 3.47607 6.404 3.47607 7.5289H16.8178C16.8178 6.404 16.5101 5.3679 15.8947 4.4207C15.279 3.4736 14.4573 2.7355 13.4282 2.2068ZM7.50727 4.9543C7.39677 5.0649 7.26467 5.1202 7.11077 5.1202C6.95688 5.1202 6.82717 5.065 6.72127 4.9543C6.61567 4.8438 6.56267 4.7116 6.56267 4.5577C6.56267 4.4039 6.61567 4.2716 6.72127 4.161C6.82697 4.0503 6.95697 3.9952 7.11077 3.9952C7.26447 3.9952 7.39667 4.0503 7.50727 4.161C7.61797 4.2716 7.67317 4.4039 7.67317 4.5577C7.67317 4.7116 7.61797 4.8437 7.50727 4.9543ZM13.587 4.9543C13.481 5.0649 13.3512 5.1202 13.1973 5.1202C13.0435 5.1202 12.9116 5.065 12.8009 4.9543C12.6902 4.8438 12.6351 4.7116 12.6351 4.5577C12.6351 4.4039 12.6902 4.2716 12.8009 4.161C12.9115 4.0503 13.0435 3.9952 13.1973 3.9952C13.3512 3.9952 13.481 4.0503 13.587 4.161C13.6925 4.2716 13.7456 4.4039 13.7456 4.5577C13.7456 4.7116 13.6926 4.8437 13.587 4.9543Z" fill="white"/>
              <path d="M19.8752 8.19894C19.5869 7.91534 19.2359 7.77344 18.8224 7.77344C18.4187 7.77344 18.0725 7.91534 17.7842 8.19894C17.4957 8.48264 17.3513 8.83124 17.3513 9.24464V15.4469C17.3513 15.8601 17.4957 16.211 17.7842 16.4995C18.0725 16.7881 18.4187 16.9321 18.8224 16.9321C19.2359 16.9321 19.5869 16.7881 19.8752 16.4995C20.164 16.211 20.3081 15.8601 20.3081 15.4469V9.24464C20.3081 8.83124 20.164 8.48264 19.8752 8.19894Z" fill="white"/>
              </svg>
              Download Android App
              </a>
              <a
              style={linkStyles}
              href="https://apps.apple.com/us/app/esg-onsite/id1632366953">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
                <path d="M15.795 12.4435C15.795 9.52133 18.2459 8.10733 18.3401 8.01313C16.9262 6.03353 14.8523 5.75073 14.0982 5.65643C12.3072 5.46793 10.5161 6.69343 9.6677 6.69343C8.7251 6.69343 7.3111 5.65643 5.8028 5.65643C3.8233 5.65643 2.0322 6.78763 0.995299 8.57873C-1.0785 12.1607 0.4297 17.4396 2.4093 20.3618C3.4462 21.7758 4.5774 23.3783 6.0856 23.2841C7.5939 23.1898 8.1595 22.3414 9.9505 22.3414C11.7416 22.3414 12.2129 23.2841 13.8154 23.2841C15.4179 23.2841 16.4548 21.8701 17.3975 20.4561C18.5287 18.8536 19 17.2511 19 17.1568C18.9057 17.0625 15.795 15.9314 15.795 12.4435Z" fill="white"/>
                <path d="M12.8707 3.7706C13.6248 2.7337 14.1904 1.414 14.0961 0C12.9649 0.0943 11.4567 0.7541 10.7026 1.791C9.94851 2.6394 9.28861 4.0534 9.47711 5.3731C10.7026 5.4674 12.0223 4.7133 12.8707 3.7706Z" fill="white"/>
              </svg>
              Download Apple App  
              </a>
            </Stack>
          </Stack>
          <Center margin='20px'>
            <Heading size="lg" textTransform="capitalize">
              {strings.location_details}
            </Heading>
          </Center>
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.name}</FormLabel>
                <Box sx={infoStyles}>{locationNoAuthQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.coordinate}</FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.geo_location?.lat},{' '}
                  {locationNoAuthQuery?.data?.geo_location?.lng}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.company}</FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.company?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.operator}</FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.company?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.location_contact_name}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.location_contact_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.location_contact}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.location_contact || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.customer_contact_name}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.customer_contact_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.customer_contact}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.customer_contact || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.notes}
                </FormLabel>
                <Box sx={infoStyles}>
                  {locationNoAuthQuery?.data?.notes || '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.qr_code}
                </FormLabel>
                <Box sx={infoStyles}>
                  <img src={locationNoAuthQuery?.data?.qr_code} />
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.image}
                </FormLabel>
                <Box sx={infoStyles}>
                  <img src={locationNoAuthQuery?.data?.image} />
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
      </div>
    </Center>
    </>
  );
};

export default LocationDetailsExternal;