import { Td, Tr } from '@chakra-ui/react';
import { EmployeeSchema } from 'constants/schema';
import React from 'react';
import { DateFormatMDY } from 'utils';

interface Props {
  employeeData: EmployeeSchema;
  index: number;
}
const EmployeeListItemReport: React.FC<Props> = (props) => {
  const { employeeData, index } = props;

  return (
    <Tr key={employeeData.id}>
      <Td>{index}</Td>
      <Td>{employeeData?.last_name}</Td>
      <Td>{employeeData?.first_name}</Td>
      <Td>{DateFormatMDY(employeeData?.created_at)}</Td>
      <Td isNumeric>{employeeData?.number_of_jobs}</Td>
      <Td isNumeric>{employeeData?.total_man_hours}</Td>
    </Tr>
  );
};

export default EmployeeListItemReport;
