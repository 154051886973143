import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';
import HazardResource from 'api/hazard';
import { CenterSpinner } from 'components/common/CenterSpinner';
import MapContainer from 'components/common/MapContainer';
import HazardAssignForm from 'components/hazard/HazardAssignForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { HazardSchema } from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { DateFormatMDY } from 'utils';
import history from 'utils/history';

const ViewHazard: React.FC = () => {
  const { id }: any = useParams();
  const hazardApi = new HazardResource();

  const hazardQuery = useQuery<HazardSchema>(
    [`hazard${id}`, id],
    () => hazardApi.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (hazardQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (hazardQuery.isError) {
    history.push(routes.hazards.hazard.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.hazards.hazard.list}>
              {strings.hazard}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.hazard_details}
          </Heading>
          <Stack direction="row" spacing="6">
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() =>
                history.push(routes.hazards.hazard.edit.replace(':id', id))
              }>
              Edit Hazard
            </Button>
            <HideControl
              hideFor="hazard-manage"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              {!hazardQuery?.data?.reported_by?.reporter_name && (
                <Stack direction="row" spacing="6">
                  <HazardAssignForm hazardId={id} />
                </Stack>
              )}
            </HideControl>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.type}</FormLabel>
                <Box sx={infoStyles}>
                  {hazardQuery?.data?.hazard_type?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.reported_by}</FormLabel>
                <Box sx={infoStyles}>
                  {hazardQuery?.data?.reported_by?.reporter_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.reporter_phone}</FormLabel>
                <Box sx={infoStyles}>
                  {hazardQuery?.data?.reported_by?.reporter_phone || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.reported_date}</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(hazardQuery?.data?.reported_by?.reported_on) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.is_resolved}</FormLabel>
                <Box sx={infoStyles}>
                  {hazardQuery?.data?.is_resolved
                    ? strings.yes
                    : strings.no || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.resolved_by}</FormLabel>
                <Box sx={infoStyles}>
                  {hazardQuery?.data?.resolved_by?.first_name || ''}{' '}
                  {hazardQuery?.data?.resolved_by?.last_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
                <Box sx={infoStyles}>
                  {hazardQuery?.data?.additional_notes || '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>

          {hazardQuery?.data?.image && (
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.image}</FormLabel>
                  <Box sx={infoStyles}>
                    <Image
                      boxSize="150px"
                      src={hazardQuery?.data?.image}
                      alt={hazardQuery?.data?.hazard_type?.name}
                    />
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          )}

          {hazardQuery?.data?.location?.lat &&
            hazardQuery?.data?.location?.lng && (
              <Grid gap="2" w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>{strings.location}</FormLabel>
                    <Box sx={infoStyles} boxSize="lg">
                      <MapContainer
                        mapData={hazardQuery?.data?.location}
                        title={hazardQuery?.data?.hazard_type?.name}
                        center={{
                          lat: hazardQuery?.data?.location?.lat,
                          lng: hazardQuery?.data?.location?.lng,
                        }}
                        onMapClick={false}
                      />
                    </Box>
                  </FormControl>
                </GridItem>
              </Grid>
            )}
        </Stack>
      </Stack>
    </>
  );
};

export default ViewHazard;
