import { Box, Center, Icon, Link, List, Tooltip } from '@chakra-ui/react';
import {
  AnnouncementSubMenuList,
  ChatSubMenuList,
  CompaniesSubMenuList,
  FAQSubMenuList,
  FieldReportingSubMenuList,
  HazardSubMenuList,
  LocationSubMenuList,
  MAPFeatureSubMenuList,
  PPESubMenuList,
  ReportSubMenuList,
  SettingSubMenuList,
  TaskSubMenuList,
  UserSubMenuList,
} from 'components/sidebar/SubMenus';
import { strings } from 'config/localization';
import {
  THEME_ANNOUNCEMENT,
  THEME_CHAT,
  THEME_COMPANY,
  THEME_DASHBOARD,
  THEME_FAQ,
  THEME_FIELD_REPORT,
  THEME_HAZARD,
  THEME_LOCATION,
  THEME_MAP_FEATURE,
  THEME_PPE,
  THEME_REPORTS,
  THEME_SETTING,
  THEME_TASK,
  THEME_USER,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import {
  BiBuildings,
  BiChart,
  BiChat,
  BiFile,
  BiHelpCircle,
  BiLocationPlus,
  BiMapPin,
  BiMask,
  BiMeteor,
  BiRss,
  BiTachometer,
  BiTask,
  BiUser,
} from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import { checkPermissions } from 'utils/esg';

let interfaceLang = strings.getInterfaceLanguage();
if (interfaceLang !== 'en' && interfaceLang !== 'esp') {
  interfaceLang = 'en';
}
let storedLang = localStorage.getItem('language') ?? interfaceLang;
strings.setLanguage(storedLang);

// Menu Mapper
const menuMap = {
  user: {
    rootLink: '/users',
    theme: THEME_USER,
    themeColor: 'blue',
    route: routes.users.base,
    tooltipLabel: strings.user_management,
    icon: BiUser,
    subMenusComp: <UserSubMenuList />,
    allowedPermissions: [PermissionRequest['read:user']],
  },
  company: {
    rootLink: '/companies',
    theme: THEME_COMPANY,
    themeColor: 'brown',
    route: routes.companies.list,
    tooltipLabel: strings.company_list,
    icon: BiBuildings,
    subMenusComp: <CompaniesSubMenuList />,
    allowedPermissions: [PermissionRequest['read:company']],
  },
  location: {
    rootLink: '/locations',
    theme: THEME_LOCATION,
    themeColor: 'purple',
    route: routes.locations.list,
    tooltipLabel: strings.location_list,
    icon: BiLocationPlus,
    subMenusComp: <LocationSubMenuList />,
    allowedPermissions: [PermissionRequest['read:location']],
  },
  task: {
    rootLink: '/task',
    theme: THEME_TASK,
    themeColor: 'teal',
    route: routes.workType.list,
    tooltipLabel: strings.tasks,
    icon: BiTask,
    subMenusComp: <TaskSubMenuList />,
    allowedPermissions: [
      PermissionRequest['read:work-type'],
      PermissionRequest['read:sop'],
    ],
  },
  ppe: {
    rootLink: '/ppe',
    theme: THEME_PPE,
    themeColor: 'red',
    route: routes.ppe.list,
    tooltipLabel: strings.ppe_list,
    icon: BiMask,
    subMenusComp: <PPESubMenuList />,
    allowedPermissions: [PermissionRequest['read:ppe']],
  },
  hazard: {
    rootLink: '/hazards',
    theme: THEME_HAZARD,
    themeColor: 'cyan',
    route: routes.hazards.hazard.list,
    tooltipLabel: strings.hazard_list,
    icon: BiMeteor,
    subMenusComp: <HazardSubMenuList />,
    allowedPermissions: [PermissionRequest['read:hazard']],
  },
  announcement: {
    rootLink: '/announcements',
    theme: THEME_ANNOUNCEMENT,
    themeColor: 'burlywood',
    route: routes.announcements.list,
    tooltipLabel: strings.announcement_list,
    icon: BiRss,
    subMenusComp: <AnnouncementSubMenuList />,
    allowedPermissions: [PermissionRequest['read:announcement']],
  },
  chat: {
    rootLink: '/chats',
    theme: THEME_CHAT,
    themeColor: 'darkbrown',
    route: routes.chats.list,
    tooltipLabel: strings.chats,
    icon: BiChat,
    subMenusComp: <ChatSubMenuList />,
  },
  reports: {
    rootLink: '/reports',
    theme: THEME_REPORTS,
    themeColor: 'malibu',
    route: routes.reports.base,
    tooltipLabel: strings.reports,
    icon: BiChart,
    subMenusComp: <ReportSubMenuList />,
  },
  faqs: {
    rootLink: '/faqs',
    theme: THEME_FAQ,
    themeColor: 'yellow',
    route: routes.faqs.list,
    tooltipLabel: strings.faqs,
    icon: BiHelpCircle,
    allowedPermissions: [PermissionRequest['manage:data-report']],
    subMenusComp: <FAQSubMenuList />,
  },
  mapFeature: {
    rootLink: '/map-features',
    theme: THEME_MAP_FEATURE,
    themeColor: 'purple2',
    route: routes.mapFeatures.view,
    tooltipLabel: strings.map_features,
    icon: BiMapPin,
    allowedPermissions: [PermissionRequest['read:map-feature']],
    subMenusComp: <MAPFeatureSubMenuList />,
  },
  dashboard: {
    rootLink: '/dashboard',
    theme: THEME_DASHBOARD,
    themeColor: 'pink',
    route: '',
    tooltipLabel: strings.dashboard,
    icon: BiTachometer,
    subMenusComp: null,
  },
  settings: {
    rootLink: '/settings',
    theme: THEME_SETTING,
    themeColor: 'green2',
    route: routes.settings.rolePermissions.list,
    tooltipLabel: strings.settings,
    icon: AiOutlineSetting,
    subMenusComp: <SettingSubMenuList />,
  },
  fieldReport: {
    rootLink: '/field-report',
    theme: THEME_FIELD_REPORT,
    themeColor: 'purple3',
    route: routes.fieldReporting.environmentTracker.list,
    tooltipLabel: strings.field_report,
    icon: BiFile,
    subMenusComp: <FieldReportingSubMenuList />,
  },
};

// styles
const navBoxStyles = {
  h: '40px',
  cursor: 'pointer',
};

const navLinkActiveStyles = {
  borderLeft: '4px',
  borderColor: 'rgba(255,255,255,0.6)',
  d: 'block',
  bg: 'primary.400',
};

const hoverMenuStyles = {
  position: 'absolute',
  width: '200px',
  height: 'auto',
  top: '0',
  right: '-200px',
  borderRadius: 'sm',
  boxShadow: 'md',
};

export interface MenuLinkProps {
  menu:
    | 'user'
    | 'company'
    | 'location'
    | 'task'
    | 'ppe'
    | 'hazard'
    | 'announcement'
    | 'chat'
    | 'reports'
    | 'settings'
    | 'fieldReport'
    | 'mapFeature'
    | 'faqs'
    | 'dashboard';
  shouldDisableToolTipOnMenus: boolean;
  shouldShowHoverSubMenus: boolean;
  handleClickCallback: (route: string, theme: string) => void;
}

const MenuLink: React.FC<MenuLinkProps> = (props) => {
  const {
    menu,
    shouldDisableToolTipOnMenus,
    shouldShowHoverSubMenus,
    handleClickCallback,
  } = props;

  const theme = menuMap[menu].theme;
  const themeColor = menuMap[menu].themeColor;
  const rootLink = menuMap[menu].rootLink;
  const route = menuMap[menu].route;
  const tooltipLabel = menuMap[menu].tooltipLabel;
  const icon = menuMap[menu].icon;
  const subMenuComp = menuMap[menu].subMenusComp;

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  // custom Task routing
  const hasWorkTypeRight: boolean = checkPermissions(userPermissions, [
    PermissionRequest['read:work-type'],
  ]);
  menuMap.task.route = hasWorkTypeRight
    ? routes.workType.list
    : routes?.sops.list;

  // custom Field Report routing
  const hasEnvironmentTrackerRight: boolean = checkPermissions(
    userPermissions,
    [PermissionRequest['read:field-report']]
  );
  const hasFieldTypeRight: boolean = checkPermissions(userPermissions, [
    PermissionRequest['read:field-type'],
  ]);
  const hasUserFieldReportRight: boolean = checkPermissions(userPermissions, [
    PermissionRequest['read:user-field-report'],
  ]);
  if (hasEnvironmentTrackerRight) {
    menuMap.fieldReport.route = routes.fieldReporting.environmentTracker.list;
  } else if (hasFieldTypeRight) {
    menuMap.fieldReport.route = routes.fieldReporting.fieldType.list;
  } else if (hasUserFieldReportRight) {
    menuMap.fieldReport.route = routes.fieldReporting.userFieldReport.list;
  }

  const handleOnClick = () => {
    handleClickCallback(route, theme);
  };

  return (
    <Box position="relative" role="group">
      <Link
        sx={
          location.pathname?.includes(rootLink)
            ? navLinkActiveStyles
            : undefined
        }
        transition="all 0.35s"
        d="block"
        bg={`${themeColor}.400`}
        _hover={{ bg: `${themeColor}.500` }}
        onClick={handleOnClick}>
        <Tooltip
          label={tooltipLabel}
          placement="left-end"
          hasArrow
          isDisabled={shouldDisableToolTipOnMenus}>
          <Center sx={navBoxStyles}>
            <Icon as={icon} w="4" h="4" />
          </Center>
        </Tooltip>
      </Link>
      {subMenuComp && shouldShowHoverSubMenus && (
        <Box
          sx={hoverMenuStyles}
          display="none"
          bg={`${themeColor}.400`}
          _groupHover={{ display: 'block' }}>
          <List p="4" spacing="8" fontSize="xs" color="white">
            {subMenuComp}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default MenuLink;
