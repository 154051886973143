import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import HazardResource from 'api/hazard';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { HazardSchema } from 'constants/schema';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { DateFormatMDY, truncateString } from 'utils';

interface Props {
  hazardData: HazardSchema;
  index: number;
}
const HazardListItem: React.FC<Props> = (props) => {
  const { hazardData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const hazardAPI = new HazardResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteHazard = useMutation(() => hazardAPI.destroy(hazardData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('hazardList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.hazard_delete_success_msg,
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteHazard.mutate();
  };

  const getAddress = (location: any) => {
    if (location.address)
      return `${location.address} ${location.address1}, ${location.city}, ${location.state}, ${location.zip}`;
    return '-';
  };

  return (
    <Tr key={hazardData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.hazards.hazard.view.replace(
            ':id',
            hazardData?.id.toString()
          )}>
          {hazardData?.hazard_type?.name || '-'}
        </RouterLink>
      </Td>
      <Td>{truncateString(hazardData?.additional_notes) || '-'}</Td>
      <Td>{truncateString(getAddress(hazardData?.location)) || '-'}</Td>
      <Td>{hazardData?.reported_by?.reporter_name || '-'}</Td>
      <Td>{DateFormatMDY(hazardData?.reported_by?.reported_on) || '-'}</Td>
      <Td>{hazardData?.is_resolved ? strings.yes : strings.no}</Td>
      <Td>
        <RouterLink
          to={routes.hazards.hazard.view.replace(
            ':id',
            hazardData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_hazard}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_hazard}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.hazards.hazard.edit.replace(
            ':id',
            hazardData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit_hazard}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_hazard}
              color="blue.300"
              size={'lg'}
            />
          </Tooltip>
        </RouterLink>
        <AccessControl
          allowedPermissions={[
            PermissionRequest['read:hazard'],
            PermissionRequest['manage:hazard'],
          ]}
          renderNoAccess={(data: any) => data}>
          <Tooltip hasArrow label={strings.delete_hazard}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_hazard}
              color="red.300"
              size="lg"
              onClick={onDeletePress}
            />
          </Tooltip>
        </AccessControl>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_hazard}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteHazard.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default HazardListItem;
