import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import WorkTypeResource from 'api/work_type';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import WorkTypeStepForm from './WorkTypeStepForm';

interface Props {
  workTypeStepData: any;
  index: number;
}
const WorkTypeStepListItem: React.FC<Props> = (props) => {
  const { workTypeStepData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const workTypeAPI = new WorkTypeResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const methodStep = useForm<any>();

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteWorkType = useMutation(
    () => workTypeAPI.destroyWorkTypeStep(workTypeStepData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('workTypeStepList');

        if (res.data[0].success) {
          setDeleteModalOpen(false);
          toast({
            title: strings.work_type_delete_success_msg,
            status: 'success',
            isClosable: true,
          });
        }
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteWorkType.mutate();
  };

  const onModalClose = () => {
    setModalOpen(false);
    methodStep.setValue('operating_procedure', '');
    methodStep.setValue('hazard_identification', '');
    methodStep.setValue('hazard_relief', '');
  };

  const updateWorkStep = useMutation(
    (data: any) => workTypeAPI.updateWorkTypeStep(workTypeStepData.id, data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.work_type_step_success_msg,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('workTypeStepList');
        onModalClose();
      },
    }
  );

  const onSubmitWorkStep = (data: any) => {
    const formData = {
      work_type: workTypeStepData.work_type,
      company: workTypeStepData.company,
      operating_procedure: data.operating_procedure,
      hazard_identification: data.hazard_identification,
      hazard_relief: data.hazard_relief,
    };
    updateWorkStep.mutate(formData);
  };

  return (
    <Tr key={workTypeStepData.id}>
      <Td>{index}</Td>
      <Td>{workTypeStepData.company_name || '-'}</Td>
      <Td>{workTypeStepData.operating_procedure || '-'}</Td>
      <Td>{workTypeStepData.hazard_identification || '-'}</Td>
      <Td>{workTypeStepData.hazard_relief || '-'}</Td>
      <Td>
        <Tooltip hasArrow label={strings.edit_work_type_step}>
          <IconButton
            icon={<BiEdit />}
            variant="link"
            aria-label={strings.edit_work_type_step}
            color="blue.300"
            size={'lg'}
            onClick={() => setModalOpen(true)}
          />
        </Tooltip>
        <Tooltip hasArrow label={strings.delete_work_type_step}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_work_type_step}
            color="red.300"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_work_type_step}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteWorkType.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.edit_step}</ModalHeader>
            <ModalCloseButton />
            <FormProvider {...methodStep}>
              <form onSubmit={methodStep.handleSubmit(onSubmitWorkStep)}>
                <ModalBody>
                  <WorkTypeStepForm workTypeStepData={workTypeStepData} />
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button colorScheme="primary" type="submit">
                      {strings.edit_work_type_step}
                    </Button>
                    <Button variant="outline" onClick={onModalClose}>
                      {strings.cancel}
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </form>
            </FormProvider>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default WorkTypeStepListItem;
