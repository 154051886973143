import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CompanyForm from 'components/company/CompanyForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { CompanySchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { buildFormData } from 'utils';

const EditCompany: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<any>();
  const methods = useForm<CompanySchema>();

  // get logged in user
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const companyAPI = new CompanyResource();

  const companyQuery = useQuery(
    [`company${id}-edit`, id],
    () => companyAPI.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (
    companyQuery?.data?.created_by &&
    companyQuery?.data?.created_by !== loggedInUser?.user_id &&
    loggedInUser?.user_information?.role.toLowerCase() !== 'superadmin'
  ) {
    toast({
      title: strings.not_allow_to_edit_company,
      status: 'error',
      isClosable: true,
    });

    history.push(routes.companies.list);
  }

  const updateCompany = useMutation((data: any) =>
    companyAPI.updateCompany(id, data)
  );

  const onSubmit = (data: any) => {
    delete data.company_email;
    delete data.company_phone;
    data.location.lat = companyQuery?.data?.location.lat || 0.0;
    data.location.lng = companyQuery?.data?.location.lng || 0.0;
    data.location = JSON.stringify(data.location);
    const formData = buildFormData(data);
    updateCompany.mutate(formData, {
      onSuccess: (res: any) => {
        toast({
          title: strings.commpany_updated_successfully,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('companyList');
        history.push(routes.companies.list);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrorMessage(error?.response?.data[objKey][0]);
        else setErrorMessage(strings.company_creation_failed);
      },
    });
  };

  if (companyQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (companyQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.company_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.companies.list}>
            {strings.company_management}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.companies.edit}>
            {strings.edit_company}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_company}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateCompany.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <CompanyForm companyData={companyQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateCompany.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditCompany;
