import Resource from 'api/resource';
import http from 'utils/http';

class FieldTypeResource extends Resource {
  constructor() {
    super('field-type/');
  }

  itemList(query?: any) {
    return http({
      url: `/${this.uri}`,
      method: 'get',
      params: query,
    });
  }
}

export { FieldTypeResource as default };
